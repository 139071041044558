import React, {Component} from "react";
import {withAPIData} from "alpaca.js/dist/api/withAPIData";
import {Table, Button} from "reactstrap";
import {Form, Text} from "informed";
import {BootstrapText, BootstrapTextArea} from "alpaca.js/dist/autoform/bootstrap";
import Fa from "alpaca.js/dist/util/fa";
import {currency} from "../util";
import axios from "axios";
import {addFormErrors} from "alpaca.js/dist/autoform/util";
import ModalButton from "alpaca.js/dist/util/modalButton";
import ApiReactSelect from "alpaca.js/dist/api/apiReactSelect";
import {validateRequired, validateNumber} from "alpaca.js/dist/forms/inputValidators";
import LoadingButton from "alpaca.js/dist/util/loadingButton";


const RejectModalBody = ({toggleModal, onConfirm, loading}) => {

    return (
        <>
            <ApiReactSelect
                url={'/api/invoices-reject-reasons/'}
                field="reject_reason"
                label="Reason"
                valueField="id"
                validate={validateRequired} />

            <BootstrapTextArea
                field="crm_notes"
                label="Notes"
                validate={validateRequired} />

            <div className="text-center mt-5">
                <Button color="danger" outline onClick={toggleModal} className="mr-3" type="button">Cancel</Button>
                <LoadingButton color="primary" loading={loading} type="button" onClick={onConfirm}>Confirm Reject</LoadingButton>
            </div>
        </>
    );
};

const RejectButton = ({disabled, ...rest}) => {
    return (
        <ModalButton
            color="danger"
            type="button"
            size="lg"
            disabled={disabled}
            className="ml-2 mr-2"
            modalTitle="Reason for Rejecting Invoice"
            modalBody={({toggleModal}) => <RejectModalBody toggleModal={toggleModal} {...rest} />}>
            <Fa icon="times" /> Reject
        </ModalButton>
    )
};


const AcceptModalBody = ({toggleModal, onConfirm, loading}) => {
    return (
        <>
            <BootstrapTextArea
                field="crm_notes"
                label="Notes"
                validate={validateRequired} />

            <div className="text-center mt-5">
                <Button color="danger" outline onClick={toggleModal} className="mr-3" type="button">Cancel</Button>
                <LoadingButton color="primary" loading={loading} type="button" onClick={onConfirm}>Confirm Accept</LoadingButton>
            </div>
        </>
    );
};

const AcceptButton = ({disabled, ...rest}) => {
    return (
        <ModalButton
            color="primary"
            type="button"
            size="lg"
            disabled={disabled}
            modalTitle="Confirm Accept"
            className="ml-2 mr-2"
            modalBody={({toggleModal}) => <AcceptModalBody toggleModal={toggleModal} {...rest} />}>
            <Fa icon="check" /> Accept
        </ModalButton>
    )
};


const ResearchModalBody = ({toggleModal, onConfirm, loading}) => {
    return (
        <>
            <BootstrapTextArea
                field="notes"
                label="Notes"
                validate={validateRequired} />

            <div className="text-center mt-5">
                <Button color="danger" outline onClick={toggleModal} className="mr-3" type="button">Cancel</Button>
                <LoadingButton color="primary" loading={loading} type="button" onClick={onConfirm}>Confirm Research</LoadingButton>
            </div>
        </>
    );
};

const ResearchButton = ({disabled, ...rest}) => {
    return (
        <ModalButton
            color="primary"
            outline
            type="button"
            size="lg"
            className="ml-2 mr-2"
            disabled={disabled}
            modalTitle="Confirm Research"
            modalBody={({toggleModal}) => <ResearchModalBody toggleModal={toggleModal} {...rest} />}>
            <Fa icon="flag" /> Research
        </ModalButton>
    )
};


export default withAPIData(class InvoiceDetail extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.submit = this.submit.bind(this);
        this.setFormApi = this.setFormApi.bind(this);
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
        this.research = this.research.bind(this);
        this.reset = this.reset.bind(this);
        this.state = {
            accepting: false,
            rejecting: false,
            researching: false,
        }
    }

    getTotal(data) {
        return _.reduce(data.line_items, function(sum, n) {
            return sum + parseFloat(n.approved_amount);
        }, 0);
    }

    onSubmit(data) {
        let url;

        if(this.state.researching) {
            url = 'research';
        } else if(this.state.rejecting) {
            url = 'reject';
        } else if(this.state.accepting) {
            url = 'accept';
        } else {
            this.setState({accepting: true});
            url = 'accept';
        }

        axios.post(`/api/invoices/${this.props.id}/${url}/`, data).then((response) => {
            document.location.reload();
        }).catch((error) => {
            let errors = error.response.data;

            if(errors.non_field_errors) {
                alert(errors.non_field_errors[0]);
            } else {
                addFormErrors(errors, this.formApi)
            }

            this.setState({accepting: false, rejecting: false, researching: false});
        });
    }

    setFormApi(formApi) {
        this.formApi = formApi;
    }

    submit() {
        this.formApi.submitForm();
    }

    reject() {
        this.setState({
            accepting: false,
            rejecting: true,
            researching: false,
        }, this.submit)
    }

    accept() {
        this.setState({
            accepting: true,
            rejecting: false,
            researching: false,
        }, this.submit)
    }

    research() {
        this.setState({
            accepting: false,
            rejecting: false,
            researching: true,
        }, this.submit)
    }

    reset() {
        this.setState({
            accepting: false,
            rejecting: false,
            researching: false,
        })
    }

    canAccept() {
        let status = this.props.data.status;
        return _.includes(["submitted", "rejected", "research"], status);
    }

    canReject() {
        let status = this.props.data.status;
        return _.includes(["submitted", "research"], status);
    }

    canResearch() {
        let status = this.props.data.status;
        return _.includes(["submitted", "rejected"], status);
    }

    render() {
        if(this.props.loading) {
            return (
                <div className="text-center">
                    <Fa icon="spinner" spin/>
                </div>
            );
        }

        let data = {line_items: this.props.data.line_items};

        for(let index in data.line_items) {
            if(!data.line_items[index].approved_amount) {
                data.line_items[index].approved_amount = data.line_items[index].requested_amount;
            }
        }

        let invoice = this.props.data;

        return <>
            <div className="row mb-3">
                {invoice.portal_notes && (
                    <div className="col-6">
                        <h5>Portal Notes</h5>
                        <p>{invoice.portal_notes}</p>
                    </div>
                )}

                {invoice.crm_notes && (
                    <div className="col-6">
                        <h5>CRM Notes</h5>
                        <p>{invoice.crm_notes}</p>
                    </div>
                )}
            </div>

            <Form initialValues={data} onSubmit={this.onSubmit} getApi={this.setFormApi} onSubmitFailure={this.reset}>{({formState, formApi}) =>
                <>
                    <Table>
                        <thead>
                            <tr>
                                <th>Line Item</th>
                                <th>Authorized</th>
                                <th>Requested</th>
                                <th>Approved</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(data.line_items, (line_item, index) => (
                                <tr key={`line_item_${index}`}>
                                    <td>
                                        {line_item.description}
                                        <Text field={`line_items.${index}.id`} type="hidden" />
                                    </td>
                                    <td>{currency(line_item.authorized_amount)}</td>
                                    <td>{currency(line_item.requested_amount)}</td>
                                    <td>
                                        {this.canAccept() ?
                                            <BootstrapText
                                                onChange={() => {this.formApi.setError(`line_items[${index}].approved_amount`, undefined)}}
                                                validateOnChange
                                                field={`line_items[${index}].approved_amount`}
                                                showLabel={false}
                                                validate={validateRequired} />
                                        :
                                            <span>{currency(line_item.approved_amount)}</span>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th />
                                <th>{currency(this.props.data.authorized_amount)}</th>
                                <th>{currency(this.props.data.requested_amount)}</th>
                                <th>
                                    {this.canAccept() ? currency(this.getTotal(formState.values)) : currency(this.props.data.approved_amount)}
                                </th>
                            </tr>
                        </tfoot>
                    </Table>

                    <div className="text-center mt-3">
                        {this.canReject() && <RejectButton disabled={this.state.accepting || this.state.researching} id={this.props.id} onConfirm={this.reject} loading={this.state.rejecting} />}
                        {this.canResearch() && <ResearchButton disabled={this.state.rejecting || this.state.accepting} id={this.props.id} onConfirm={this.research} loading={this.state.researching} />}
                        {this.canAccept() && <AcceptButton disabled={this.state.rejecting || this.state.researching} id={this.props.id} onConfirm={this.accept} loading={this.state.accepting} />}
                    </div>
                </>
            }</Form>

        </>
    }
}, "/api/invoices/${id}")
