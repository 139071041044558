import React from 'react';
import PropTypes from 'prop-types';
import {  Nav, NavItem, NavLink, Row, Col, Button, FormGroup, Input, Table, InputGroup, UncontrolledTooltip } from 'reactstrap';
import axios from 'axios';
import classNames from 'classnames';
import Paginator from '../paginator';
import InteractionRow from './interaction_row';
import Select2 from '../forms/select2';
import Fa from "alpaca.js/dist/util/fa";
import {ReplyBox} from './replybox';


export class InteractionsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.changeFilter = this.changeFilter.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.loadMessages = this.loadMessages.bind(this);
        this.changePage = this.changePage.bind(this);
        this.onMarkAttachment = this.onMarkAttachment.bind(this);
        this.afterMessageSent = this.afterMessageSent.bind(this);

        this.state = {
            current_tab: 'all',
            tabs: this.getTabs(),
            current_filter: 'all',
            filters: this.getFilters(),
            data: {},
            current_page: 1,
            total_pages: 1,
            messages: [],
            loading: false,
        };
    }

    afterMessageSent(){
        this.loadMessages();
    }

    getShowTicketColumn() {
        return false;
    }

    getTabs() {
        return {
            all: {
                name: 'All',
                icon: 'icon-bars'
            },
            email: {
                name: 'Email',
                icon: 'icon-email'
            },
            phone: {
                name: 'Call',
                icon: 'icon-call'
            },
            sms: {
                name: 'SMS',
                icon: 'icon-sms'
            },
            note: {
                name: 'Internal Note',
                icon: 'icon-note'
            },
            system: {
                name: 'System Message',
                icon: 'icon-system'
            },
        };
    }

    getFilters() {
        return [
            {
                name: 'All',
                id: 'all',
            },
            {
                name: 'Contact',
                id: 'contact',
            },
            {
                name: 'Agent',
                id: 'agent',
            },
            {
                name: 'Critical',
                id: 'critical',
            }
        ]
    }

    componentDidMount() {
        this.loadMessages();
    }

    changeFilter(event) {
        this.setState({current_filter: event.target.value}, this.loadMessages);
    }

    changeTab(tab) {
        this.setState({
            message: "",
            critical: false,
            files: [],
            sending_message: false,
            current_tab: tab,
            current_page: 1,
            total_pages: 1
        }, this.loadMessages);
    }

    loadMessages() {
        if(this.source) {
            this.source.cancel();
        }

        this.setState({loading: true});
        this.source = axios.CancelToken.source();

        let params = {page: this.state.current_page, channel: this.props.channel, page_size: 25};

        if(this.state.current_tab !== 'all') {
            params.message_type =  this.state.current_tab;
        }

        if(this.state.current_filter === 'critical') {
            params.critical = true;
        } else if (this.state.current_filter === 'agent' || this.state.current_filter === 'contact' || this.state.current_filter === 'vendor' || this.state.current_filter === 'retailer') {
            params.from_type = this.state.current_filter;
        }

        axios.get('/api/messages/', {params: params, cancelToken: this.source.token}).then((response) => {
            this.setState({
                messages: response.data.results,
                current_page: response.data.current_page,
                total_pages: response.data.total_pages,
                loading: false,
            });
        });
    }

    changePage(page) {
        this.setState({current_page: page}, this.loadMessages);
    }

    onMarkAttachment(message, attachment, mark_as) {
        axios.patch(
            '/api/messages/' + message.id + '/attachments/' + attachment.id + '/',
            {attachment_type: mark_as}
        ).then((response) => {
            this.loadMessages();
        });
    }

    renderHeader() {
        return (
            <Row className="mb-3">
                <Col>
                    <h3>Interactions</h3>
                </Col>
                <Col sm="5" xs="12" md="4" lg="3">
                    <div className={"filter-row"}>
                        <InputGroup className="pull-right">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><Fa icon="filter" /></span>
                            </div>
                            <Select2 onSelect={this.changeFilter} data={this.state.filters}/>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
        )
    }

    renderReply() {
        if(this.state.current_tab === 'all') {
            return (
                <div className="reply-box text-center">

                    {this.props.can_add_email ? (
                        <Button color="primary" className="btn-wide" onClick={this.changeTab.bind(this, "email")}>
                            <i className="icon icon-email-white" style={{margin: "-3px 10px -3px 0"}} /> Send Email
                        </Button>
                    ) : (
                        <div className="d-inline-block">
                            <UncontrolledTooltip target="no_email_send_message_tooltip">
                                No email address on file
                            </UncontrolledTooltip>

                            <div className="d-inline-block" id="no_email_send_message_tooltip" aria-disabled="true" aria-hidden="true">
                                <a href="#" className="btn-wide btn btn-primary disabled" disabled>
                                    <i className="icon icon-email-white" style={{margin: "-3px 10px -3px 0"}} /> Send Email
                                </a>
                            </div>
                        </div>
                    )}

                    <span className="divider"/>

                    <Button color="primary" className="btn-wide" onClick={this.changeTab.bind(this, "note")}>
                        <i className="icon icon-note-white" style={{margin: "-3px 10px -3px 0"}} /> Add Internal Note
                    </Button>

                    {this.props.can_add_sms ? (
                        <>
                            <span className="divider"/>

                            <Button color="primary" className="btn-wide" onClick={this.changeTab.bind(this, "sms")}>
                                <i className="icon icon-sms-white" style={{margin: "-3px 10px -3px 0"}} /> Send SMS
                            </Button>
                        </>
                    ) : (
                        <>
                            <span className="divider"/>

                            <div className="d-inline-block">
                                <UncontrolledTooltip target="no_sms_send_message_tooltip">
                                    No sms enabled phone number on file
                                </UncontrolledTooltip>
                            </div>
                        </>
                    )}
                </div>
            )
        } else if (this.state.current_tab === 'email' || this.state.current_tab === 'note' || this.state.current_tab === 'sms') {
            return (
                <ReplyBox
                    current_tab={this.state.current_tab}
                    can_specify_email_addr={this.props.can_specify_email_addr}
                    channel={this.props.channel}
                    changeTab={this.changeTab}
                    reload={this.afterMessageSent}
                    last_message={(this.state.messages.length > 0) ? this.state.messages[0] : null}
                    can_add_email={this.props.can_add_email}
                />
            )
        } else {
            return null;
        }
    }

    renderTableHeader() {
        return (
            <tr>
                {this.getShowTicketColumn() &&
                    <th>Ticket #</th>
                }
                <th>Type</th>
                <th>Sender</th>
                <th>Interaction #</th>
                <th>Details</th>
                <th>Date/Time</th>
                <th/>
            </tr>
        )
    }

    renderTable() {
        if(this.state.loading) {
            return (
                <div className="text-center mt-4">
                    <span className="text-muted"><Fa icon="spinner" spin /> loading...</span>
                </div>
            );
        }

        if(this.state.messages.length === 0) {
            return (
                <div className="text-center mt-4">
                    <span className="text-muted">No interactions found</span>
                </div>
            );
        }

        return (
            <Table className="interactions-table table-responsive-md">
                <thead>
                {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.state.messages.map((message) =>
                        <InteractionRow
                            key={message.id}
                            message={message}
                            onMarkAttachment={this.onMarkAttachment}
                            showTicketColumn={this.getShowTicketColumn()}
                            reload={this.loadMessages}
                            can_email_reply={this.state.can_email_reply}
                        />
                    )}
                </tbody>
            </Table>
        )
    }

    renderFooter() {
        return (
            <div className="table-list-footer">
                <Paginator activePage={this.state.current_page} numPages={this.state.total_pages} onSelect={this.changePage} />
            </div>
        );
    }

    render() {
        return (
            <div className={"interactions-list table-list"}>
                <div className="table-list-header">
                    {this.renderHeader()}

                    <Nav tabs className={"justify-content-center mb-2"}>

                        {_.entries(this.state.tabs).map(([key, value]) =>
                            <NavItem key={key}>
                                <NavLink className={classNames({ active: this.state.current_tab === key })} onClick={() => { this.changeTab(key); }} style={{cursor: "pointer"}}>
                                    <i className={classNames("icon", value.icon)}/>
                                    {value.name}
                                </NavLink>
                            </NavItem>
                        )}

                    </Nav>

                    {this.renderReply()}
                </div>

                {this.renderTable()}

                {this.renderFooter()}

            </div>
        );
    }
}

InteractionsPanel.propTypes = {
    channel: PropTypes.number,
    can_add_email: PropTypes.bool,
    can_add_sms: PropTypes.bool,
};

InteractionsPanel.defaultProps = {
    can_add_email: true,
    can_add_sms: true,
};
