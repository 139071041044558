import React from "react";

import { InteractionsPanel } from './interactions_panel';

export class VendorInteractionsPanel extends InteractionsPanel {
    getTabs() {
        return {
            all: {
                name: 'All',
                icon: 'icon-bars'
            },
            email: {
                name: 'Email',
                icon: 'icon-email'
            },
            note: {
                name: 'Internal Note',
                icon: 'icon-note'
            },
            system: {
                name: 'System Message',
                icon: 'icon-system'
            },
        }
    }

    getFilters() {
        return [
            {
                name: 'All',
                id: 'all',
            },
            {
                name: 'Contact',
                id: 'contact',
            },
            {
                name: 'Agent',
                id: 'agent',
            },
            {
                name: 'Vendor',
                id: 'vendor',
            },
            {
                name: 'Critical',
                id: 'critical',
            }
        ]
    }
}
