import React from 'react';
import PropTypes from 'prop-types';
import Fa from "alpaca.js/dist/util/fa";
import {deleteIn} from 'immutable-setter';


function humanFileSize(bytes) {
    // copied and modified from: https://stackoverflow.com/a/14919494/2761986

    let thresh = 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    let units = ['kB','MB','GB','TB','PB','EB','ZB','YB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
}

export default class FileInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelectFile = this.handleSelectFile.bind(this);
        this.triggerOnChange = this.triggerOnChange.bind(this);
        this.state = {
            files: this.props.files,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.files.length !== this.state.files.length) {
            this.setState({files: nextProps.files});
        }
    }

    triggerOnChange() {
        const { onChange } = this.props;
        if (onChange) {
            onChange(this.state.files);
        }
    }

    handleSelectFile(event) {
        let component = this;
        this.setState(
            {files: this.state.files.concat(event.target.files[0])},
            function() {
                component.triggerOnChange();

                try{
                    component.refs.file_input.value = '';
                    if(component.refs.file_input.value){
                        component.refs.file_input.type = "text";
                        component.refs.file_input.type = "file";
                    }
                }catch(e){}
            }
        );
    }

    removeFile(index, e) {
        e.preventDefault();
        this.setState({files: deleteIn(this.state.files, [parseInt(index)])}, this.triggerOnChange);
    }

    render() {
        const files = _.filter(this.props.files, (f) => f !== undefined && f !== null);
        let filenames = [];
        let total_file_size = 0;

        for(let index in files) {
            let file = files[index];
            filenames.push(
                <div key={index}>
                    {file.name} ({humanFileSize(file.size)})&#160;
                    <a href="#" className="text-danger" onClick={this.removeFile.bind(this, index)} style={{position:"absolute"}}><Fa icon="times"/></a>
                </div>
            );
            total_file_size += file.size;
        }

        return (
              <div className="file-upload-input">
                  <div className="btn btn-outline-primary mb-1">
                      <span>{this.props.children}</span>
                      <input type="file" onChange={this.handleSelectFile} multiple={this.props.multiple} ref="file_input" />
                  </div>
                  <label>
                      {filenames}
                      {(this.props.fileSizeTotalLimit !== null && total_file_size > this.props.fileSizeTotalLimit) &&
                          <span className="text-danger">
                              <Fa icon="exclamation-triangle" /> Total file size cannot exceed {humanFileSize(this.props.fileSizeTotalLimit)}
                          </span>
                      }
                  </label>
              </div>
        );
    }
}

FileInput.propTypes = {
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    files: PropTypes.array,
    fileSizeTotalLimit: PropTypes.number,
};

FileInput.defaultProps = {
    multiple: false,
    files: [],
    fileSizeTotalLimit: null,
};
