import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Button, Modal, ModalBody, ModalFooter, Alert, Row, Col, UncontrolledTooltip} from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import CheckBoxInput from "../forms/checkbox_input";
import FieldInput from "../forms/field_input";
import linkState from '../link_state';
import {textOrDash} from '../util';
import { setIn } from 'immutable-setter';


export class RequestQuoteButton extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.sendQuoteRequest = this.sendQuoteRequest.bind(this);
        this.addPart = this.addPart.bind(this);
        this.removePart = this.removePart.bind(this);
        this.getRetailer = this.getRetailer.bind(this);
        this.canRequestQuote = this.canRequestQuote.bind(this);

        this.state = {
            modal_opened: false,
            requesting: false,
            data: {notes: "", examination_claims: [], parts: {}},
        };
    }

    toggleModal() {
        if(this.state.requesting) {
            return;
        }

        this.setState({ modal_opened: !this.state.modal_opened });
    }

    getRetailer() {
        let examination_claims = this.props.examination.examination_claims;

        for(let index in examination_claims) {
            if (examination_claims.hasOwnProperty(index)) {
                let examination_claim = examination_claims[index];

                if (examination_claim.claim.plan.retailer !== null) {
                    return examination_claim.claim.plan.retailer;
                }
            }
        }

        return null;
    }

    canRequestQuote(){
        let retailer = this.getRetailer();

        return retailer !== null && retailer.enable_retailer_portal;
    }

    canSubmit() {
        // must select at least one claim
        if (this.state.data.examination_claims.length === 0) {
            return false;
        }
        let examination_claims = this.props.examination.examination_claims;
        let parts = this.state.data.parts;
        let all_claims_have_parts = true;

        for(let index in examination_claims) {
            if(examination_claims.hasOwnProperty(index)) {
                let examination_claim = examination_claims[index];
                // only validate selected claims
                if (!_.includes(this.state.data.examination_claims, examination_claim.id)) {
                    continue;
                }

                if(examination_claim.claim.plan.retailer === null) {
                    return false;
                }

                // all added part fields must have values
                let has_parts = false;
                for(let pi in parts[examination_claim.id]) {
                    if(parts[examination_claim.id].hasOwnProperty(pi)) {
                        has_parts = true;
                        if (_.trim(parts[examination_claim.id][pi]).length === 0) {
                            return false;
                        }
                    }
                }
                all_claims_have_parts = all_claims_have_parts && has_parts;
            }
        }

        // note is only required there is a claim selected, but no parts added for that claim
        let has_note = _.trim(this.state.data.notes).length > 0;
        return all_claims_have_parts || has_note;
    }

    sendQuoteRequest() {
        this.setState({requesting: true});

        let data = {
            notes: _.trim(this.state.data.notes),
            examination_claims: this.state.data.examination_claims,
            parts: _.pick(this.state.data.parts, this.state.data.examination_claims)
        };

        axios.post(`/api/examination/${this.props.examination.id}/submit_quote_request/`, data).then((response) => {
            this.setState({ modal_opened: false, requesting: false });
            this.props.loadExamination();
        });
    }

    onCheckedClaim(examination_claim_id, e) {
        if(e.target.checked) {
            this.setState({
                data: setIn(this.state.data, ["examination_claims"], _.uniq(_.concat(this.state.data.examination_claims, examination_claim_id)))
            });
        } else {
            this.setState({
                data: setIn(this.state.data, ["examination_claims"], _.uniq(_.without(this.state.data.examination_claims, examination_claim_id)))
            });
        }
    }

    renderModalClaimItem(examination_claim) {
        let item = examination_claim.claim.item;
        let plan = examination_claim.claim.plan;
        let quote_request = this.props.examination.quote_request;
        let pending_quote_request = false;

        if(quote_request && quote_request.status === 'pending') {
            pending_quote_request = _.filter(quote_request.quote_request_claims, ((x) => x.examination_claim_id === examination_claim.id)).length > 0;
        }

        return (
            <div key={examination_claim.id}>
                <CheckBoxInput onChange={this.onCheckedClaim.bind(this, examination_claim.id)} checked={_.includes(this.state.data.examination_claims, examination_claim.id)}>
                    <strong>Claim {examination_claim.claim.name}</strong>
                    <span className="claim-item ml-3 d-inline-flex mt-0">{item.description}</span>
                </CheckBoxInput>

                {pending_quote_request &&
                    <Alert color="warning"><Fa icon="exclamation-triangle"/> This claim has a pending quote request.</Alert>
                }

                {plan.retailer === null ? (
                    <Alert color="danger"><Fa icon="exclamation-triangle"/> No retailer found</Alert>
                ) : (
                    <div>
                        <Row>
                            <Col xs={6}>
                                <dt>Retailer</dt>
                                <dd>
                                   <a href={`/tickets/${this.props.examination.ticket}/retailers/${plan.retailer.id}/`} target="_blank">
                                       {plan.retailer.name}
                                   </a>
                                </dd>
                            </Col>
                            <Col xs={6}>
                                <dt>Manufacturer</dt>
                                <dd>{textOrDash(item.manufacturer_name)}</dd>
                            </Col>
                            <Col xs={4}>
                                <dt>SKU</dt>
                                <dd>{textOrDash(item.retail_sku)}</dd>
                            </Col>
                            <Col xs={4}>
                                <dt>Invoice #</dt>
                                <dd>{textOrDash(plan.invoice_number)}</dd>
                            </Col>
                            <Col xs={4}>
                                <a href={`/plans/${plan.id}/items/${item.id}/update/`} target="_blank" className="btn btn-xs btn-outline-primary">Edit Item</a>
                            </Col>
                        </Row>
                    </div>
                )}

                {this.renderPartsForClaim(examination_claim)}
            </div>
        )
    }

    addPart(examination_claim_id, e) {
        let parts = this.state.data.parts;
        let parts_for_claim = parts[examination_claim_id] || [];
        parts_for_claim.push('');

        this.setState({
            data: setIn(this.state.data, ["parts", examination_claim_id], parts_for_claim)
        });
    }

    removePart(examination_claim_id, index, e) {
        let parts = _.clone(this.state.data.parts[examination_claim_id]);
        parts.splice(index, 1);

        this.setState({
            data: setIn(this.state.data, ["parts", examination_claim_id], parts)
        });
    }

    renderPartsForClaim(examination_claim) {
        let parts = this.state.data.parts[examination_claim.id] || [];
        if (this.state.data.examination_claims.indexOf(examination_claim.id) === -1) {
            return;
        }
        return (
            <Fragment>
                <dt>
                    Parts
                    <Button className="btn-xs btn-outline-primary ml-4" onClick={this.addPart.bind(null, examination_claim.id)}>Add Part</Button>
                </dt>
                {parts.length > 0 &&
                    parts.map((part, index) =>
                        <Fragment key={`ec_id_${examination_claim.id}_part_${index}`}>
                            <Row>
                                <Col xs={10}>
                                    <FieldInput className="flex-grow-1"  value={part} onChange={linkState(this, "data", ["parts", examination_claim.id, index])}/>
                                </Col>
                                <Col xs={2}>
                                    <Button className="btn btn-danger btn-sm" onClick={this.removePart.bind(null, examination_claim.id, index)}>
                                        <Fa icon="trash-alt" className="mr-0 ml-0" />
                                    </Button>
                                </Col>
                            </Row>
                        </Fragment>
                    )
                }

            </Fragment>
        );
    }

    renderModal() {
        return (
            <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal} backdrop={this.state.requesting ? "static" : true}>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">Request Quote</h3>
                        <p>Select the items for the quote</p>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.toggleModal} disabled={this.state.requesting}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <hr className="mt-0"/>

                    {this.props.examination.examination_claims.map((examination_claim) =>
                        this.renderModalClaimItem(examination_claim)
                    )}

                    <textarea
                        placeholder="Notes"
                        className="form-control mb-4 mt-2"
                        value={this.state.data.notes}
                        onChange={linkState(this, "data", ["notes"])} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.requesting} className="btn-wide">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.sendQuoteRequest} disabled={this.state.requesting || !this.canSubmit()} className="btn-wide">
                        {this.state.requesting ? (
                            <span><Fa icon="spinner" spin/> Sending</span>
                        ) : (
                            "Send"
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        let retailer = this.getRetailer();
        let disabled = !this.canRequestQuote();

        return (
            <>
                <span id="request_quote_tooltip" className="d-inline-block" tabIndex="0">
                    <Button
                        disabled={disabled}
                        style={disabled ? {pointerEvents: "none"} : {}}
                        onClick={this.toggleModal}
                        className="btn btn-xs btn-outline-primary">
                        Request Quote
                    </Button>
                </span>

                {(retailer !== null && !retailer.enable_retailer_portal) &&
                    <UncontrolledTooltip target="request_quote_tooltip">
                        Retailer is not enabled for the retailer portal.
                    </UncontrolledTooltip>
                }

                {this.renderModal()}
            </>
        )
    }
}

RequestQuoteButton.propTypes = {
    examination: PropTypes.object.isRequired,
};
