import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Card, CardBody, Table, Button, UncontrolledTooltip, Row, Col, ModalFooter, ModalBody, Modal } from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import FieldInput from "../forms/field_input";
import linkState from "../link_state";
import {currency, isNone} from "../util";
import {SelectVendorPanel} from "./select_vendor_panel";
import CheckBoxInput from "../forms/checkbox_input";
import {RepairClaimList} from "./examination_resolution/repair";
import DamageCodeList from "../claims/damageCodeList";


export class SelectVendorInstallInspectPanel extends SelectVendorPanel {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.changeOverride = this.changeOverride.bind(this);

        this.state.modal_opened = false;
    }

    getDefaultService(eclaim, damage) {
        // install and inspection is always the one service
        return this.props.service.id;
    }

    renderDamage(damage, examination_claim) {
        return (
            <CheckBoxInput
                label={null}
                onChange={this.changeService.bind(this, examination_claim.id, damage.id)}
                checked={this.state.selected_services[examination_claim.id][damage.id] !== null}
            >
                <DamageCodeList damage_names={damage.code_actual_names} />
            </CheckBoxInput>
        );
    }

    renderPriceHeaders() {
        return <>
            {this.hasSelections() &&
                <th>{this.props.service.name}</th>
            }
        </>;
    }

    canSubmitDecision() {
        if(this.state.override) {
            if(this.getAuthorizedTotal() <= 0 || _.trim(this.state.override_reason).length === 0) {
                return false;
            }
        }

        return true;
    }

    toggleModal() {
        if(this.state.setting_vendor) {
            return;
        }

        this.setState({ modal_opened: !this.state.modal_opened });
    }

    getServicePrices(vendor) {
        return _.filter(vendor.service_prices, (price) => this.props.service.id === price.service.id);
    }

    selectVendor(vendor_location) {
        if(!this.state.modal_opened) {
            this.setState({
                selected_vendor_location: vendor_location,
                selected_vendor: vendor_location.vendor,
                selected_service: this.props.service.id,
                resolution_claims: this.getResolutionClaims(vendor_location),
                modal_opened: true,
                override: false,
                override_reason: "",
                notes: "",
            });

            return;
        }

        this.setState({setting_vendor: this.state.selected_vendor_location.id});

        let data = {
            vendor_location: this.state.selected_vendor_location.id,
            vendor: this.state.selected_vendor.id,
            service: this.state.selected_service.id,
            resolution_claims: this.state.resolution_claims,
            authorized_amount: this.getAuthorizedTotal(),
            override: this.state.override,
            override_reason: this.state.override_reason,
            notes: this.state.notes,
        };

        axios.post(this.props.api_url, data).then((response) => {
            this.setState({setting_vendor: null});
            document.location = this.getSuccessURL();
        });
    }

    getResolutionClaims(vendor_location) {
        if(vendor_location === undefined) {
            vendor_location = this.state.selected_vendor_location;
        }

        let service = _.filter(vendor_location.service_prices, (price) => this.props.service.id === price.service.id)[0];

        let resolution_claims = [];

        let first_service = true;

        for(let eclaim_id in this.state.selected_services) {
            let damages = this.state.selected_services[eclaim_id];
            let services = [];

            for(let damage_id in damages) {
                let service_id = damages[damage_id];

                if(service_id == null) {
                    continue;
                }

                services.push({
                    "price": first_service ? (parseFloat(service.price) || parseFloat(service.service.price)) : 0,
                    "damage": parseInt(damage_id),
                    "service": this.props.service.uuid,
                    "trip_charge": first_service ? (vendor_location.trip_charge ? vendor_location.trip_charge : 0) : 0
                });

                first_service = false;
            }

            if(services.length > 0) {
                resolution_claims.push({
                    "examination_claim": parseInt(eclaim_id),
                    "extra_data": {"services": services}
                })
            }
        }

        return resolution_claims;
    }

    getAuthorizedTotal() {
        return _.reduce(
            this.state.resolution_claims,
            (i, rc) => _.reduce(
                rc.extra_data.services,
                (x, service) => parseFloat(service.price || 0) + parseFloat(service.trip_charge || 0),
                (value, total) => value + total
            ),
            (value, total) => value + total
        );
    }

    renderModal() {
        return (
            <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal} backdrop={this.state.setting_vendor ? "static" : true}>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">Confirm Service Decision</h3>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.toggleModal} disabled={this.state.submitting}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <hr/>
                    {this.renderInnerModal()}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.setting_vendor !== null} className="btn-wide">Cancel</Button>
                    <Button color="primary" onClick={this.selectVendor.bind(this, this.state.selected_vendor_location)} disabled={this.state.setting_vendor !== null || !this.canSubmitDecision()}
                            className="btn-wide">
                        {this.state.setting_vendor !== null ? (
                            <span><Fa icon="spinner" spin/> Confirming</span>
                        ) : (
                            "Confirm"
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    changeOverride(e) {
        if(e.target.checked) {
            this.setState({override: e.target.checked});
        } else {
            // If we uncheck override, we need to reset the claims
            this.setState({override: e.target.checked, resolution_claims: this.getResolutionClaims()});
        }
    }

    renderInnerModal() {
        let service = this.getServicePrices(this.state.selected_vendor_location)[0];

        return (
            <form>
                <div>
                    <div>
                        <h5 className="d-inline-block mr-4">{service.service.name}</h5>
                        {currency(this.getAuthorizedTotal())}
                    </div>

                    <div className="mb-2">
                        <small className="text-muted">
                            Service Tech:&#160;
                            <a href={`/tickets/${this.props.ticket}/vendors/${this.state.selected_vendor.id}/`}>
                                {this.state.selected_vendor.name}
                            </a>
                            <br/>
                        </small>
                    </div>

                    <CheckBoxInput
                        label="Override Authorized Amount"
                        onChange={this.changeOverride}
                        checked={this.state.override} />

                    {this.state.override &&
                        <FieldInput
                            label="Override Reason"
                            onChange={linkState(this, "override_reason")}
                            value={this.state.override_reason} />
                    }

                    <RepairClaimList
                        examination={this.state.examination}
                        resolution_claims={this.state.resolution_claims}
                        override={this.state.override}
                        onChange={linkState(this, ["resolution_claims"])} />

                    <FieldInput
                        type="textarea"
                        label="Additional Notes to Service Tech"
                        value={this.state.notes}
                        onChange={linkState(this, "notes")} />

                </div>
            </form>
        )
    }

    renderExtra() {
        if(this.state.selected_vendor_location !== undefined) {
            return this.renderModal();
        }

        return null;
    }

}

SelectVendorInstallInspectPanel.propTypes = {
    examination_id: PropTypes.number.isRequired
};

SelectVendorInstallInspectPanel.defaultProps = {
};
