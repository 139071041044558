import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Card, CardBody, Col, Row, Table, UncontrolledTooltip } from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import FieldInput from "../forms/field_input";
import linkState from "../link_state";
import Select2 from "../forms/select2";
import { currency, isNone } from "../util";
import DamageCodeList from "../claims/damageCodeList";

export class SelectVendorPanel extends React.Component {
    constructor(props) {
        super(props);
        this.loadVendors = this.loadVendors.bind(this);

        this.state = {
            examination_id: this.props.examination_id,
            examination: null,
            vendors: [],
            postal_code: this.props.postal_code,
            loading_vendors: false,
            loading_examination: true,
            selected_services: {},  // {<examination_claim_id>: {<damage_id>: <service_id>}}
            selected_service_array: [],
            available_services: {},
            setting_vendor: null
        };
    }

    componentDidMount() {
        this.loadExamination();
    }

    getSuccessURL() {
        return `/examination/${this.state.examination_id}/`;
    }

    getSelectedServiceArray(selected_services) {
        // pull out the leaf (service_id) values from the nested selected_services object
        return _.transform(selected_services,
            function (result, value) {
                _.values(value).map((v) => result.push(parseInt(v)))
            },
            []
        );
    }

    hasSelections() {
        return _.compact(this.state.selected_service_array).length > 0;
    }

    loadVendors(e) {
        if (e) {
            e.preventDefault();
        }

        if (!this.state.postal_code) {
            return;
        }

        this.setState({loading_vendors: true});

        let params = {'postal_code': this.state.postal_code};

        if (!isNone(this.props.service)) {
            params.service = this.props.service.id;
        } else {
            params.service = this.state.selected_service_array;
        }
        if (this.props.alternate_flow) {
            params.alternate_flow = this.props.alternate_flow;
        }

        // console.log('props service', this.props.service);
        // console.log('service array', this.state.selected_service_array);
        // console.log('params for vendor query', params);

        axios.get('/api/vendors-locations/', {params: params}).then((response) => {
            this.setState({
                vendors: response.data.results,
                loading_vendors: false
            });
        });
    }

    getDefaultService(eclaim, damage) {
        // default to first available
        let service = eclaim.available_services[0].id;

        // check for previously existing selected
        for (const selected of eclaim.selected_services || []) {
            if (selected.damage_id === damage.id) {
                service = selected.selected_service.id;
            }
        }

        return service;
    }

    loadExamination() {
        axios.get(`/api/examination/${this.state.examination_id}/`).then((response) => {
            let selected_services = {}, available_services = {};
            for (const eclaim of response.data.examination_claims) {
                if (this.props.examination_claims !== undefined && !_.includes(this.props.examination_claims, eclaim.id)) {
                    continue;
                }

                if (eclaim.available_services.length > 0) {
                    for (const damage of eclaim.claim.damages) {
                        if (selected_services[eclaim.id] === undefined) {
                            selected_services[eclaim.id] = {};
                        }

                        selected_services[eclaim.id][damage.id] = this.getDefaultService(eclaim, damage);
                    }

                    // keep track of all available services across all examination claims
                    for (const service of eclaim.available_services) {
                        available_services[service.id] = service.name;
                    }
                }
            }

            this.setState({
                examination: response.data,
                loading_examination: false,
                selected_services: selected_services,
                available_services: available_services,
                selected_service_array: this.getSelectedServiceArray(selected_services)
            }, () => {
                if (this.state.postal_code) {
                    this.loadVendors();
                }
            });
        });
    }

    changeService(claim_id, damage_id, e) {
        let selected_services = _.clone(this.state.selected_services);

        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                selected_services[claim_id][damage_id] = this.props.service.id;
            } else {
                selected_services[claim_id][damage_id] = null;
            }
        } else {
            selected_services[claim_id][damage_id] = e.target.value;
        }

        this.setState(
            {
                selected_services: selected_services,
                selected_service_array: this.getSelectedServiceArray(selected_services)
            },
            e.target.type !== 'checkbox' ? this.loadVendors : undefined
        );

    }

    getServicePrices(vendor) {
        let service_prices = [];

        // This is done this way on purpose because we could have duplicates in selected_service_array, and we want to preserve those
        for (let index in this.state.selected_service_array) {
            let selected_service = this.state.selected_service_array[index];
            let service_price = _.find(vendor.service_prices, (price) => selected_service === price.service.id);

            if (service_price) {
                service_prices.push(service_price);
            }
        }

        return service_prices;
    }

    getTotalPrice(vendor) {
        let total = _.reduce(this.getServicePrices(vendor), (result, value) => {
                if (!_.includes(this.state.selected_service_array, value.service.id)) {
                    result += 0;
                } else {
                    result += parseFloat(value.price) || parseFloat(value.service.price);
                }
                return result;
            }, 0
        );

        if (vendor.trip_charge !== null) {
            total += _.round(vendor.trip_charge);
        }
        return total;
    }

    selectVendor(vendor_location) {
        if (!this.canSelectVendorLocation(vendor_location)) {
            return;
        }

        this.setState({setting_vendor: vendor_location.id});

        let data = {
            selected_vendor: vendor_location.vendor.id,
            selected_vendor_location: vendor_location.id,
            selected_services: this.state.selected_services,
            service_postal_code: this.state.postal_code,
        };

        axios.post(this.props.api_url, data).then((response) => {
            this.setState({setting_vendor: null});
            document.location = this.getSuccessURL();
        });
    }

    renderPriceColumns(vendor) {

        return (
            <Fragment>
                <td>
                    <strong style={{fontSize: 16}}>
                        {currency((vendor.trip_charge) ? vendor.trip_charge : 0)}
                    </strong>
                </td>

                {_.uniq(this.state.selected_service_array).map((service_id, index) => {
                    let service = vendor.service_prices.filter((srv) => srv.service.id === service_id)[0];
                    if (!service) return null;
                    return <td key={`price_${index}`}>
                        <strong style={{fontSize: 16}}>
                            {currency(parseFloat(service.price) || parseFloat(service.service.price))}
                        </strong>
                    </td>;
                })}

                <td>
                    <strong style={{fontSize: 16}}>${this.getTotalPrice(vendor)}</strong>
                </td>

            </Fragment>
        )
    }

    renderPriceHeaders() {
        return <>
            {_.uniq(this.state.selected_service_array).map((service_id, index) => {
                return <th key={`srv_${index}`}>{this.state.available_services[service_id]}</th>
            })}
        </>;
    }

    canSelectVendorLocation(vendor_location) {
        let can_select = this.state.setting_vendor == null && this.hasSelections();

        if (this.props.alternate_flow === "rtg") {
            if (vendor_location.vendor.alternate_flow === "rtg") {
                can_select = true;
            }else{
                if (this.props.can_select_non_rtg_vendors || this.props.service_override_zips.indexOf(this.state.postal_code) >= 0) {
                    can_select = can_select && vendor_location.vendor.enable_vendor_portal;
                }else{
                    can_select = false;
                }
            }
        } else {
            can_select = can_select && vendor_location.vendor.enable_vendor_portal;
        }
        return can_select;
    }

    renderVendorTable() {
        if (this.state.loading_vendors) {
            return (
                <div className="text-center text-muted mb-4 mt-2">
                    <Fa icon="spinner" spin/> loading service technicians...
                </div>
            );
        }

        if (this.state.vendors.length === 0) {
            return (
                <p className="lead mb-4 mt-2 text-center">No Service Technicians Found</p>
            );
        }

        return (

            <Table striped>
                <thead>
                <tr>
                    <th>Service Tech</th>
                    <th>Trip</th>
                    {this.renderPriceHeaders()}
                    <th>Total</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {this.state.vendors.map((vendor_location, index) =>
                    <tr key={vendor_location.id}>
                        <td>
                            <a href={`/tickets/${this.props.ticket}/vendors/${vendor_location.vendor.id}/`}>
                                {vendor_location.vendor.name}
                            </a>
                        </td>
                        {this.renderPriceColumns(vendor_location)}
                        <td className="text-right">
                                <span id={`vendor_select_${vendor_location.id}`} className="d-inline-block"
                                      tabIndex="0">
                                    <Button
                                        size="xs"
                                        outline
                                        color="primary"
                                        style={this.canSelectVendorLocation(vendor_location) ? {} : {pointerEvents: "none"}}
                                        onClick={this.selectVendor.bind(this, vendor_location)}
                                        disabled={!this.canSelectVendorLocation(vendor_location)}
                                    >
                                        {this.state.setting_vendor === vendor_location.id ? (
                                            <span><Fa icon="spinner" spin/> Select</span>
                                        ) : (
                                            <span>Select</span>
                                        )}

                                    </Button>
                                </span>

                            {!this.canSelectVendorLocation(vendor_location) && !vendor_location.vendor.enable_vendor_portal &&
                            <UncontrolledTooltip target={`vendor_select_${vendor_location.id}`}>
                                Vendor is not enabled for the vendor portal.
                            </UncontrolledTooltip>
                            }
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        );
    }

    renderDamage(damage, examination_claim) {
        return <>
            <DamageCodeList damage_names={damage.code_actual_names}/>
            {(examination_claim.available_services.length > 0)
                ?
                <Select2
                    data={examination_claim.available_services}
                    onSelect={this.changeService.bind(this, examination_claim.id, damage.id)}
                    value={this.state.selected_services[examination_claim.id][damage.id]}
                />
                :
                <div>No services available</div>
            }
        </>;
    }

    renderClaims() {
        return (
            <Row className="mb-3">
                {this.state.examination.examination_claims.map((examination_claim) =>
                    <Col sm={3} key={examination_claim.id}>
                        <h5>Claim {examination_claim.claim.name}</h5>
                        <div className="claim-item mb-2">{examination_claim.claim.item.description}</div>
                        {examination_claim.claim.damages.map((damage, index) =>
                            <div key={`claim_damage_${index}`} className="mt-2 mb-2">
                                {this.renderDamage(damage, examination_claim)}
                            </div>
                        )}
                    </Col>
                )}
            </Row>
        );
    }

    renderBackButton() {
        return (
            <div className="text-center">
                <a href={this.getSuccessURL()} className="btn btn-primary btn-wide">Back to Examination</a>
            </div>
        );
    }

    renderExtra() {

    }

    render() {
        return (
            <Card className="mb-3">
                <CardBody>
                    {this.state.loading_examination ? (
                        <div className="text-center text-muted mb-4 mt-2">
                            <Fa icon="spinner" spin/> loading details...
                        </div>
                    ) : (
                        <div>
                            {this.renderClaims()}

                            <form onSubmit={this.loadVendors}>
                                <Row>
                                    <Col sm={5}>
                                        <FieldInput label="Zip Code" value={this.state.postal_code}
                                                    onChange={linkState(this, 'postal_code')}/>
                                    </Col>
                                    <Col sm={2}>
                                        <div className="form-group">
                                            <label>&#160;</label>
                                            <Button outline color="primary" block
                                                    onClick={this.loadVendors}>Search</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>

                            {this.renderVendorTable()}
                        </div>
                    )}

                    {this.renderBackButton()}

                    {this.renderExtra()}

                </CardBody>
            </Card>
        );
    }
}

SelectVendorPanel.propTypes = {
    examination_id: PropTypes.number.isRequired
};

SelectVendorPanel.defaultProps = {};
