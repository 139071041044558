import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, ModalBody, ModalFooter} from 'reactstrap';


export class NoEmailModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal isOpen={this.props.showModal} toggle={this.props.cancelConfirmation}
                   backdrop={"static"}>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">Warning</h3>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.props.cancelConfirmation}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <hr/>
                    <p>No email on file to receive redemption email, do you want to continue?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={this.props.cancelConfirmation} className="btn-wide">
                        No, Cancel
                    </Button>
                    <Button color="primary" onClick={this.props.proceedConfirmation} className="btn-wide">
                        Yes, Proceed
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

NoEmailModal.propTypes = {
    proceedConfirmation: PropTypes.func,
    cancelConfirmation: PropTypes.func,
    showModal: PropTypes.bool
};
