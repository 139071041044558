import React from 'react';
import {ExaminationOption} from './examination_option';

export const ExaminationRepairOption = (props) => {return (
    <ExaminationOption {...props}>
        {props.selected_vendor &&
           <div>
               <small className="text-muted">
                   <i>
                       Service Tech:&#160;
                       <a href={`/tickets/${props.examination_claim.claim.ticket}/vendors/${props.selected_vendor.id}/`}>
                           {props.selected_vendor.name}
                       </a>
                   </i><br/>
               </small>
               {props.examination_claim.selected_services &&
                   <small className="text-muted"><i>Services: {_.join(_.map(props.examination_claim.selected_services, s => s.selected_service.name), ", ")}</i><br/></small>
               }
           </div>
        }
    </ExaminationOption>
)};

ExaminationRepairOption.propTypes = ExaminationOption.propTypes;
