import React from 'react';
import axios from 'axios';
import DuplicateContactMatch from './duplicate_contact_match';
import Paginator from '../paginator';
import Fa from "alpaca.js/dist/util/fa";


export class DuplicateContactList extends React.Component {
    constructor(props) {
        super(props);
        this.changePage = this.changePage.bind(this);

         this.state = {
             current_page: 1,
             start_index: 1,
             total_pages: 1,
             results: [],
             page_size: 20,
             loading:false,
        };
    }

    componentDidMount() {
        this.loadData(false);
    }

    changePage(page) {
        let component = this;
        this.setState(
            {current_page: page},
            function() {component.loadData(true);}
        );
    }

    loadData(scroll_to_top) {
        if(this.source) {
            this.source.cancel();
        }

        this.setState({loading: true});
        this.source = axios.CancelToken.source();

        let params = {page: this.state.current_page};

        if(this.props.contact) {
            params.contact = this.props.contact;
        }

        axios.get('/api/contacts-duplicates/', {params: params, cancelToken: this.source.token}).then((response) => {
            this.source = null;
            this.setState({
                results: response.data.results,
                current_page: response.data.current_page,
                total_pages: response.data.total_pages,
                page_size: response.data.page_size,
                loading: false,
                start_index: (this.state.current_page - 1) * this.state.page_size + 1
            });
            if(scroll_to_top) {
                window.scrollTo(0,0);
            }
        }).catch((error) => {
            this.setState({
                loading: false,
            });
        });
    }

    render() {
        return (
            <div>
                {this.state.results.map((result, index) =>
                    <DuplicateContactMatch key={result.id} contact_match={result} index={index+this.state.start_index} />
                )}

                {this.state.loading &&
                    <div className="text-center text-muted mb-2"><Fa icon="spinner" spin /> loading...</div>
                }

                {(!this.state.loading && this.state.results.length === 0) &&
                    <p className="lead text-center">No contact matches found</p>
                }

                <Paginator activePage={this.state.current_page} numPages={this.state.total_pages} onSelect={this.changePage} />
            </div>
        );
    }
}
