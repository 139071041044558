import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'reactstrap';
import { PaginatorButton } from './paginator_button';
import Fa from "alpaca.js/dist/util/fa";


export default class Paginator extends React.Component {
    constructor(props) {
        super(props);
    }

    renderPageButtons() {
        const pageButtons = [];

        let startPage;
        let endPage;
        let maxButtons = 4;

        if (maxButtons < this.props.numPages) {
            startPage = Math.max(
                Math.min(
                    this.props.activePage - Math.floor(maxButtons / 2, 10),
                    this.props.numPages - maxButtons + 1
                ),
                1
            );
            endPage = startPage + maxButtons - 1;
        } else {
            startPage = 1;
            endPage = this.props.numPages;
        }

        for (let page = startPage; page <= endPage; ++page) {
            pageButtons.push(
                <PaginatorButton active={page === this.props.activePage} onSelect={this.props.onSelect} key={page} eventKey={page}>
                    {page}
                </PaginatorButton>
            );
        }

        if (startPage > 1) {
            if (startPage > 2) {
                pageButtons.unshift(
                    <PaginatorButton disabled key="ellipsisFirst">
                        <span aria-label="More">
                            {'\u2026'}
                        </span>
                    </PaginatorButton>
                );
            }

            pageButtons.unshift(
                <PaginatorButton key={1} active={false} onSelect={this.props.onSelect} eventKey={1}>
                    1
                </PaginatorButton>
            );
        }

        if (endPage < this.props.numPages) {
            if (endPage < this.props.numPages - 1) {
                pageButtons.push(
                    <PaginatorButton disabled key="ellipsis">
                        <span aria-label="More">
                            {'\u2026'}
                        </span>
                    </PaginatorButton>
                );
            }

            pageButtons.push(
                <PaginatorButton active={false} onSelect={this.props.onSelect} key={this.props.numPages} eventKey={this.props.numPages}>
                    {this.props.numPages}
                </PaginatorButton>
            );
        }

        return pageButtons;
    }

    render() {
        if(this.props.numPages <= 1) {
            return null;
        }

        return (
            <div className="pagination-container">
                <div className="pagination-navigation">
                    <Pagination>
                        <PaginatorButton disabled={this.props.activePage === undefined || this.props.activePage === 1} onSelect={this.props.onSelect} eventKey={this.props.activePage - 1}>
                            <span aria-hidden="true"><Fa icon="chevron-left"/></span>
                        </PaginatorButton>
                        {this.renderPageButtons()}
                        <PaginatorButton disabled={this.props.activePage === this.props.numPages} onSelect={this.props.onSelect} eventKey={this.props.activePage + 1}>
                            <span aria-hidden="true"><Fa icon="chevron-right"/></span>
                        </PaginatorButton>
                    </Pagination>
                </div>
            </div>
        );
    }
}

Paginator.propTypes = {
    activePage: PropTypes.number,
    numPages: PropTypes.number,
    onSelect: PropTypes.func
};
