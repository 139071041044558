import React, {Fragment} from 'react';
import {ExaminationOption} from './examination_option';
import { Row, Col } from 'reactstrap';
import { yesno, currency, lineBreaks } from '../../util';
import QuoteDetailModal from './quote_detail_modal';


const PartDetail = (props) => {
    return (
        <Fragment>
            <Row>
                <Col sm={4}>
                    <dt>Part</dt>
                    <dd>{props.description}</dd>
                </Col>
                <Col sm={4}>
                    <dt>Part #</dt>
                    <dd>{props.part_number}</dd>
                </Col>
                <Col sm={4}>
                    <dt>Available</dt>
                    <dd>
                        {yesno(props.available)}<br/>
                        {props.available && <span>{props.available_time_frame}</span>}
                    </dd>
                </Col>
            </Row>

            <Row>
                <Col sm={4}>
                    <dt>Part Price</dt>
                    <dd>
                        {props.wholesale_price ? currency(props.wholesale_price) : <span className="text-muted">&mdash;</span>}
                    </dd>
                </Col>

                <Col sm={4}>
                    <dt>Delivery</dt>
                    <dd>
                        {props.delivery_price ? currency(props.delivery_price) : <span className="text-muted">&mdash;</span>}
                    </dd>
                </Col>

                <Col sm={4}>
                    <dt>Installation</dt>
                    <dd>
                        {props.installation_available ?
                            (props.installation_price ? currency(props.installation_price) : <span className="text-muted">&mdash;</span>)
                            : <span className="text-muted"><em>N/A</em></span>
                        }
                    </dd>
                </Col>

            </Row>
            <hr />
        </Fragment>
    );
};

export const ExaminationReplacePartsOption = (props) => {

    let plan = props.examination_claim.claim.plan;
    let quote_request = props.examination.quote_request;
    let quote_request_claim = null;
    if (quote_request) {
        quote_request_claim = _.filter(quote_request.quote_request_claims, ((x) => x.examination_claim_id === props.examination_claim.id))[0];
    }

    return (
        <ExaminationOption {...props}>
            {quote_request &&
                <QuoteDetailModal quote_status={quote_request.status} ticket_id={props.examination.ticket} retailer_id={plan.retailer.id}>
                    <hr/>
                    {plan.retailer !== null &&
                    <Fragment>
                        <dt>Retailer</dt>
                        <dd>
                            <a href={`/tickets/${props.examination.ticket}/retailers/${plan.retailer.id}/`} target="_blank">
                                {plan.retailer.name}
                            </a>
                        </dd>
                    </Fragment>
                    }

                    {quote_request_claim.parts.map((part, index) =>
                        <PartDetail {...part} key={`qrc_${quote_request_claim.id}_p_${index}`}/>
                    )}

                    {quote_request.response_notes && <Fragment>
                        <h5>Notes from Retailer</h5>
                        <p>{lineBreaks(quote_request.response_notes)}</p>
                    </Fragment>}

                </QuoteDetailModal>
            }
        </ExaminationOption>
    )

};

ExaminationReplacePartsOption.propTypes = ExaminationOption.propTypes;
