import React from 'react';
import Select2 from '../forms/select2';
import {FormGroup, Label, Input} from 'reactstrap';
import { setIn } from 'immutable-setter';
import { Row, Col, Collapse } from 'reactstrap';
import axios from "axios/index";


export class PlanItemDropdowns extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            item_class: this.props.class,
            item_category: this.props.category,
            item_type: this.props.type,
            item_material: this.props.material,
            item_categories: [],
            item_types: [],
            item_materials: [],
        };
        this.changeValue = this.changeValue.bind(this)
    }

    componentDidMount() {
        this.loadCategories(1);

        if(this.state.item_category) {
            this.loadTypes(1);
        }

        this.loadMaterials(1);
    }

    changeValue(e) {
        let state = {};
        state[e.target.name] = e.target.value;
        this.setState(state);

        if(e.target.name === 'item_category') {
            this.setState({item_types: []});
            this.loadTypes(1);
        }
    }

    loadCategories(page, previous_data) {
        let params = {page: page};

        if(!previous_data) {
            previous_data = [];
        }

        axios.get('/api/item-categories/', {params: params}).then((response) => {
            let data = _.concat(previous_data, response.data.results);

            if(response.data.next !== null) {
                this.loadCategories(page+1, data);
            } else {
                this.setState({item_categories: data});
            }
        });
    }

    loadTypes(page, previous_data) {
        if(!this.state.item_category) {
            return;
        }

        let params = {page: page, item_categories: this.state.item_category};

        if(!previous_data) {
            previous_data = [];
        }

        axios.get('/api/item-types/', {params: params}).then((response) => {
            let data = _.concat(previous_data, response.data.results);

            if(response.data.next !== null) {
                this.loadTypes(page+1, data);
            } else {
                this.setState({item_types: data});
            }

        });
    }

    loadMaterials(page, previous_data) {
        let params = {page: page};

        if(!previous_data) {
            previous_data = [];
        }

        axios.get('/api/item-materials/', {params: params}).then((response) => {
            let data = _.concat(previous_data, response.data.results);

            if(response.data.next !== null) {
                this.loadMaterials(page+1, data);
            } else {
                this.setState({item_materials: data});
            }

        });
    }

    render() {
        return (
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <Label for="id_item_category">Category</Label>
                        <Select2
                            onSelect={this.changeValue}
                            name="item_category"
                            value={this.state.item_category}
                            data={this.state.item_categories}
                            minimumResultsForSearch={-1}
                            id="id_item_category"
                            disabled={this.state.item_categories.length === 0}
                            emptyLabel="---------"
                            data-parsley-required="true"
                            required
                            data-parsley-required-message="This field is required." />
                           <p className={"form-text text-muted"}>{this.props.help_text["item_category"]}</p>
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup>
                        <Label for="id_item_type">Type</Label>
                        <Select2
                            onSelect={this.changeValue}
                            name="item_type"
                            value={this.state.item_type}
                            data={this.state.item_types}
                            id="id_item_type"
                            disabled={this.state.item_types.length === 0}
                            emptyLabel="---------"
                            data-parsley-required="true"
                            required
                            data-parsley-required-message="This field is required." />
                        <p className={"form-text text-muted"}>{this.props.help_text["item_type"]}</p>
                    </FormGroup>
                </Col>

                <Col sm={4}>
                    <FormGroup>
                        <Label for="id_item_material">Material</Label>
                        <Select2
                            onSelect={this.changeValue}
                            name="item_material"
                            value={this.state.item_material}
                            data={this.state.item_materials}
                            id="id_item_material"
                            disabled={this.state.item_materials.length === 0}
                            emptyLabel="---------"
                            data-parsley-required="true"
                            required
                            data-parsley-required-message="This field is required." />
                            <p className={"form-text text-muted"}>{this.props.help_text["item_material"]}</p>
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}
