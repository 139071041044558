import React from 'react';
import {Button} from 'reactstrap';

export class TicketSelectAll extends React.Component {
    constructor(props) {
        super(props);
        this.selectAll = this.selectAll.bind(this);
        this.state = {
            all_selected: false
        };
    }

    componentDidMount() {
        const self = this;
        const inputs = $('input[name=change_assignee]');
        inputs.change((e) => {
            self.setState({all_selected: _.every(inputs, {checked: true})});
        });
    }

    selectAll() {
        if (!this.state.all_selected) {
            this.setState({all_selected: true});
            $('input[name=change_assignee]').prop('checked', true);
        } else {
            this.setState({all_selected: false});
            $('input[name=change_assignee]').prop('checked', false);
        }
    }

    render() {
        return (
            <div>
                <Button outline color="primary" size="xs" className="btn-wide" id="select-all" onClick={this.selectAll}>
                    {this.state.all_selected ? 'Unselect All' : 'Select All'}</Button>
            </div>
        );
    }
}
