import React from 'react';

import {Col, Row} from 'reactstrap';
import CheckBoxInput from "../../forms/checkbox_input";
import linkState from '../../link_state';
import FieldInput from "../../forms/field_input";

import {ExaminationConfirmationWrapper, validate_resolution} from './common';
import {currencyLinkState} from "../../util";


export default class SettlementResolution extends React.Component {
    constructor(props) {
        super(props);

        let initial_data = this.calculateTotals(props.data);
        this.state = {
            data: initial_data
        };

        // push the calculated totals and any other changes up to the parent object
        this.props.onChange(initial_data);
    }

    calculateTotals(data) {
        // given initial data from props, or updated data after a state change, calculate totals and ensure
        // all resolution claims have the necessary extra_data.  Returns the update data object.

        // helper func to get an examination_claim object from props by id
        let examination_claims = this.props.examination.examination_claims;
        let find_examination_claim = (id) => _.filter(examination_claims, ec => ec.id === id)[0];

        data.calculated_amount = 0;

        for (let index in data.resolution_claims) {
            let resolution_claim = data.resolution_claims[index];
            let examination_claim = find_examination_claim(resolution_claim.examination_claim);

            let amount = parseFloat(examination_claim.options[this.props.data.option_type].amount);
            data.calculated_amount += amount;
        }

        if (!data.override) {
            data.authorized_amount = data.calculated_amount;
        } else {
            data.authorized_amount = parseFloat(data.authorized_amount);
        }

        data.can_submit = validate_resolution(data);

        return data;
    }

    onLinkStateChange() {
        // triggered after state is updated from a form field change
        let updated_data = this.calculateTotals(this.state.data);
        this.setState({
            data: updated_data,
        });
        this.props.onChange(updated_data);
    }

    renderOverride() {
        return (
            <Row>
                <Col>
                    <FieldInput
                        label="Authorized Amount"
                        value={this.state.data.authorized_amount}
                        onChange={currencyLinkState(this, "data", ["authorized_amount"])}
                        type="number"/>
                </Col>
                <Col sm={12}>
                    <FieldInput
                        label="Override Reason"
                        onChange={linkState(this, "data", ["override_reason"])}
                        value={this.state.data.override_reason}/>
                </Col>
            </Row>
        );
    }

    render() {
        let data = this.state.data;

        return (
            <ExaminationConfirmationWrapper title="Settlement" total={data.calculated_amount}>
                <div>
                    <CheckBoxInput
                        label="Override Authorized Amount"
                        onChange={linkState(this, "data", ["override"])}
                        checked={data.override}/>

                    {data.override && this.renderOverride()}
                </div>

                <FieldInput
                    type="textarea"
                    label="Notes"
                    value={data.notes}
                    onChange={linkState(this, "data", ["notes"])}/>

            </ExaminationConfirmationWrapper>
        );
    }
}
