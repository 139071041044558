import React from "react";

import { InteractionsPanel } from './interactions_panel';

export class RetailerInteractionsPanel extends InteractionsPanel {
    constructor(props) {
        super(props);
        this.state.can_email_reply = true;
    }

    getTabs() {
        return {
            all: {
                name: 'All',
                icon: 'icon-bars'
            },
            email: {
                name: 'Email',
                icon: 'icon-email'
            },
            note: {
                name: 'Internal Note',
                icon: 'icon-note'
            },
            system: {
                name: 'System Message',
                icon: 'icon-system'
            },
        }
    }

    getFilters() {
        return [
            {
                name: 'All',
                id: 'all',
            },
            {
                name: 'Agent',
                id: 'agent',
            },
            {
                name: 'Retailer',
                id: 'retailer',
            },
            {
                name: 'Critical',
                id: 'critical',
            }
        ]
    }
}
