import React from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import DamageLocation from './damage_location';
import {deleteIn, setIn} from 'immutable-setter';

export default class DamageLocationFormset extends React.Component {
    constructor(props) {
        super(props);
        this.handleAddAnother = this.handleAddAnother.bind(this);

        let locations = this.props.locations;
        if(locations.length === 0) {
            locations = [this.getEmptyLocation()];
        }

        this.state = {
            locations: locations
        };
    }

    getEmptyLocation() {
        return {
             location_id: '',
             size: '',
             quantity: '',
         };
    }

    handleAddAnother(e) {
        e.preventDefault();
        this.setState({locations: this.state.locations.concat([this.getEmptyLocation()])});
    }

    handleRemove(index) {
        const { onChange } = this.props;

        this.setState(
            {locations: deleteIn(this.state.locations, [index])},
            function () {
                if (onChange) {
                    onChange(this.state.locations);
                }
            }
        );
    }

    handleChange(index, location) {
        const { onChange } = this.props;

        this.setState(
            {'locations': setIn(this.state.locations, [index], location)},
            function () {
                if (onChange) {
                    onChange(this.state.locations);
                }
            }
        );
    }

    getErrors(index) {
        if(this.props.errors !== undefined && this.props.errors.length > index) {
            return this.props.errors[index];
        }

        return null;
    }

    render() {
        return (
            <Card color="light" className="mb-4">
                <CardBody>
                    {this.state.locations.map((result, index) =>
                        <div key={`location_${this.state.locations.length}_${index}`} style={{paddingRight: 140, position: "relative"}}>
                            <DamageLocation
                                key={index}
                                location={result}
                                onChange={this.handleChange.bind(this, index)} errors={this.getErrors(index)}
                                damage_location_types={this.props.damage_location_types}
                            />

                            {index > 0 &&
                                <Button onClick={this.handleRemove.bind(this, index)} color="primary" outline style={{position: "absolute", right: 0, top: 23}}>
                                    <Fa icon="minus-circle"/> Remove
                                </Button>
                            }
                        </div>
                    )}

                    <a href="#" className="font-weight-bold" onClick={this.handleAddAnother}>
                        <Fa icon="plus" spacing={3} size={1} />
                        Add Another Damage Location
                    </a>
                </CardBody>
            </Card>
        );
    }
}
