export function get_primary_contact_item(items) {
    for(var index in items) {
        if(items[index].primary) {
            return items[index];
        }
    }

    if(items.length > 0) {
        return items[0];
    }

    return null;
}


export function get_shipping_address(addresses) {
    for(var index in addresses) {
        if(addresses[index].type === "shipping") {
            return addresses[index];
        }
    }

    if(addresses.length > 0) {
        return addresses[0];
    }

    return null;
}
