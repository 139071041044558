import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Button, Input, Modal, ModalBody, ModalFooter} from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import FieldInput from '../forms/field_input';
import linkState from "../link_state";


export class MoveToTicketButton extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.doMove = this.doMove.bind(this);

        this.state = {
            modal_opened: false,
            moving: false,
            ticket: "",
            error: null
        };
    }

    toggleModal(e) {
        if(this.state.moving) {
            return;
        }

        if(e) {
            e.preventDefault();
        }

        this.setState({ modal_opened: !this.state.modal_opened });
    }

    doMove() {
        if(_.trim(this.state.ticket).length === 0) {
            return;
        }

        this.setState({moving: true, error: null});

        axios.post(`/api/messages/${this.props.message}/move_to_ticket/`, {ticket: _.trim(this.state.ticket)}).then((response) => {
            this.props.onComplete();
        }).catch((error) => {
            if (error.response.status === 400) {
                console.log(error.response);
                this.setState({
                    moving: false,
                    error: error.response.data.ticket[0]
                });
            }
        });
    }

    renderModal() {
       return (
            <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal} backdrop={this.state.moving ? "static" : true}>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">Move to New Ticket</h3>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.toggleModal} disabled={this.state.restarting}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <hr/>
                    <p>Enter the ticket number of the ticket to move this message to.</p>
                    <FieldInput
                        label={null}
                        onChange={linkState(this, 'ticket')}
                        value={this.state.ticket}
                        error={this.state.error} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.moving} className="btn-wide">Cancel</Button>
                    <Button color="primary" onClick={this.doMove} disabled={this.state.moving || _.trim(this.state.ticket).length === 0}
                            className="btn-wide">
                        {this.state.moving ? (
                            <span><Fa icon="spinner" spin/> Moving...</span>
                        ) : (
                            "Move Message"
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {

        return (
            <span>
                <a onClick={this.toggleModal} className="dropdown-item" style={{cursor: "pointer"}}>
                    <Fa icon="external-link-square-alt" /> Move to New Ticket
                </a>

                {this.renderModal()}
            </span>
        )
    }
}

MoveToTicketButton.propTypes = {
    message: PropTypes.number.isRequired,
    onComplete: PropTypes.func
};
