import { hot } from 'react-hot-loader/root';

export function register(name, component) {
    window[name] = hot(component);
}

require('./libs');
require('./config');

import { styles } from '../css/style.scss';

import { InteractionsPanel } from './interactions/interactions_panel';
import { InteractionsOverviewPanel } from './interactions/interactions_overview_panel';
import { CallWidget } from './call_widget';
import { CallWidgetToggle } from './call_widget_toggle';
import { DuplicateContactList } from './contacts/duplicate_contact_list';
import { ExceptionGuide } from './exception_guide';
import { ClaimDamageDetailsFormset } from './claims/claim_damage_details_formset';
import { ClaimDamageDetailsFormsetConfirm } from './claims/claim_damage_details_formset_confirm';
import { ClaimDecision } from './claims/claim_decision';
import { ClaimDocumentation } from './claims/claim_documentation';
import { ContactSelector } from './contacts/contact_selector.jsx';
import { PlanItemQuantity } from './plans/plan_item_quantity';
import { TicketSelectAll } from "./tickets/select_all";
import { TicketReassignSelected } from "./tickets/reassign_selected";
import { TicketServiceGroupForm } from "./tickets/service_group_form";
import { ClaimCancelButton } from "./claims/claim_cancel_button";
import { VendorInteractionsPanel } from "./interactions/vendor_interactions_panel";
import { RetailerInteractionsPanel } from "./interactions/retailer_interactions_panel";
import { ExaminationPanel } from "./examination/examination_panel";
import { SelectVendorPanel } from "./examination/select_vendor_panel";
import {SelectVendorInstallInspectPanel} from './examination/select_vendor_install_inspect_panel';
import {PlanItemDropdowns} from "./plans/plan_item_dropdowns";
import ViewReceiptButton from "./plans/view_receipt_button";
import {RestartExaminationButton} from "./examination/restart_examination_button";
import {StartExaminationButton} from "./examination/start_examination_button";
import {FinalizeClaimsButton} from "./examination/finalize_claims_button";
import {CancelWorkOrderButton} from "./examination/cancel_work_order_button";
import AttachmentViewer from "./attachment_viewer";
import {ContactUnlinkButton} from "./contacts/contact_unlink_button";
import {ClaimSubStatusDropdown} from "./claims/claim_sub_status_dropdown";
import {CancelResolutionButton} from "./examination/cancel_resolution_button";
import {ReplyBox} from "./interactions/replybox";
import {CheckIssuedButton} from "./examination/check_issued_button";
import InvoiceDetail from "./invoices/invoice_detail";
import TableCheckboxSelectAllHeader from "alpaca.js/dist/util/tableCheckboxSelectAllHeader";
import InvoiceApproveSelectedButton from "./invoices/approve_selected";
import TableCheckboxActionButton from "alpaca.js/dist/util/tableCheckboxActionButton";
import APITableCheckboxActionButton from "alpaca.js/dist/util/apiTableCheckboxActionButton";
import InvoiceDownloadButton from "./invoices/download_button";
import InvoiceUpload from "./invoices/invoiceUpload";
import InvoiceUploadErrors from "./invoices/invoiceUploadErrors";
import BulkUploadCountButton from "alpaca.js/dist/bulk_upload/bulkUploadCountButton";

register('InteractionsPanel', InteractionsPanel);
register('InteractionsOverviewPanel', InteractionsOverviewPanel);
register('CallWidget', CallWidget);
register('CallWidgetToggle', CallWidgetToggle);
register('DuplicateContactList', DuplicateContactList);
register('ExceptionGuide', ExceptionGuide);
register('ClaimDamageDetailsFormset', ClaimDamageDetailsFormset);
register('ClaimDamageDetailsFormsetConfirm', ClaimDamageDetailsFormsetConfirm);
register('ClaimDecision', ClaimDecision);
register('ClaimDocumentation', ClaimDocumentation);
register('ContactSelector', ContactSelector);
register('PlanItemQuantity', PlanItemQuantity);
register('TicketSelectAll', TicketSelectAll);
register('TicketReassignSelected', TicketReassignSelected);
register('TicketServiceGroupForm', TicketServiceGroupForm);
register('ClaimCancelButton', ClaimCancelButton);
register('VendorInteractionsPanel', VendorInteractionsPanel);
register('RetailerInteractionsPanel', RetailerInteractionsPanel);
register('ExaminationPanel', ExaminationPanel);
register('SelectVendorPanel', SelectVendorPanel);
register('PlanItemDropdowns', PlanItemDropdowns);
register('AttachmentViewer', AttachmentViewer);
register('ViewReceiptButton', ViewReceiptButton);
register('SelectVendorInstallInspectPanel', SelectVendorInstallInspectPanel);
register('RestartExaminationButton', RestartExaminationButton);
register('StartExaminationButton', StartExaminationButton);
register('FinalizeClaimsButton', FinalizeClaimsButton);
register('CheckIssuedButton', CheckIssuedButton);
register('CancelWorkOrderButton', CancelWorkOrderButton);
register('CancelWorkOrderButton', CancelWorkOrderButton);
register('ContactUnlinkButton', ContactUnlinkButton);
register('ClaimSubStatusDropdown', ClaimSubStatusDropdown);
register('CancelResolutionButton', CancelResolutionButton);
register('ReplyBox', ReplyBox);
register("InvoiceDetail", InvoiceDetail);
register("TableCheckboxSelectAllHeader", TableCheckboxSelectAllHeader);
register("InvoiceApproveSelectedButton", InvoiceApproveSelectedButton);
register("TableCheckboxActionButton", TableCheckboxActionButton);
register("APITableCheckboxActionButton", APITableCheckboxActionButton);
register("InvoiceDownloadButton", InvoiceDownloadButton);
register('InvoiceUpload', InvoiceUpload);
register('InvoiceUploadErrors', InvoiceUploadErrors);
register('BulkUploadCountButton', BulkUploadCountButton);

