import React, {Fragment} from 'react';

import {Button, Col, Collapse, Row, Table} from 'reactstrap';
import CheckBoxInput from "../forms/checkbox_input";
import linkState from '../link_state';
import FieldInput from "../forms/field_input";
import SelectInput from '../forms/select_input';
import {currency, currencyLinkState, lineBreaks, yesno} from '../util';
import {setIn} from "immutable-setter";
import Fa from "alpaca.js/dist/util/fa";


const OPTION_NAMES_MAP = {
    'repair': 'Repair',
    'replace_parts': 'Replace Parts',
    'reselection': 'Reselection',
    'settlement': 'Settlement',
    'refund': 'Refund',
    'manual': 'Manual'
};


const NOTES_LABEL_MAP = {
    'repair': 'Additional Notes to Service Tech',
    'replace_parts': 'Additional Notes to Retailer',
    'manual': 'Notes',
    'reselection': 'Notes',
    'settlement': 'Notes',
};

const DEFAULT_PART = {
    'available': null,
    'available_time_frame': '',
    'description': '',
    'part_number': '',
    'wholesale_price': '',
    'delivery_price': '',
    'installation_price': ''
};

const ReselectionHeader = (props) => {
    return (
        <Fragment>
            <div>
                <h5 className="d-inline-block" style={{"width":  "300px"}}>Original Authorized Amount</h5>
                {currency(props.calculated_amount)}
            </div>
            <div>
                <h5 className="d-inline-block" style={{"width":  "300px"}}>Authorized Amount</h5>
                {currency(props.authorized_amount)}
            </div>
        </Fragment>
    );
};

const Header = (props) => {
    return (
        <div>
            <h4 className="d-inline-block mr-4">{OPTION_NAMES_MAP[props.option_type]}</h4>
            {props.option_type !== 'reselection' &&
                <Fragment>
                    {props.calculated_amount ?
                        (currency(props.calculated_amount)) :
                        (<small className="text-muted">unknown price</small>)
                    }
                </Fragment>
            }
        </div>
    );
};

class QuoteResponseNote extends React.Component {
    constructor(props) {
        super(props);
        this.toggleNote = this.toggleNote.bind(this);
        this.state = {
            note_open: false,
        };
    }

    toggleNote(e) {
        e.preventDefault();
        this.setState({note_open: !this.state.note_open});
    }

    render() {
        return (
            <div className="mb-2">
                <a className="small" onClick={this.toggleNote}
                   href="#">{this.state.note_open ? 'Hide' : 'Show'} Notes from Retailer</a>

                <Collapse isOpen={this.state.note_open}>
                    <p className="small">{lineBreaks(this.props.response_notes)}</p>
                </Collapse>
            </div>
        );
    }
}

function get_parts_for_selection(examination, resolution_claims) {
    // Builds the initial object for claim_parts state with this layout:
    //   { <examination_claim_id>: { 'claim_name': <name>, 'parts': <parts array> } }

    const quote_request = examination.quote_request;
    if (quote_request === null) {
        return {};
    }
    let examination_claim_parts = {};

    for (let id in resolution_claims) {
        let examination_claim_id = resolution_claims[id].examination_claim;
        let quote_request_claims = _.filter(quote_request.quote_request_claims, (x => x.examination_claim_id === examination_claim_id));
        let examination_claim = _.filter(examination.examination_claims, (x => x.id === examination_claim_id))[0];

        for (let qrc_id in quote_request_claims) {
            // react complains when setting form values to null - some values in the parts object could be null.
            // this loops over each part, pulls out any key with null value, then sets any missing keys to empty strings
            let parts = [];
            _.forEach(quote_request_claims[qrc_id].parts, parts_obj => {
                parts.push(_.defaults(_.omitBy(parts_obj, _.isNull), DEFAULT_PART));
            });

            examination_claim_parts[examination_claim_id] = {
                'claim_name': examination_claim.claim.name,
                'parts': parts,
            }

        }
    }
    return examination_claim_parts;
}

function has_quote_response(quote_request, resolution_claims) {
    if (quote_request === null || quote_request.response_notes === null) {
        return false;
    }

    for (let id in resolution_claims) {
        let examination_claim_id = resolution_claims[id].examination_claim;
        let quote_request_claim = _.filter(quote_request.quote_request_claims, ((x) => x.examination_claim_id === examination_claim_id))[0];
        if (quote_request_claim !== undefined) {
            return true;
        }
    }
    return false;
}

export class ExaminationModalOption extends React.Component {
    constructor(props) {
        super(props);
        this.addPart = this.addPart.bind(this);
        this.removePart = this.removePart.bind(this);

        this.state = {
            data: props.data,
            examination_claim_parts: get_parts_for_selection(props.examination, props.data.resolution_claims),
        };
    }

    onLinkStateChange() {
        // perform override calculations on authorized amount for reselections
        let data = this.state.data;
        if (data.option_type === "reselection") {
            if (data.override) {
                let total = 0.0;
                let reason = Array();
                // loop through the claims, calculate the authorized amount, and populate the
                // override_reason, and make sure that there is an extra_data for the line item info
                for (let i in data.resolution_claims) {
                    let claim = data.resolution_claims[i];
                    if (JSON.stringify(claim.extra_data) === '{}') {
                        claim.extra_data = {
                            override_amount: claim.authorized_amount,
                            override_reason: ''
                        };
                    }
                    claim.extra_data.override_amount = parseFloat(claim.extra_data.override_amount);
                    total = total + claim.extra_data.override_amount;
                    if (claim.extra_data.override_reason) {
                        reason.push(claim.extra_data.override_reason);
                    }
                }
                data.authorized_amount = total;
                data.override_reason = reason.join(", ");
            } else {
                // put back default
                data.authorized_amount = data.calculated_amount;
                for (let id in data.resolution_claims) {
                    let claim = data.resolution_claims[id];
                    claim.extra_data = {};
                }
            }
        }
        this.props.onChange(this.state.data);
    }


    renderOverride() {
        let result = Array();
        if (this.props.data.option_type === 'reselection') {

            for (let id in this.props.examination.examination_claims) {
                let examination_claim = this.props.examination.examination_claims[id];
                if (this.props.selections[examination_claim.id] === 'reselection') {
                    result.push(<Row key={id}>
                        <Col>
                            <div>
                                <h6 className="d-inline-block">Claim {examination_claim.claim.name}</h6> {examination_claim.claim.item.description}
                            </div>
                            <div className="ml-4">
                                <FieldInput
                                    label="Authorized Amount"
                                    value={this.state.data.resolution_claims[id].extra_data.override_amount}
                                    onChange={currencyLinkState(this, "data", ["resolution_claims", parseInt(id), "extra_data", "override_amount"])}
                                    type="number"/>
                                <FieldInput
                                    label="Override Reason"
                                    value={this.state.data.resolution_claims[id].override_reason}
                                    onChange={linkState(this, "data", ["resolution_claims", parseInt(id), "extra_data", "override_reason"])}
                                />
                            </div>
                        </Col>
                    </Row>);
                }
            }
        } else {
            result.push(<Row key={`override_0`}>
                <Col>
                    <FieldInput
                        label="Authorized Amount"
                        value={this.state.data.authorized_amount}
                        onChange={currencyLinkState(this, "data", ["authorized_amount"])}
                        type="number"/>
                </Col>
                <Col sm={12}>
                    <FieldInput
                        label="Override Reason"
                        onChange={linkState(this, "data", ["override_reason"])}
                        value={this.state.data.override_reason}/>
                </Col>
            </Row>);
        }
        return result;
    }

    addPart(examinations_claim_id, e) {
        let new_parts = [...this.state.examination_claim_parts[examinations_claim_id].parts, DEFAULT_PART];
        this.setState({
            examination_claim_parts: setIn(this.state.examination_claim_parts, [examinations_claim_id, "parts"], new_parts)
        });
    }

    removePart(examinations_claim_id, index) {
        this.setState(prevState => {
                let new_parts = prevState.examination_claim_parts[examinations_claim_id].parts.filter((value, idx) => idx !== index);
                return {examination_claim_parts: setIn(prevState.examination_claim_parts, [examinations_claim_id, 'parts'], new_parts)};
            }
        );
    }

    renderPart(part, examinations_claim_id, index) {
        // console.log('rendering part', part, index);
        const time_frame = <span style={{'whiteSpace': 'nowrap'}}>{part.available_time_frame}</span>;

        return (
            <tr key={`part_${index}`}>
                <td>
                    {(part.available !== null) ?
                        ((part.available) ? (<Fragment>Yes<br/>{time_frame}</Fragment>) : ('No')) :
                        ("Unknown")
                    }
                </td>
                <td>
                    <FieldInput
                        value={part.description}
                        onChange={linkState(this, "parts", [index, "description"])} />
                </td>
                <td>
                    <FieldInput
                        value={part.part_number}
                        onChange={linkState(this, "parts", [index, "part_number"])} />
                </td>
                <td>
                    <FieldInput
                        value={part.wholesale_price}
                        onChange={currencyLinkState(this, "parts", [index, "wholesale_price"])}
                        type="number"/>
                </td>
                <td>
                    <FieldInput
                        value={part.delivery_price}
                        onChange={currencyLinkState(this, "parts", [index, "delivery_price"])}
                        type="number"/>
                </td>
                <td>
                    <FieldInput
                        value={part.installation_price}
                        onChange={currencyLinkState(this, "parts", [index, "installation_price"])}
                        type="number"/>
                </td>
                <td>
                    <Button className="btn btn-danger btn-sm" onClick={this.removePart.bind(null, examinations_claim_id, index)}>
                        <Fa icon="trash-alt" className="mr-0 ml-0" />
                    </Button>
                </td>
            </tr>
        );
    }

    renderInner() {
        let data = this.state.data;
        let quote_request = this.props.examination.quote_request;

        return (
            <Fragment>
                {(data.option_type === "replace_parts" || data.option_type === "reselection") && (
                    has_quote_response(this.props.examination.quote_request, data.resolution_claims) && (
                        <QuoteResponseNote response_notes={quote_request.response_notes}/>
                    )
                )}

                {(data.option_type === "repair" && this.props.examination.selected_vendor) &&
                <div className="mb-2">
                    <small className="text-muted">
                        Service Tech:&#160;
                        <a href={`/tickets/${this.props.examination.ticket}/vendors/${this.props.examination.selected_vendor.id}/`}>
                            {this.props.examination.selected_vendor.name}
                        </a>
                        <br/>
                    </small>
                    {data.selected_service &&
                    <small className="text-muted">Service: {data.selected_service.name}<br/></small>
                    }
                </div>
                }

                {data.option_type !== 'manual' &&
                <div>
                    <CheckBoxInput
                        label="Override Authorized Amount"
                        onChange={linkState(this, "data", ["override"])}
                        checked={data.override}/>

                    {data.override &&
                    this.renderOverride()
                    }
                </div>
                }

                {data.option_type === "replace_parts" &&
                    <Fragment>
                        <Row>
                            <Col>
                                <SelectInput
                                    value={data.delivery_method}
                                    label="Delivery Method"
                                    choices={[["ship_to_customer", "Ship to Customer"], ["ship_to_retailer", "Ship to Retailer"]]}
                                    minimumResultsForSearch={20}
                                    onChange={linkState(this, "data", ["delivery_method"])}/>
                            </Col>
                            <Col>
                                <FieldInput
                                    label="Authorized Delivery Amount"
                                    value={data.authorized_delivery_amount}
                                    onChange={currencyLinkState(this, "data", ["authorized_delivery_amount"])}
                                    type="number"/>
                            </Col>
                        </Row>
                        {_.toPairs(this.state.examination_claim_parts).map(([ec_id, ec_data], index) => {
                            return (
                                <Fragment key={`ec${ec_id}idx${index}`}>
                                    <h5>
                                        Claim {ec_data.claim_name}
                                        <Button className="btn-xs btn-outline-primary ml-4" onClick={this.addPart.bind(null, ec_id)}>Add Part</Button>
                                    </h5>
                                    <Table className="mb-1">
                                        <thead>
                                        <tr>
                                            <th>Available</th>
                                            <th>Description</th>
                                            <th>Part #</th>
                                            <th className="price-col">Price</th>
                                            <th className="price-col">Delivery</th>
                                            <th className="price-col">Installation</th>
                                            <th> </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {ec_data.parts.map((part, index) => { return this.renderPart(part, ec_id, index)} )}
                                        </tbody>
                                    </Table>
                                </Fragment>
                            )
                        })}
                    </Fragment>
                }

                {data.option_type === "reselection" &&
                <FieldInput
                    label="Authorized Delivery Amount"
                    value={data.authorized_delivery_amount}
                    onChange={currencyLinkState(this, "data", ["authorized_delivery_amount"])}
                    type="number"/>
                }

                {data.option_type === "manual" &&
                <SelectInput
                    value={data.manual_resolution_reason}
                    label="Reason"
                    choices={this.props.manual_reasons}
                    minimumResultsForSearch={20}
                    onChange={linkState(this, "data", ["manual_resolution_reason"])}/>
                }

                {NOTES_LABEL_MAP[data.option_type] !== undefined && (
                    <FieldInput
                        type="textarea"
                        label={NOTES_LABEL_MAP[data.option_type]}
                        value={data.notes}
                        onChange={linkState(this, "data", ["notes"])}/>
                )}

            </Fragment>
        )
    }

    render() {
        let header_props = _.pick(this.state.data, ["calculated_amount", "authorized_amount", "option_type" ]);

        return (
            <div className="mt-4 examination-detail-section">
                <Header {...header_props} />
                <Row>
                    <Col md={{size: 11, offset: 1}}>
                        {this.state.data.option_type === 'reselection' && <ReselectionHeader {...header_props} />}
                        {this.renderInner()}
                    </Col>
                </Row>
            </div>
        );
    }
}
