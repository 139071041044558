// Taken from: https://blog.rescale.com/addressing-valuelink-deprecation-in-react-15/

import { setIn } from 'immutable-setter';
import {parseNumber} from "./examination/examination_resolution/common";


function createHandler(component, key, path, transformer) {
    return e => {
        const el = e.target;
        let value1 = el.type === 'checkbox' ? el.checked : el.value;
        const value = transformer !== undefined ? transformer(value1) : value1;

        component.setState({
            [key]: path ? setIn(component.state[key], path, value) : value,
        }, function() {
            if (typeof component.onLinkStateChange !== "undefined") {
                component.onLinkStateChange();
            }
        });
    };
}

export default function linkState(component, key, path, transformer) {
    if (path) {
        return createHandler(component, key, path, transformer);
    }

    const cache = component.__linkStateHandlers || (component.__linkStateHandlers = {});

    return cache[key] || (cache[key] = createHandler(component, key));
};
