import React from 'react';
import Select2 from '../forms/select2';
import {FormGroup, Label, Input} from 'reactstrap';
import { setIn } from 'immutable-setter';


export class PlanItemQuantity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: _.get(props, 'serial_numbers.length', 1),
            serial_numbers: props.serial_numbers || [""]
        };
        this.changeQuantity = this.changeQuantity.bind(this)
    }

    changeQuantity(e) {
        let quantity = e.target.value;
        let serial_numbers = _.times(quantity, (i) => _.get(this.state.serial_numbers, i, ""));

        this.setState({quantity: e.target.value, serial_numbers: serial_numbers});
    }

    changeSerialNumber(index, e) {
        this.setState({serial_numbers: setIn(this.state.serial_numbers, [index], e.target.value)});
    }

    render() {

        var quantities = _.times(10, (i) => [i+1, i+1]);

        return (
            <div>
                <FormGroup className="row">
                    <Label className="col-md-3 col-form-label" for="quantity">Quantity</Label>
                    <div className="col-md-6">
                        <Select2 onSelect={this.changeQuantity} name="quantity" data={quantities} value={this.state.quantity}/>
                    </div>
                </FormGroup>
                {_.times(this.state.quantity, (i) => (
                    <FormGroup className="row" key={`serial_number_${i}_form_group`}>
                        <Label className="col-md-3 col-form-label" for={`serial_number_${i}`}>Serial Number {`${i+1}`}</Label>
                        <div className="col-md-9">
                            <Input type="text" name={`serial_number_${i}`} id={`serial_number_${i}`}
                                   placeholder={`Serial Number for item ${i+1}`} value={this.state.serial_numbers[i]}
                                   onChange={this.changeSerialNumber.bind(this, i)}/>
                        </div>
                    </FormGroup>
                ))}
            </div>
        )
    }
}
