import React from 'react';
import PropTypes from 'prop-types';
import DamageCodeSelector from './damage_code_selector';
import { Card, CardBody, Row, Col } from 'reactstrap';
import DateInput from '../forms/date_input';
import FieldInput from '../forms/field_input';
import SelectInput from '../forms/select_input';
import CheckBoxInput from '../forms/checkbox_input';
import DamageLocationFormset from './damage_location_formset';
import linkState from '../link_state';
import { setIn, getIn } from 'immutable-setter';


export default class ClaimDamageDetails extends React.Component {
    constructor(props) {
        super(props);
        this.handleAddAnother = this.handleAddAnother.bind(this);

         this.state = {
             damage: this.props.damage
        };
    }

    handleAddAnother(e) {
        e.preventDefault();
        this.setState({count: this.state.count + 1});
    }

    handleLocationsChange(locations) {
        let key = this.getDetailsKey();

        this.setState(
            {'damage': setIn(this.state.damage, [key, 'locations'], locations)},
            this.triggerOnChange
        );
    }

    handleDamageCodeChange(code) {
        let key = this.getCodeKey();

        this.setState(
            {'damage': setIn(this.state.damage, [key], code)},
            this.triggerOnChange
        );
    }

    triggerOnChange() {
        const { onChange } = this.props;

        if (onChange) {
            onChange(this.state.damage);
        }
    }

    onLinkStateChange() {
        this.triggerOnChange();
    }

    getError(paths) {
        if(this.props.errors !== undefined) {
            return getIn(this.props.errors, paths)
        }

        return null;
    }

    getDetailsKey() {
        return this.props.showActual ? 'detail_actual' : 'detail_reported';
    }

    getCodeKey() {
        return this.props.showActual ? 'code_actual' : 'code_reported';
    }

    getDetails() {
        let key = this.getDetailsKey();
        return this.state.damage[key];
    }

    getCode() {
        let key = this.getCodeKey();
        return this.state.damage[key];
    }

    renderCodeSelector() {
        let code_key = this.getCodeKey();
        let code = this.getCode();

        return (
            <DamageCodeSelector
                code_tree={this.props.damage_code_tree}
                onChange={this.handleDamageCodeChange.bind(this)}
                value={code}
                error={this.getError([code_key])} />
        );
    }

    renderMainForm() {
        let key = this.getDetailsKey();
        let damage = this.getDetails();

        return (
            <Card color="light" className="mb-3">
                <CardBody>
                    <Row>
                        <Col sm="6">
                            <FieldInput
                                label="Cause of Damage"
                                onChange={linkState(this, 'damage', [key, 'cause'])}
                                value={damage.cause}
                                error={this.getError([key, 'cause'])} />
                        </Col>
                        <Col sm="6">
                            <SelectInput
                                label="Primary Cause of Damage"
                                onChange={linkState(this, 'damage', [key, 'cause_type_id'])}
                                value={damage.cause_type_id}
                                error={this.getError([key, 'cause_type_id'])}
                                choices={this.props.cause_types} />
                        </Col>
                        <Col sm="6">
                            <DateInput
                                label="Date of Damage"
                                onChange={linkState(this, 'damage', [key, 'damage_date'])}
                                value={damage.damage_date}
                                disabled={damage.damage_date_unknown}
                                error={this.getError([key, 'damage_date'])}
                                options={{maxDate: new Date()}} />
                            <CheckBoxInput
                                label="Date unknown"
                                onChange={linkState(this, 'damage', [key, 'damage_date_unknown'])}
                                checked={damage.damage_date_unknown}
                                error={this.getError([key, 'damage_date_unknown'])} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    renderLocationFormset() {
        let key = this.getDetailsKey();
        let damage = this.getDetails();

        return (
            <DamageLocationFormset
                locations={damage.locations}
                onChange={this.handleLocationsChange.bind(this)}
                errors={this.getError([key, 'locations'])}
                damage_location_types={this.props.damage_location_types}
            />
        );
    }

    render() {
        return (
            <div>
                {this.renderCodeSelector()}
                {this.renderMainForm()}
                {this.renderLocationFormset()}
            </div>
        );
    }
}

ClaimDamageDetails.propTypes = {
    showActual: PropTypes.bool,
};

ClaimDamageDetails.defaultProps = {
    showActual: false,
};
