import React from 'react';
import { Row, Col } from 'reactstrap';
import CheckBoxInput from '../forms/checkbox_input';
import { setIn } from 'immutable-setter';
import ClaimDamageDetails from './claim_damage_details';
const moment = require('moment');


export default class ClaimDamageDetailsConfirm extends ClaimDamageDetails {
    constructor(props) {
        super(props);
    }

    toggleCodeDifferent(e) {
        if(e.target.checked && !this.state.damage.code_actual) {
            this.setState(
                {'damage': setIn(this.state.damage, ["code_actual"], this.state.damage.code_reported)},
                function() {
                    this.setState(
                        {'damage': setIn(this.state.damage, ["code_actual_different"], true)}
                    );
                }
            );
        } else {
            this.setState(
                {'damage': setIn(this.state.damage, ["code_actual_different"], e.target.checked)}
            );
        }
    }

    toggleDetailDifferent(e) {
        if(e.target.checked && !this.state.damage.detail_actual) {
            this.setState(
                {'damage': setIn(this.state.damage, ["detail_actual"], this.state.damage.detail_reported)},
                function() {
                    this.setState(
                        {'damage': setIn(this.state.damage, ["detail_actual_different"], true)}
                    );
                }
            );
        } else {
            this.setState(
                {'damage': setIn(this.state.damage, ["detail_actual_different"], e.target.checked)}
            );
        }
    }

    renderCurrentCodes() {
        let reported_codes = this.state.damage.code_reported;
        var code_names = [];
        var code_tree = this.props.damage_code_tree;

        for(let x in reported_codes) {
            let code_id = reported_codes[x];
            let found = false;

            for(let i in code_tree) {
                if(code_tree[i].id === code_id) {
                    code_names.push(code_tree[i].name);
                    code_tree = code_tree[i].children;
                    found = true;
                    break;
                }
            }

            if(!found) {
                code_names.push("\"" + code_id + "\"");
            }
        }

        return <dd className="damage-code-list">
            {code_names.map((result, index) =>
                <span key={index}>{result}</span>
            )}
        </dd>;
    }

    getDamageLocationType(location_type_id) {
        for(var index in this.props.damage_location_types) {
            var damage_location_type = this.props.damage_location_types[index];
            if(damage_location_type[0] === location_type_id) {
                return damage_location_type[1];
            }
        }

        return <span>&mdash;</span>;
    }

    render() {
        let reported_details = this.state.damage.detail_reported;
        let cause_type = reported_details.cause_type_id;

        for(let x in this.props.cause_types) {
            if(this.props.cause_types[x][0] === cause_type) {
                cause_type = this.props.cause_types[x][1];
                break;
            }
        }

        return (
            <div>
                <CheckBoxInput
                    label="Actual damage different than reported"
                    light={true}
                    onChange={this.toggleCodeDifferent.bind(this)}
                    checked={this.state.damage.code_actual_different} />

                {!this.state.damage.code_actual_different ? (
                    <div>
                        <dt>Actual Damage Code(s)</dt>
                        {this.renderCurrentCodes()}
                    </div>
                ) : (
                    this.renderCodeSelector()
                )}

                <hr/>

                <CheckBoxInput
                    label="Damage details different than reported"
                    light={true}
                    onChange={this.toggleDetailDifferent.bind(this)}
                    checked={this.state.damage.detail_actual_different} />

                {!this.state.damage.detail_actual_different ? (
                    <div>
                        <Row>
                            <Col lg={2} md={3} sm={4} xs={6}>
                                <dt>Cause of Damage</dt>
                                <dd>{reported_details.cause}</dd>
                            </Col>
                            <Col lg={2} md={3} sm={4} xs={6}>
                                <dt>Type of Cause</dt>
                                <dd>{cause_type}</dd>
                            </Col>
                            <Col lg={2} md={3} sm={4} xs={6}>
                                <dt>Date of Damage</dt>
                                <dd>
                                    {reported_details.damage_date_unknown || !reported_details.damage_date ? (
                                        <i>unknown</i>
                                    ) : (
                                        moment(reported_details.damage_date).format('MM/DD/YYYY')
                                    )}
                                </dd>
                            </Col>
                        </Row>

                        {reported_details.locations.map((location, index) =>
                            <Row key={index}>
                                <Col lg={2} md={3} sm={4} xs={6}>
                                    <dt>Damage Location</dt>
                                    <dd>{this.getDamageLocationType(location.location_id)}</dd>
                                </Col>
                                <Col lg={2} md={3} sm={4} xs={6}>
                                    <dt>Damage Size</dt>
                                    <dd>{location.size}</dd>
                                </Col>
                                <Col lg={2} md={3} sm={4} xs={6}>
                                    <dt>Damage Quantity</dt>
                                    <dd>{location.quantity}</dd>
                                </Col>
                            </Row>
                        )}
                    </div>
                ) : (
                    <div>
                        {this.renderMainForm()}
                        {this.renderLocationFormset()}
                    </div>
                )}
            </div>
        );
    }
}

