import React from 'react';

import {Col, Row} from 'reactstrap';
import CheckBoxInput from "../../forms/checkbox_input";
import linkState from '../../link_state';
import FieldInput from "../../forms/field_input";
import DateInput from "../../forms/date_input";

import {setIn} from "immutable-setter";

import {
    ExaminationConfirmationWrapper,
    has_quote_response,
    LabeledTotal,
    parseNumber,
    QuoteResponseNote,
    validate_resolution
} from './common';
import {currencyLinkState} from "../../util";


export default class ReselectionResolution extends React.Component {
    constructor(props) {
        super(props);

        let initial_data = this.calculateTotals(props.data);
        if (props.is_rtg_claim) {
            initial_data.invoice_date = "";
            initial_data.invoice_number = "";
        }
        this.state = {
            data: initial_data
        };

        // push the calculated totals and any other changes up to the parent object
        this.props.onChange(initial_data);
    }

    calculateTotals(data) {
        // given initial data from props, or updated data after a state change, calculate totals and ensure
        // all resolution claims have the necessary extra_data.  Returns the update data object.

        // helper func to get an examination_claim object from props by id
        let examination_claims = this.props.examination.examination_claims;
        let find_examination_claim = (id) => _.filter(examination_claims, ec => ec.id === id)[0];

        data = _.cloneDeep(data);  // prevent modifying the supplied data object, which could be from state or props

        data.original_authorized_delivery_amount = 0;
        data.original_authorized_amount = 0;
        data.original_authorized_retail_amount = 0;
        data.original_authorized_item_amount = 0;

        data.authorized_delivery_amount = 0;
        data.authorized_amount = 0;
        data.authorized_retail_amount = 0;
        data.authorized_item_amount = 0;

        // loop through the claims, calculate the authorized amount, and populate the
        // override_reason, and make sure that there is an extra_data for the line item info
        for (let index in data.resolution_claims) {
            let resolution_claim = data.resolution_claims[index];
            let examination_claim = find_examination_claim(resolution_claim.examination_claim);
            let option = examination_claim.options[this.props.data.option_type];

            let amount = parseFloat(option.amount);
            let retail_price = parseFloat(option.extra_data.retail_price);
            let delivery_price = parseFloat(option.extra_data.delivery_price);
            let item_price = parseFloat(option.extra_data.item_price);

            data.original_authorized_amount += amount;
            data.original_authorized_retail_amount += retail_price;
            data.original_authorized_delivery_amount += delivery_price;
            data.original_authorized_item_amount += item_price;

            if (!data.override) {
                resolution_claim.extra_data = {
                    authorized_retail_amount: retail_price,
                    authorized_item_amount: item_price,
                    authorized_delivery_amount: delivery_price
                };

                resolution_claim.authorized_amount = amount;  // this cannot be changed through the UI

                data.authorized_amount += amount;
                data.authorized_retail_amount += retail_price;
                data.authorized_delivery_amount += delivery_price;
                data.authorized_item_amount += item_price;

                data.override_reason = null;

            } else {
                if (!data.override_reason) {
                    data.override_reason = "";
                }

                // no extra data?  override was just enabled, set some defaults
                if (_.isEmpty(resolution_claim.extra_data)) {
                    resolution_claim.extra_data = {
                        authorized_item_amount: item_price,
                        authorized_retail_amount: retail_price,
                        authorized_delivery_amount: delivery_price,
                    };
                }

                // resolution_claim.authorized_amount = parseNumber(resolution_claim.extra_data.authorized_item_amount) + parseNumber(resolution_claim.extra_data.authorized_delivery_amount);
                // Mihir change = removed authorized delivery amount from authorized amount.
                resolution_claim.authorized_amount = parseNumber(resolution_claim.extra_data.authorized_item_amount);

                data.authorized_delivery_amount += parseNumber(resolution_claim.extra_data.authorized_delivery_amount);
                data.authorized_amount += parseNumber(resolution_claim.authorized_amount);
                data.authorized_retail_amount += parseNumber(resolution_claim.extra_data.authorized_retail_amount);
                data.authorized_item_amount = parseNumber(resolution_claim.extra_data.authorized_item_amount);
            }
        }

        data.can_submit = this.canSubmit(data);

        return data;
    }

    canSubmit(data) {
        if (!validate_resolution(data)) {
            return false;
        }

        if (isNaN(parseFloat(data.authorized_delivery_amount)) || parseNumber(data.authorized_item_amount) <= 0 || parseNumber(data.authorized_retail_amount) <= 0) {
            return false;
        }

        if (data.override) {
            for (let i in data.resolution_claims) {
                let claim = data.resolution_claims[i];
                let retail_amount = parseNumber(claim.extra_data.authorized_retail_amount);
                let item_amount = parseNumber(claim.extra_data.authorized_item_amount);
                let delivery_amount = parseFloat(claim.extra_data.authorized_delivery_amount);

                if (retail_amount <= 0 || item_amount <= 0 || isNaN(delivery_amount)) {
                    return false;
                }
            }
        }

        // in case of rooms to go,
        if (this.props.is_rtg_claim) {
            // require invoice_number
            if (!data.invoice_number) {
                return false;
            }
            // require invoice_date
            if (!data.invoice_date) {
                return false;
            }
        }

        return true;
    }

    handleOverrideChange() {
        // Override checkbox requires a custom change handler.
        // With override false, the extra_data objects are empty.  When the state changes to true, the renderOverride
        // method will be called.  When link_state is used, this call happens before onLinkStateChange is called,
        // which creates the default extra_data objects.  With no defaults, the value props of the fields in
        // the override section get set to null, which causes a controlled/uncontrolled component warning.
        this.setState((prevState) => {
            prevState = setIn(prevState, ["data", "override"], !prevState.data.override);
            let data = this.calculateTotals(prevState.data);
            return {data: data};
        }, () => {
            this.props.onChange(this.state.data);
        });
    }

    onLinkStateChange() {
        // triggered after state is updated from a form field change
        let updated_data = this.calculateTotals(this.state.data);
        this.setState({
            data: updated_data,
        });
        this.props.onChange(updated_data);
    }

    renderOverride() {
        let data = this.state.data;

        return (
            <>
                <LabeledTotal label="Override Total Authorized Amount" total={data.authorized_amount + data.authorized_delivery_amount}/>
                <LabeledTotal label="Override Authorized Item Amount" total={data.authorized_item_amount}/>
                <LabeledTotal label="Override Authorized Delivery Amount" total={data.authorized_delivery_amount}/>
                <LabeledTotal label="Override Authorized Retail Amount" total={data.authorized_retail_amount}/>

                <br/>

                <Row>
                    <Col sm={6}>
                        <FieldInput
                            label="Override Reason"
                            value={data.override_reason}
                            onChange={linkState(this, "data", ["override_reason"])}/>
                    </Col>
                </Row>

                <br/>

                {this.state.data.resolution_claims.map((resolution_claim, index) => {
                    let ec_id = resolution_claim.examination_claim;
                    let examination_claim = _.filter(this.props.examination.examination_claims, ec => ec.id === ec_id)[0];
                    let extra_data_path = ["resolution_claims", parseInt(index), "extra_data"];
                    let extra_data = data.resolution_claims[index].extra_data;

                    return <Row key={`override${ec_id}`}>
                        <Col md={3}>
                            <h6 className="mb-1 pt-1">Claim {examination_claim.claim.name}</h6>
                            <div className="claim-item">{examination_claim.claim.item.description}</div>
                        </Col>
                        <Col md={3}>
                            <FieldInput
                                label="Authorized Item Amount"
                                value={extra_data.authorized_item_amount}
                                onChange={currencyLinkState(this, "data", [...extra_data_path, "authorized_item_amount"])}
                                type="number"/>
                        </Col>
                        <Col md={3}>
                            <FieldInput
                                label="Authorized Retail Amount"
                                value={extra_data.authorized_retail_amount}
                                onChange={currencyLinkState(this, "data", [...extra_data_path, "authorized_retail_amount"])}
                                type="number"/>
                        </Col>
                        <Col md={3}>
                            <FieldInput
                                label="Authorized Delivery Amount"
                                value={extra_data.authorized_delivery_amount}
                                onChange={currencyLinkState(this, "data", [...extra_data_path, "authorized_delivery_amount"])}
                                type="number"/>
                        </Col>
                    </Row>
                })}
            </>
        );
    }

    render() {
        let data = this.state.data;
        let quote_request = this.props.examination.quote_request;

        return (
            <ExaminationConfirmationWrapper title="Reselection" total={data.original_authorized_amount}>
                <LabeledTotal label="Original Total Authorized Amount" total={data.original_authorized_amount}/>
                <LabeledTotal label="Original Authorized Item Amount" total={data.original_authorized_item_amount}/>
                <LabeledTotal label="Original Authorized Delivery Amount"
                              total={data.original_authorized_delivery_amount}/>
                <LabeledTotal label="Authorized Retail Amount" total={data.original_authorized_retail_amount}/>

                {has_quote_response(quote_request, data.resolution_claims) && (
                    <QuoteResponseNote response_notes={quote_request.response_notes}/>
                )}

                <br/>

                <CheckBoxInput
                    label="Override Authorized Amount"
                    onChange={this.handleOverrideChange.bind(this)}
                    checked={data.override}/>

                {data.override && this.renderOverride()}

                <FieldInput type="textarea" label="Notes" value={data.notes}
                            onChange={linkState(this, "data", ["notes"])}/>
                {this.props.is_rtg_claim && (
                    <Row>
                        <Col sm="4">
                            <FieldInput
                                value={data.invoice_number}
                                label="Invoice Number"
                                onChange={linkState(this, "data", ["invoice_number"])}
                            />
                        </Col>
                        <Col sm="4">
                            <DateInput
                                value={data.invoice_date}
                                label="Invoice Date"
                                onChange={linkState(this, 'data', ['invoice_date'])}
                                options={{maxDate: new Date()}}
                            />
                        </Col>
                    </Row>
                )}
            </ExaminationConfirmationWrapper>
        )
    }
}
