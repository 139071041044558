import React from "react";
import classNames from 'classnames';

const DamageCodeList = ({damage_names, small=true}) => {
    return (
        <div className={classNames("damage-code-list", {"small": small})}>
            {damage_names.map((name, index) => <span key={`damages_${index}`}>{name}</span>)}
        </div>
    )
};

export default DamageCodeList;
