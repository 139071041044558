import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";


export class CancelResolutionButton extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.sendCancel = this.sendCancel.bind(this);

        this.state = {
            modal_opened: false,
            cancelling: false,
        }
    }

    toggleModal() {
        if (this.state.cancelling) {
            return;
        }

        this.setState({modal_opened: !this.state.modal_opened});
    }

    sendCancel() {
        this.setState({cancelling: true});

        axios.post(`/api/resolutions/${this.props.resolution_id}/cancel/`).then((response) => {
            this.setState({ modal_opened: false, cancelling: false });
            document.location.reload();
        });
    }

    renderModal() {
        if (this.props.can_cancel) {
            return(
                <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal}
                       backdrop={this.props.cancelling ? "static" : true}>
                    <div className="modal-header pb-0">
                        <div className="modal-title">
                            <h3 className="modal-title">Cancel {this.props.title}</h3>
                        </div>
                        <button type="button" className="close" aria-label="Close" onClick={this.toggleModal}
                                disabled={this.state.cancelling}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <hr className="mt-0"/>
                        <p>Are you sure you want to cancel this {this.props.title}?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.cancelling}
                                className="btn-wide">
                            No, Don't Cancel
                        </Button>
                        <Button color="primary" onClick={this.sendCancel} disabled={this.state.cancelling}
                                className="btn-wide">
                            {this.state.cancelling ? (
                                <span><Fa icon="spinner" spin/> cancelling...</span>
                            ) : (
                                "Yes, Cancel"
                            )}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        } else {
            return (
                <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal}
                       backdrop={this.props.cancelling ? "static" : true}>
                    <div className="modal-header pb-0">
                        <div className="modal-title">
                            <h3 className="modal-title">Unable to Cancel {this.props.title}</h3>
                        </div>
                        <button type="button" className="close" aria-label="Close" onClick={this.toggleModal}
                                disabled={this.state.cancelling}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalFooter>
                        <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.cancelling}
                                className="btn-wide">
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            );
        }
    }

    render() {

        return (
            <>
                <Button onClick={this.toggleModal} className="btn btn-xs btn-outline-danger">
                    Cancel {this.props.title}
                </Button>

                {this.renderModal()}
            </>
        )
    }
}

CancelResolutionButton.propTypes = {
    resolution_id: PropTypes.number.isRequired,
};
