import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { uniqueId} from 'lodash';
import classNames from 'classnames';
import Fa from "alpaca.js/dist/util/fa";
const moment = require('moment');


export default class DateInput extends React.Component {
    constructor(props) {
        super(props);

        const id = uniqueId("field-");
        this.state = {id: id};
    }

    componentDidMount() {
        const {onChange} = this.props;
        let s = $(this.refs['input']);

        let options = this.props.options;
        options.format = 'MM/DD/YYYY';
        options.useCurrent = false;

        if (s.val()) {
            options.date = moment(s.val(), options.format);
        }

        s.datetimepicker(options);

        s.on('blur', function (e) {
            $(this).data('datetimepicker').hide();
            if (onChange) {
                onChange(e);
            }
        }).on('change.datetimepicker', function(e) {
            if (onChange) {
                onChange(e);
            }
        }).on('focus', function(e) {
            if (s.val().length === 0) {
                s.val(moment().format('MM/DD/YYYY'));
            }
        });
    }

    render() {
        let value = this.props.value;
        if(value !== undefined) {
            value = moment(value, ['MM/DD/YYYY', moment.ISO_8601, moment.RFC_2822]).format('MM/DD/YYYY');
        }

        return (
            <FormGroup className={classNames({'is-invalid': this.props.error})}>
                <Label>{this.props.label}</Label>
                <InputGroup>
                    <div className="input-group-prepend">
                        <span className="input-group-text"><Fa icon="calendar-alt" /></span>
                    </div>
                    <input
                        defaultValue={value}
                        disabled={this.props.disabled}
                        data-toggle="datetimepicker"
                        data-target={"#" + this.state.id}
                        id={this.state.id}
                        ref="input"
                        className={classNames("form-control", {'is-invalid': this.props.error})}
                    />
                </InputGroup>
                {this.props.error &&
                    <span className="invalid-feedback">{this.props.error}</span>
                }
            </FormGroup>
        );
    }
}

DateInput.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.object
};

DateInput.defaultProps = {
    label: '',
    type: 'text',
    options: {},
};
