import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Collapse} from "reactstrap";
import classNames from 'classnames';


export default class CollapsibleCard extends Component {
    constructor(props) {
        super(props);
        this.toggleExpanded = this.toggleExpanded.bind(this);

        this.state = {
            expanded: props.expanded
        };
    }

    toggleExpanded(e) {
        e.preventDefault();
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        return (
            <Card className="card-bordered card-claim-detail mb-4">
                <CardHeader>
                    <div className="d-flex justify-content-between">
                        <h4>{this.props.header}</h4>
                        <a className={classNames("card-collapse-btn", {collapsed: !this.state.expanded})} href="#" onClick={this.toggleExpanded} />
                    </div>
                </CardHeader>
                <Collapse isOpen={this.state.expanded}>
                    <CardBody>
                        {this.props.children}
                    </CardBody>
                </Collapse>
            </Card>
        );
    }
}

CollapsibleCard.propTypes = {
    header: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
    children: PropTypes.node
};

CollapsibleCard.defaultProps = {
    expanded: true,
};
