import React, {Fragment} from 'react';
import {ExaminationOption} from './examination_option';
import { Row, Col } from 'reactstrap';
import {currency, yesno, lineBreaks} from "../../util";
import QuoteDetailModal from './quote_detail_modal';


function currencyOrDash(value) {
    return (value ? currency(value) : <span className="text-muted">&mdash;</span>)
}

export const ExaminationReselectionOption = (props) => {

    let claim = props.examination_claim.claim;
    let plan = claim.plan;
    let quote_request = props.examination.quote_request;
    let quote_request_claim = null;
    if (quote_request) {
        quote_request_claim = _.filter(quote_request.quote_request_claims, ((x) => x.examination_claim_id === props.examination_claim.id))[0];
    }

    let retail_price = props.examination_claim.claim.item.retail_price;
    if (quote_request_claim && quote_request_claim.item_retail_price > retail_price) {
        retail_price = quote_request_claim.item_retail_price;
    }

    return (
        <ExaminationOption {...props}>
            <div>
                <small>Full Retail: {currency(retail_price)}<br/></small>
                {quote_request &&
                    <QuoteDetailModal quote_status={quote_request.status} ticket_id={props.examination.ticket} retailer_id={plan.retailer.id}>
                        <hr/>
                        <Row>
                            {plan.retailer !== null &&
                            <Col xs={6}>
                                <dt>Retailer</dt>
                                <dd>
                                    <a href={`/tickets/${props.examination.ticket}/retailers/${plan.retailer.id}/`}
                                       target="_blank">
                                        {plan.retailer.name}
                                    </a>
                                </dd>
                            </Col>
                            }

                            <Col sm={6}>
                                <dt>Item Available</dt>
                                <dd>{yesno(quote_request_claim.item_available)}</dd>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <dt>Retail Cost (claim)</dt>
                                <dd>{currencyOrDash(claim.item.retail_price)}</dd>
                            </Col>
                            <Col sm={6}>
                                <dt>Retail Cost (quote)</dt>
                                <dd>{currencyOrDash(quote_request_claim.item_retail_price)}</dd>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <dt>Wholesale Cost (claim)</dt>
                                <dd>{currencyOrDash(claim.item.wholesale_price)}</dd>
                            </Col>
                            <Col sm={6}>
                                <dt>Wholesale Cost (quote)</dt>
                                <dd>{currencyOrDash(quote_request_claim.item_wholesale_price)}</dd>
                            </Col>
                        </Row>

                        {quote_request.delivery_optional &&
                            <Row>
                                <Col sm={6}>
                                    <dt>Delivery Agreement</dt>
                                    <dd>Optional</dd>
                                </Col>
                                <Col sm={6}>
                                    <dt>Customer Paid Delivery</dt>
                                    <dd>
                                        {yesno(quote_request_claim.customer_paid_delivery)}
                                        {quote_request_claim.customer_paid_delivery &&
                                            <>
                                                : {currencyOrDash(quote_request_claim.item_delivery_price)}
                                                {quote_request.max_delivery_amount && <> (Max: {currency(quote_request.max_delivery_amount)})</>}
                                            </>
                                        }
                                    </dd>
                                </Col>

                            </Row>
                        }

                        {quote_request.fixed_delivery_amount &&
                            <Row>
                                <Col sm={6}>
                                    <dt>Delivery Agreement</dt>
                                    <dd>Fixed</dd>
                                </Col>
                                <Col sm={6}>
                                    <dt>Delivery Amount</dt>
                                    <dd>
                                        {currencyOrDash(quote_request_claim.fixed_delivery_amount)}
                                    </dd>
                                </Col>

                            </Row>
                        }
                        <hr />

                        {quote_request.response_notes && <Fragment>
                            <h5>Notes from Retailer</h5>
                            <p>{lineBreaks(quote_request.response_notes)}</p>
                        </Fragment>}

                    </QuoteDetailModal>
                }

            </div>
        </ExaminationOption>
    );
};

ExaminationReselectionOption.propTypes = ExaminationOption.propTypes;
