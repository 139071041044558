import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select2 from "../forms/select2";
import axios from "axios/index";


export class TicketReassignSelected extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.doAssignment = this.doAssignment.bind(this);
        this.changeSelectedUser = this.changeSelectedUser.bind(this);

        this.state = {
            modal_opened: false,
            loading: false,
            selected_user: null,
        };
    }

    getTicketIds() {
        let ticket_ids = [];
        $('input[name=change_assignee]:checked').each(function(){
            ticket_ids.push(parseInt($(this).val()));
        });
        return ticket_ids;
    }

    toggleModal() {
        if(!this.state.modal_opened && this.getTicketIds().length === 0) {
            return;
        }

        if(this.state.loading) {
            return;
        }

        this.setState({ modal_opened: !this.state.modal_opened });
    }

    changeSelectedUser(e) {
        this.setState({selected_user: e.target.value});
    }

    doAssignment() {
        this.setState({loading: true});

        let params = {
            user: this.state.selected_user,
            tickets: this.getTicketIds(),
        };

        axios.post('/api/tickets/bulk_reassign/', params).then((response) => {
            this.setState({
                modal_opened: false,
                loading: false,
            }, function(){
                document.location.reload();
            });
        });
    }

    render() {
        let ticket_ids = this.getTicketIds();

        let user_ajax = {
            url: '/api/users/',
            dataType: 'json',
            processResults: function (data) {
                return {
                    results: data.results.map(function(user) { return {id: user.id, text: user.name}; }),
                    pagination: {
                        more: data.next !== null
                    }
                };
            },
            data: function (params) {
                return {
                    name: params.term,
                    page: params.page || 1
                };
            }
        };

        return (
            <div>
                <Button outline color="primary" size="xs" className="btn-wide" onClick={this.toggleModal}>Reassign Selected</Button>
                <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal} backdrop={this.state.loading ? "static" : true}>
                    <ModalHeader toggle={this.toggleModal}>Change Assignee for {ticket_ids.length} Ticket{ticket_ids.length !== 1 && "s"}</ModalHeader>
                    <ModalBody>
                        <Select2 ajax={user_ajax} placeholder="Select assignee..." onSelect={this.changeSelectedUser} value={this.state.selected_user}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.doAssignment} disabled={this.state.loading || this.state.selected_user === null}>Assign</Button>{' '}
                        <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.loading}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
