import React, {useState} from 'react';
import LoadingButton from "alpaca.js/dist/util/loadingButton";
import fileDownload from "js-file-download";
import axios from "axios";

const InvoiceDownloadButton = () => {
    let [loading, setLoading] = useState(false);

    let onClick = () => {
        setLoading(true);
        let filterData = $('#invoice_filter_form').serialize();

        axios.get("/api/invoices/?format=csv&" + filterData, {responseType: 'arraybuffer'}).then(function (response) {
            let filename = _.split(response.headers['content-disposition'], '=')[1];
            filename = filename ? filename : 'invoices.csv';
            let mime = response.headers['content-type'];
            fileDownload(response.data, filename, mime);
            setLoading(false);
        });
    };

    return <LoadingButton color="primary" outline size="xs" onClick={onClick} loading={loading} icon="download">
        Download All
    </LoadingButton>;
};

export default InvoiceDownloadButton;
