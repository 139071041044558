import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import {ExaminationModalOption} from "./examination_modal_option";



export class ManualResolutionButton extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.submitDecision = this.submitDecision.bind(this);

        let examination_claims = [];

        for(let index in this.props.examination.examination_claims) {
            let examination_claim = this.props.examination.examination_claims[index];
            if(examination_claim.selected === null) {
                examination_claims.push(examination_claim)
            }
        }

        this.state = {
            submitting: false,
            modal_opened: false,
            data: {
                override: false,
                override_reason: "",
                notes: "",
                option_type: "manual",
                authorized_amount: 0,
                manual_resolution_reason: null,
                resolution_claims: _.map(examination_claims, ec => {
                    return {examination_claim: ec.id};
                }),
            },
        };

    }

    toggleModal(e) {
        if(e) {
            e.preventDefault();
        }

        if(this.state.submitting) {
            return;
        }

        this.setState({ modal_opened: !this.state.modal_opened });
    }

    submitDecision() {
        this.setState({submitting: true});

        axios.post(`/api/examination/${this.props.examination.id}/submit_decision/`, [this.state.data]).then((response) => {
            document.location.reload();
        });
    }

    onModalOptionChange(data) {
        this.setState({data: data});
    }

    renderModal() {
        return (
            <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal} backdrop={this.state.submitting ? "static" : true}>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">Manual Resolution</h3>
                        <h5>{this.props.examination.object_title}</h5>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.toggleModal} disabled={this.state.submitting}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <hr/>
                    <form>
                        <ExaminationModalOption
                            data={this.state.data}
                            onChange={this.onModalOptionChange.bind(this)}
                            examination={this.props.examination}
                            manual_reasons={this.props.manual_reasons} />
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.submitting} className="btn-wide">Cancel</Button>
                    <Button color="primary" onClick={this.submitDecision} disabled={this.state.submitting || this.state.data.manual_resolution_reason === null}
                            className="btn-wide">
                        {this.state.submitting ? (
                            <span><Fa icon="spinner" spin/> Confirming</span>
                        ) : (
                            "Confirm"
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        if(this.state.data.resolution_claims.length === 0) {
            return null;
        }

        return (
            <>
                <Button size="xs" color="primary" outline onClick={this.toggleModal}>Submit Manual Resolution</Button>&#160;&#160;
                {this.renderModal()}
            </>
        );
    }
}

ManualResolutionButton.propTypes = {
    examination: PropTypes.object,
};
