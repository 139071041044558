import React, {Fragment} from 'react';

import {Button, Col, Collapse, Row, Table} from 'reactstrap';
// import CheckBoxInput from "../forms/checkbox_input";
// import linkState from '../link_state';
// import FieldInput from "../forms/field_input";
// import SelectInput from '../forms/select_input';
import {currency, isNone, lineBreaks, yesno} from '../../util';
// import {setIn} from "immutable-setter";
import PropTypes from 'prop-types';

export const OPTION_NAMES_MAP = {
    'repair': 'Repair',
    'replace_parts': 'Replace Parts',
    'reselection': 'Reselection',
    'settlement': 'Settlement',
    'refund': 'Refund',
    'manual': 'Manual'
};


export const NOTES_LABEL_MAP = {
    'repair': 'Additional Notes to Service Tech',
    'replace_parts': 'Additional Notes to Retailer',
    'manual': 'Notes',
    'reselection': 'Notes',
    'settlement': 'Notes',
};


export function parseNumber(value) {
    value = parseFloat(value);

    if(isNaN(value)) {
        value = 0;
    }

    return value;
}


export function validate_resolution(data) {
    // validates fields common to all resolution types

    // authorize_amount is always required
    if (parseNumber(data.authorized_amount) <= 0) {
        return false;
    }

    // if override is selected, then a reason must be set
    if (data.override) {
        if (_.trim(data.override_reason).length === 0) {
            return false;
        }
    }

    return true;
}


export function has_quote_response(quote_request, resolution_claims) {
    if (quote_request === null || quote_request.response_notes === null) {
        return false;
    }

    for (let id in resolution_claims) {
        let examination_claim_id = resolution_claims[id].examination_claim;
        let quote_request_claim = _.filter(quote_request.quote_request_claims, ((x) => x.examination_claim_id === examination_claim_id))[0];
        if (quote_request_claim !== undefined) {
            return true;
        }
    }
    return false;
}


export class QuoteResponseNote extends React.Component {
    constructor(props) {
        super(props);
        this.toggleNote = this.toggleNote.bind(this);
        this.state = {
            note_open: false,
        };
    }

    toggleNote(e) {
        e.preventDefault();
        this.setState({note_open: !this.state.note_open});
    }

    render() {
        return (
            <div className="mb-2">
                <a className="small" onClick={this.toggleNote}
                   href="#">{this.state.note_open ? 'Hide' : 'Show'} Notes from Retailer</a>

                <Collapse isOpen={this.state.note_open}>
                    <p className="small">{lineBreaks(this.props.response_notes)}</p>
                </Collapse>
            </div>
        );
    }
}


export const LabeledTotal = (props) => { return (
    <Row>
        <Col>
            <h5>{props.label}</h5>
        </Col>
        <Col>
            {currency(props.total)}
        </Col>
    </Row>
)};


export class ExaminationConfirmationWrapper extends React.Component {

    render() {

        return (
            <div className="mt-4 examination-detail-section">
                <div>
                    <h4 className="d-inline-block mr-4">{this.props.title}</h4>
                    {this.props.show_total &&
                        <Fragment>
                            {!isNone(this.props.total) ?
                                (currency(this.props.total)) :
                                (<small className="text-muted">unknown price</small>)
                            }
                        </Fragment>
                    }
                </div>
                <Row>
                    <Col md={{size: 11, offset: 1}}>
                        {this.props.children}
                    </Col>
                </Row>
            </div>
        );
    }
}


ExaminationConfirmationWrapper.propTypes = {
    show_total: PropTypes.bool,
    total: PropTypes.number,
    title: PropTypes.string,
};

ExaminationConfirmationWrapper.defaultProps = {
    show_total: true,
    title: 'Examination Confirmation Title',
};
