import React from 'react';
import classNames from 'classnames';
import { observer } from "mobx-react";

import { observablePageStore } from './store';


export const CallWidgetToggle = observer(class CallWidgetToggle extends React.Component {
    constructor(props) {
        super(props);
        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen(e) {
        observablePageStore.toggleCallWidgetOpen();
        e.preventDefault();
    }

    render() {
        return (
            <ul className="list-unstyled mb-0">
                <li>
                    <a href="#" className={classNames("jq_change_status", {deactivated: !observablePageStore.callWidgetOpen})} onClick={this.toggleOpen}>
                        <img src="/static/images/nav_call_status_active.svg" alt="Hide call widget"/>
                    </a>
                </li>
                <li>
                    <a href="#" className={classNames("jq_change_status", {deactivated: observablePageStore.callWidgetOpen})} onClick={this.toggleOpen}>
                        <img src="/static/images/nav_call_status_deactivated.svg" alt="Show call widget" />
                    </a>
                </li>
            </ul>
        );
    }
});
