import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Modal, ModalBody, ModalFooter, Alert, Row, Col } from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import linkState from '../link_state';
import Select2 from "../forms/select2";


export class FinalizeClaimsButton extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.sendFinalize = this.sendFinalize.bind(this);

        this.state = {
            modal_opened: false,
            finalizing: false,
            loading: false,
            claims: [],
            data: {}
        }
    }

    toggleModal() {
        if(this.state.finalizing) {
            return;
        }

        if(!this.state.modal_opened && this.state.claims.length === 0) {
            this.loadClaims();
        }

        this.setState({ modal_opened: !this.state.modal_opened });
    }

    sendFinalize() {
        this.setState({finalizing: true});

        axios.post(`/api/resolutions/${this.props.resolution_id}/finalize_claims/`, this.state.data).then((response) => {
            this.setState({ modal_opened: false, finalizing: false });
            document.location.reload();
        });
    }

    loadClaims() {
        this.setState({loading: true});

        axios.get(`/api/resolutions/${this.props.resolution_id}/`).then((response) => {
            let claims = response.data.claims;
            let data = {claims: {}};

            for(let index in claims) {
                data.claims[claims[index].id] = 'resolved';
            }

            this.setState({
                claims: claims,
                loading: false,
                data: data
            });
        });
    }

    renderClaims() {
        if(this.state.loading) {
            return <div className="text-muted">
                <Fa icon="spinner" spin/> loading...
            </div>
        }

        return (
            <div>
                {this.state.claims.map((claim) =>
                    <Row>
                        <Col sm={8}>
                            <strong className="mr-3">Claim {claim.name}</strong>
                            {claim.item &&
                                <span className="claim-item d-inline-flex mt-0">{claim.item.description}</span>
                            }
                        </Col>
                        <Col>
                            <Select2
                                data={[["resolved", "Resolved"], ["unresolved", "Unresolved"]]}
                                inimumResultsForSearch={100}
                                value={this.state.data.claims[claim.id]}
                                onSelect={linkState(this, "data", ["claims", claim.id])}/>
                        </Col>
                    </Row>
                )}
                    <textarea
                        placeholder="Notes"
                        className="form-control mt-4"
                        value={this.state.data.notes}
                        onChange={linkState(this, "data", ["notes"])} />

            </div>
        );
    }

    renderModal() {
        return (
            <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal} backdrop={this.state.finalizing ? "static" : true}>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">{this.props.title}</h3>
                        <p>Select whether or not each claim was resolved.</p>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.toggleModal} disabled={this.state.finalizing}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <hr className="mt-0"/>
                    {this.renderClaims()}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.finalizing} className="btn-wide">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.sendFinalize} disabled={this.state.finalizing || this.state.claims.length === 0} className="btn-wide">
                        {this.state.finalizing ? (
                            <span><Fa icon="spinner" spin/> Finalizing</span>
                        ) : (
                            "Finalize"
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {

        return (
            <>
                <Button onClick={this.toggleModal} className="btn btn-xs btn-outline-primary">
                    {this.props.title}
                </Button>

                {this.renderModal()}
            </>
        )
    }
}

FinalizeClaimsButton.propTypes = {
    resolution_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};

FinalizeClaimsButton.defaultProps = {
    title: "Finalize Claims"
};
