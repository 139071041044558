import React from 'react';
import PropTypes from 'prop-types';
import {withAPIData} from "alpaca.js/dist/api/withAPIData";

import CollapsibleCard from "../collapsible_card";
import {formatTime} from "../util";


const PreviousExaminationsPanelBase = (props) => {
    if(props.loading) {
        return null;
    }

    if(props.data.count === 0) {
        return null;
    }

    return (
        <CollapsibleCard header="Previous Examinations" expanded={props.expanded}>
            {_.map(props.data.results, (examination) => (
                <div>
                    <a href={`/examination/${examination.id}/`}>
                        Created {formatTime(examination.date_created)},
                        closed {formatTime(examination.date_updated)} by {examination.user_updated.name}
                        {examination.resolutions.length > 0 && <>
                            {" "} - {_.join(_.map(examination.resolutions, r => r.option_type_name), ", ")}
                        </>}
                    </a>
                </div>
            ))}
        </CollapsibleCard>
    );
};

const PreviousExaminationsPanel = withAPIData(PreviousExaminationsPanelBase, "/api/examination/?previous_to=${examination_id}");

PreviousExaminationsPanelBase.propTypes = {
    examination_id: PropTypes.number,
    expanded: PropTypes.bool,
};

PreviousExaminationsPanelBase.defaultProps = {
    expanded: true,
};


export default PreviousExaminationsPanel;
