import React from 'react';
import PropTypes from 'prop-types';
import Select2 from '../forms/select2';
import axios from "axios/index";


export class ClaimSubStatusDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);

        this.state = {
            loading: true,
            sub_status: this.props.sub_status,
            choices: []
        };
    }

    componentDidMount() {
        this.setState({loading: true});

        axios.get(`/api/claims/${this.props.claim}/sub_statuses/`).then((response) => {
            this.setState({
                loading: false,
                choices: response.data
            });
        });
    }

    onChange(e) {
        let value = e.target.value;

        if(value.length === 0) {
            value = null;
        }

        this.setState({loading: true, sub_status: value});

        let data = {sub_status: value};

        axios.patch(`/api/claims/${this.props.claim}/`, data).then((response) => {
            this.setState({
                loading: false,
            });
        });
    }

    render() {
        if(this.state.choices.length === 0) {
            return null;
        }

        return (
            <div className="d-inline-block">
                <strong>Sub-status: </strong>
                <div className="form-group d-inline-block text-left mb-0 select2-long-text" style={{width:275}}>
                    <Select2 onSelect={this.onChange} data={this.state.choices} disabled={this.state.loading}
                             emptyLabel="--------" value={this.state.sub_status} />
                </div>
            </div>
        );
    }
}

ClaimSubStatusDropdown.propTypes = {
    claim: PropTypes.number.isRequired,
    sub_status: PropTypes.string
};
