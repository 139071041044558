import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, ModalBody, ModalFooter} from 'reactstrap';


export class NoBillingModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal isOpen={this.props.showModal} toggle={this.props.cancelConfirmation}
                   backdrop={"static"}>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">Additional Information Needed</h3>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.props.cancelNoBilling}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <hr/>
                    <p>There is no Billing Address on file to be able to process the settlement.</p>
                    <p>Please add a billing address for the contact before proceeding.</p>
                    <a className="btn btn-sm btn-outline-warning"
                       href={`/contacts/${this.props.contactId}/update/`} target={"_blank"}>Edit Contact</a> (opens a new tab)
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={this.props.cancelNoBilling} className="btn-wide">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.props.proceedNoBilling} className="btn-wide">
                        Proceed
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

NoBillingModal.propTypes = {
    proceedNoBilling: PropTypes.func,
    cancelNoBilling: PropTypes.func,
    contactId: PropTypes.number,
    showModal: PropTypes.bool
};
