import React, {Component} from 'react';
import CSVUploader from "alpaca.js/dist/bulk_upload/csvUploader";

const COLUMN_MAPPINGS = {
    examination_resolution_id: "Auth Number",
    payment_number: "Payment Number",
    payment_date: "Payment Date",
    payment_type: "Payment Type",
};

export default class InvoiceUpload extends Component {
    render() {
        return  (
            <div className="container">
                <CSVUploader
                    mappings={this.props.mappings}
                    data={this.props.data}
                    columns={COLUMN_MAPPINGS}
                    uploadUrl="/api/invoices/bulk/"
                    onUpload={() => {document.location = '/invoices/upload/errors/'}} />
            </div>
        )
    }
}
