import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import axios from "axios/index";
import { filter } from 'lodash';


export default class MultiAttachmentInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelectFile = this.handleSelectFile.bind(this);
        this.handleAddFile = this.handleAddFile.bind(this);

        this.state = {
            loading: false,
            current_file: null,
            attachments: this.props.attachments,
        };
    }

    handleSelectFile(event) {
        this.setState({current_file: event.target.files[0]});
    }

    handleAddFile(event) {
        const { onChange } = this.props;
        this.setState({loading: true});

        var formData = new FormData();
        formData.append('attachment', this.state.current_file);
        formData.append('attachment_type', this.props.attachment_type);

        axios.post('/api/attachments/', formData).then((response) => {
            this.refs.file_input.value = "";
            $(this.refs.file_input).change();

            this.setState({
                current_file: null,
                loading: false,
                attachments: this.state.attachments.concat([response.data])
            }, function(){
                if (onChange) {
                    onChange(this.state.attachments);
                }
            });
        });
    }

    handleDeleteFile(id, event) {
        event.preventDefault();
        const { onChange } = this.props;

        this.setState({
            'attachments': filter(this.state.attachments, function(x) { return x.id !== id; })
        }, function(){
            if (onChange) {
                onChange(this.state.attachments);
            }
        });
    }

    render() {
        return (
            <div className="multi-attachment">
                <div className="multi-attachment-input">
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" onChange={this.handleSelectFile} disabled={this.state.loading} ref="file_input" />
                        <label className="custom-file-label" data-placeholder={this.props.placeholder} data-button={this.props.button_text} />
                    </div>
                    <Button color="primary" outline disabled={this.state.loading || !this.state.current_file} onClick={this.handleAddFile}>
                        <Fa icon={this.state.loading ? "spinner" : "plus"} spin={this.state.loading}/> Add
                    </Button>
                </div>

                {this.state.attachments.map((attachment, index) =>
                    <div className="multi-attachment-file" key={index}>
                        <Fa icon="paperclip" size={1}/> {attachment.filename} <a href="#" className="multi-attachment-delete" onClick={this.handleDeleteFile.bind(this, attachment.id)}><Fa icon="times" /></a>
                    </div>
                )}
            </div>
        );
    }
}

MultiAttachmentInput.propTypes = {
    onChange: PropTypes.func,
    attachments: PropTypes.array,
    attachment_type: PropTypes.string,
    placeholder: PropTypes.string,
    button_text: PropTypes.string,
};

MultiAttachmentInput.defaultProps = {
    attachments: [],
    button_text: "Browse",
    placeholder: "Choose file...",
    attachment_type: null,
};
