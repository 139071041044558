import React from 'react';
import APITableCheckboxActionButton from "alpaca.js/dist/util/apiTableCheckboxActionButton";
import axios from "axios";

const InvoiceApproveSelectedButton = () => {
    let onFailure = (error) => {
        let errors = error.response.data;

        if(errors.non_field_errors) {
            alert(errors.non_field_errors[0]);
        }
    };

    return <APITableCheckboxActionButton url="/api/invoices/accept/" outline size="xs" onFailure={onFailure} icon="check" axiosInstance={axios}>
        Accept Selected
    </APITableCheckboxActionButton>;
};

export default InvoiceApproveSelectedButton;
