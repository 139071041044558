import React from 'react';
import PropTypes from 'prop-types';
import {ExaminationOption} from "./options/examination_option";
import {ExaminationRepairOption} from "./options/examination_repair_option";
import {ExaminationReplacePartsOption} from "./options/examination_replace_parts_option";
import {ExaminationReselectionOption} from "./options/examination_reselection_option";


const OPTION_COMPONENT_MAP = {
    repair: ExaminationRepairOption,
    replace_parts: ExaminationReplacePartsOption,
    reselection: ExaminationReselectionOption
};


export class ExaminationClaimRow extends React.Component {
    constructor(props) {
        super(props);
    }

    renderOption(option_name) {
        let Component = _.get(OPTION_COMPONENT_MAP, option_name, ExaminationOption);

        return (
            <td key={option_name}>
                <Component
                    option_name={option_name}
                    examination_claim={this.props.examination_claim}
                    selected_option={this.props.selected_option}
                    selectOption={this.props.selectOption}
                    is_second_repair={this.props.is_second_repair}
                    selected_vendor={this.props.selected_vendor}
                    loadExamination={this.props.loadExamination}
                    examination={this.props.examination} />
            </td>
        );
    }

    render() {
        let examination_claim = this.props.examination_claim;

        return (
            <tr>
                <td>
                    <h6>Claim {examination_claim.claim.name}</h6>
                    {examination_claim.claim.item !== null &&
                        <div className="claim-item">{examination_claim.claim.item.description}</div>
                    }
                </td>

                {_.keys(this.props.examination_claim.options).map((option_name) =>
                    this.renderOption(option_name)
                )}

            </tr>
        );
    }
}

ExaminationClaimRow.propTypes = {
    examination_claim: PropTypes.object.isRequired,
    selected_option: PropTypes.string,
    is_second_repair: PropTypes.bool.isRequired,
    selectOption: PropTypes.func.isRequired,
    selected_vendor: PropTypes.object,
};
