import React from 'react';
import { Row, Col } from 'reactstrap';
import FieldInput from '../forms/field_input';
import SelectInput from '../forms/select_input';
import linkState from '../link_state';
import { getIn } from 'immutable-setter';


export default class DamageLocation extends React.Component {
    constructor(props) {
        super(props);

         this.state = {
            location: this.props.location,
        };
    }

    onLinkStateChange() {
        const { onChange } = this.props;

        if (onChange) {
            onChange(this.state.location);
        }
    }

    getError(paths) {
        if(this.props.errors !== undefined) {
            return getIn(this.props.errors, paths);
        }

        return null;
    }

    render() {
        let location = this.state.location;

        return (
            <Row>
                <Col sm="5">
                    <SelectInput
                        label="Damage Location"
                        onChange={linkState(this, 'location', ['location_id'])}
                        value={location.location_id}
                        error={this.getError(['location_id'])}
                        choices={this.props.damage_location_types} />
                </Col>
                <Col sm="3">
                    <FieldInput label="Damage Size" onChange={linkState(this, 'location', ['size'])} value={location.size} error={this.getError(['size'])}/>
                </Col>
                <Col sm="4">
                    <FieldInput label="Damage Quantity" onChange={linkState(this, 'location', ['quantity'])} value={location.quantity} error={this.getError(['quantity'])}/>
                </Col>
            </Row>
        );
    }
}
