import React, { Component } from 'react';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import {Button} from 'reactstrap';
import axios from "axios";


export default class ViewReceiptButton extends Component {
    constructor(props) {
        super(props);
        this.goToNext = this.goToNext.bind(this);
        this.goToPrev = this.goToPrev.bind(this);
        this.openImage = this.openImage.bind(this);

        this.state = {
            isOpen: false,
            loading: true,
            attachments: [],
        };
    }

    componentDidMount() {
        this.loadAttachments();
    }

    loadAttachments() {
        axios.get(`/api/plans/${this.props.plan}/attachments/`).then((response) => {
            this.setState({
                attachments: response.data.results,
                loading: false
            });
        });
    }

    openImage() {
        this.setState({ isOpen: true, open_index: 0});
    }

    getSrc(index) {
        return this.state.attachments[index].attachment;
    }

    getMainSrc() {
        return this.getSrc(this.state.open_index);
    }

    getPrevSrc() {
        return this.getSrc(this.getPrevIndex());
    }

    getNextSrc() {
        return this.getSrc(this.getNextIndex());
    }

    getNextIndex() {
        let count = this.state.attachments.length;
        return (this.state.open_index + count - 1) % count;
    }

    getPrevIndex() {
        let count = this.state.attachments.length;
        return (this.state.open_index + 1) % count;
    }

    goToNext() {
        this.setState({open_index: this.getNextIndex()});
    }

    goToPrev() {
        this.setState({open_index: this.getPrevIndex()});
    }

    render() {
        if(this.state.attachments.length === 0) {
            return null;
        }

        return (
            <div className="d-inline-block">
                <Button color="primary" size="xs" outline onClick={this.openImage} disabled={this.state.loading}>View Receipt</Button>

                {(!this.state.loading && this.state.isOpen) && (
                    <Lightbox
                        mainSrc={this.getMainSrc()}
                        nextSrc={this.getNextSrc()}
                        prevSrc={this.getPrevSrc()}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={this.goToNext}
                        onMoveNextRequest={this.goToPrev}
                        reactModalStyle={{overlay: {zIndex: 1110}}}
                    />
                )}
            </div>
        );
    }
}
