import React, {Fragment} from 'react';

import {Button, Col, Row, Table} from 'reactstrap';
import CheckBoxInput from "../../forms/checkbox_input";
import linkState from '../../link_state';
import FieldInput from "../../forms/field_input";
import DateInput from "../../forms/date_input";
import SelectInput from '../../forms/select_input';
import {setIn} from "immutable-setter";
import {
    ExaminationConfirmationWrapper,
    has_quote_response,
    LabeledTotal,
    QuoteResponseNote,
    validate_resolution
} from './common';
import {currencyLinkState} from "../../util";
import Fa from "alpaca.js/dist/util/fa";


const DEFAULT_PART = {
    'available': null,
    'available_time_frame': '',
    'description': '',
    'part_number': '',
    'wholesale_price': '',
    'delivery_price': '',
    'installation_price': '',
    'installation_available': false,
};


export default class ReplacePartsResolution extends React.Component {
    constructor(props) {
        super(props);

        let initial_data = this.calculateTotals(props.data);

        if (props.is_rtg_claim) {
            // rooms to go claims default override to true.
            initial_data.override = true;

            initial_data.invoice_date = "";
            initial_data.invoice_number = "";
        }

        this.state = {
            data: initial_data
        };

        // push the calculated totals and any other changes up to the parent object
        this.props.onChange(initial_data);
    }

    calculateTotals(data) {
        // given initial data from props, or updated data after a state change, calculate totals and ensure
        // all resolution claims have the necessary extra_data.  Returns the update data object.

        // helper func to get an examination_claim object from props by id
        let examination_claims = this.props.examination.examination_claims;
        let find_examination_claim = (id) => _.filter(examination_claims, ec => ec.id === id)[0];
        let to_num = (val) => parseFloat(val) || 0;

        data = _.cloneDeep(data);  // prevent modifying the supplied data object, which could be from state or props

        data.calculated_amount = data.authorized_amount = data.authorized_delivery_amount = 0;
        data.extra_data = {
            authorized_part_amount: 0,
            authorized_installation_amount: 0
        };

        for (let index = 0; index < data.resolution_claims.length; index++) {
            let examination_claim = find_examination_claim(data.resolution_claims[index].examination_claim);
            let amount = parseFloat(examination_claim.options[this.props.data.option_type].amount);
            data.calculated_amount += amount;

            if (data.resolution_claims[index].extra_data === undefined || !data.override) {
                data.resolution_claims[index].extra_data = this.build_initial_extra_data(examination_claim.id);
            }

            let parts = data.resolution_claims[index].extra_data.parts;
            // subtotals for all parts in this resolution claim
            let part_total = _.reduce(parts, (sum, row) => sum + to_num(row.wholesale_price), 0);
            let delivery_total = _.reduce(parts, (sum, row) => sum + to_num(row.delivery_price), 0);
            let install_total = _.reduce(parts, (sum, row) => sum + to_num((row.installation_available) ? row.installation_price : 0), 0);

            // sum of sub totals for all resolution claims
            data.extra_data.authorized_part_amount += part_total;
            data.authorized_delivery_amount += delivery_total;
            data.extra_data.authorized_installation_amount += install_total;

            // total for this resolution claim
            data.resolution_claims[index].authorized_amount = part_total + delivery_total + install_total;

            // sum of total for all resolution claims
            data.authorized_amount += data.resolution_claims[index].authorized_amount;
        }

        // don't allow submission until validation method is built out
        data.can_submit = this.canSubmit(data);

        return data;
    }

    calculateAndPush(data) {
        data = this.calculateTotals(data);
        this.setState({data: data});
        this.props.onChange(data);
    }

    build_initial_extra_data(examination_claim_id) {
        // Builds the initial parts array for the extra_data of this resolution_claim

        const quote_request = this.props.examination.quote_request;
        if (quote_request === null) {
            return {parts: []};
        }

        // const examination_claim = _.filter(this.props.examination.examination_claims, (x => x.id === ec_id))[0];
        const quote_request_claim = _.filter(quote_request.quote_request_claims, (x => x.examination_claim_id === examination_claim_id))[0];

        let parts = _.map(quote_request_claim.parts, parts_obj => {
            return _.defaults(
                _.omitBy(parts_obj, (val, key) => key === 'id' || _.isNull(val)),
                DEFAULT_PART,
                {'quoterequestclaimpart_id': parts_obj.id}
            );
        });

        return {
            parts: parts || []
        };
    }

    onLinkStateChange() {
        // triggered after state is updated from a form field change
        this.calculateAndPush(this.state.data);
    }

    canSubmit(data) {
        // return true if data object provided can be submitted
        if (!validate_resolution(data)) {
            // requires authorized amount to be set, and override reason to be provided if override is set
            return false;
        }
        // validate the parts
        for (let index = 0; index < data.resolution_claims.length; index++) {
            let res_claim = data.resolution_claims[index];
            if (_.isArray(res_claim.extra_data.parts)) {
                for (let part_index = 0; part_index < res_claim.extra_data.parts.length; part_index++) {
                    let part = res_claim.extra_data.parts[part_index];
                    let price = parseFloat(part.wholesale_price);
                    if (_.trim(part.description) === '' || isNaN(price) || price < 0) {
                        return false;
                    }
                }
            }
        }
        // in case of rooms to go,
        if (this.props.is_rtg_claim) {
            // require invoice_number
            if (!data.invoice_number) {
                return false;
            }
            // require invoice_date
            if (!data.invoice_date) {
                return false;
            }
        }
        return true;
    }

    addPart(resolution_claim_index) {
        if (!this.state.data.override) {
            return;
        }
        // build a new parts array by copying the existing array and adding a blank part to it,
        // then replace the existing parts array on the extra_data property for this resolution
        let resolution_claim = this.state.data.resolution_claims[resolution_claim_index];
        let new_parts = [...resolution_claim.extra_data.parts, _.clone(DEFAULT_PART)];
        let new_data = setIn(this.state.data, ["resolution_claims", resolution_claim_index, "extra_data", "parts"], new_parts);
        this.calculateAndPush(new_data);
    }

    removePart(resolution_claim_index, part_index) {
        if (!this.state.data.override) {
            return;
        }
        // build a new parts array by filtering out the passed in part_index element,
        // then replace the existing parts array on the extra_data property for this resolution
        let old_parts = this.state.data.resolution_claims[resolution_claim_index].extra_data.parts;
        let new_parts = old_parts.filter((value, idx) => idx !== part_index);
        let new_data = setIn(this.state.data, ["resolution_claims", resolution_claim_index, "extra_data", "parts"], new_parts);
        this.calculateAndPush(new_data);
    }

    renderPart(part, examinations_claim_id, resolution_claim_index, part_index) {
        const time_frame = (<span style={{'whiteSpace': 'nowrap'}}>{part.available_time_frame}</span>);
        const part_path = ["resolution_claims", resolution_claim_index, "extra_data", "parts", part_index];
        const disabled = !this.state.data.override;

        return (
            <tr key={`part_${examinations_claim_id}_${part_index}`}>
                <td>
                    {(part.available !== null) ?
                        ((part.available) ? (<Fragment>Yes<br/>{time_frame}</Fragment>) : ('No')) :
                        ("Unknown")
                    }
                </td>
                <td>
                    <FieldInput value={part.description}
                                disabled={disabled}
                                onChange={linkState(this, "data", [...part_path, "description"])}/>
                </td>
                <td>
                    <FieldInput value={part.part_number}
                                disabled={disabled}
                                onChange={linkState(this, "data", [...part_path, "part_number"])}/>
                </td>
                <td>
                    <FieldInput value={part.wholesale_price}
                                disabled={disabled}
                                onChange={currencyLinkState(this, "data", [...part_path, "wholesale_price"])} type="number"/>
                </td>
                <td>
                    <FieldInput value={part.delivery_price}
                                disabled={disabled}
                                onChange={currencyLinkState(this, "data", [...part_path, "delivery_price"])} type="number"/>
                </td>
                <td>
                    <div className="d-flex">
                        <CheckBoxInput checked={part.installation_available}
                                       disabled={disabled}
                                       onChange={linkState(this, "data", [...part_path, "installation_available"])}/>
                        <FieldInput value={part.installation_price}
                                    disabled={disabled || !part.installation_available}
                                    onChange={currencyLinkState(this, "data", [...part_path, "installation_price"])}
                                    type="number"/>
                    </div>
                </td>
                <td>
                    <Button className="btn btn-danger btn-sm"
                            onClick={this.removePart.bind(this, resolution_claim_index, part_index)}>
                        <Fa icon="trash-alt" className="mr-0 ml-0" />
                    </Button>
                </td>
            </tr>
        );
    }

    renderPartsForResolution(resolution_claim, resolution_claim_index) {
        let examination_claim = _.filter(this.props.examination.examination_claims, ec => ec.id === resolution_claim.examination_claim)[0];

        return <Fragment key={`rc_parts_${resolution_claim_index}`}>
            <h5 className="mt-4">
                Claim {examination_claim.claim.name}
                <Button className="btn-xs btn-outline-primary ml-4"
                        onClick={this.addPart.bind(this, resolution_claim_index)}
                        disabled={!this.state.data.override}
                >
                    Add Part
                </Button>
            </h5>
            <Table className="mb-1">
                <thead>
                <tr>
                    <th>Available</th>
                    <th>Description</th>
                    <th>Part #</th>
                    <th className="price-col">Price</th>
                    <th className="price-col">Delivery</th>
                    <th className="price-col">Installation</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {resolution_claim.extra_data.parts.map((part, index) => {
                    return this.renderPart(part, examination_claim.id, resolution_claim_index, index)
                })}
                </tbody>
            </Table>
        </Fragment>
    }

    render() {
        let data = this.state.data;
        let quote_request = this.props.examination.quote_request;

        return <ExaminationConfirmationWrapper title="Replace Parts" total={data.calculated_amount}>
            <Row className="mb-4">
                <Col md={6}>
                    <div>
                        <LabeledTotal label="Total Authorized Parts" total={data.extra_data.authorized_part_amount}/>
                        <LabeledTotal label="Total Authorized Delivery" total={data.authorized_delivery_amount}/>
                        <LabeledTotal label="Total Authorized Installation"
                                      total={data.extra_data.authorized_installation_amount}/>
                        <LabeledTotal label="Total Authorized Amount" total={data.authorized_amount}/>
                    </div>
                </Col>
                <Col md={6}>
                    {has_quote_response(this.props.examination.quote_request, data.resolution_claims) && (
                        <QuoteResponseNote response_notes={quote_request.response_notes}/>
                    )}

                    <div className="w-50">
                        <SelectInput
                            value={data.delivery_method}
                            label="Delivery Method"
                            choices={[["ship_to_customer", "Ship to Customer"], ["ship_to_retailer", "Ship to Retailer"]]}
                            minimumResultsForSearch={20}
                            onChange={linkState(this, "data", ["delivery_method"])}/>
                    </div>
                    <CheckBoxInput
                        label="Override Authorized Amount"
                        onChange={linkState(this, "data", ["override"])}
                        checked={data.override}
                    />
                    {data.override && (
                        <FieldInput
                            value={data.override_reason}
                            label="Override Reason"
                            onChange={linkState(this, "data", ["override_reason"])}/>
                    )}
                </Col>
            </Row>

            {this.state.data.resolution_claims.map((resolution_claim, index) => {
                return this.renderPartsForResolution(resolution_claim, index);
            })}

            <FieldInput
                type="textarea"
                label="Additional Notes to Retailer"
                value={data.notes}
                onChange={linkState(this, "data", ["notes"])}/>

            {this.props.is_rtg_claim && (
                <Row>
                    <Col sm="4">
                        <FieldInput
                            value={data.invoice_number}
                            label="Invoice Number"
                            onChange={linkState(this, "data", ["invoice_number"])}
                        />
                    </Col>
                    <Col sm="4">
                        <DateInput
                            value={data.invoice_date}
                            label="Invoice Date"
                            onChange={linkState(this, 'data', ['invoice_date'])}
                            options={{maxDate: new Date()}}
                        />
                    </Col>
                </Row>
            )}

        </ExaminationConfirmationWrapper>
    }
}
