import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {ConfirmButton} from "../util/confirm_button";
import Fa from "alpaca.js/dist/util/fa";


export class ContactUnlinkButton extends React.Component {
    constructor(props) {
        super(props);
        this.doUnlink = this.doUnlink.bind(this);

        this.state = {
            unlinking: false,
        };
    }

    doUnlink() {
        this.setState({unlinking: true});

        axios.post(`/api/contacts/${this.props.contactId}/unlink_portal_user/`).then((response) => {
            this.setState({ unlinking: false });
            document.location.reload();
        });
    }

    render() {
        return (
            <ConfirmButton
                onConfirm={this.doUnlink}
                loading={this.state.unlinking}
                className="btn btn-outline-primary btn-xs"
                confirmTitle="Unlink Portal User"
                confirmMessage="This will delete the association between the portal user and this contact. Are you sure you want to continue?">

                <Fa icon="unlink" /> Unlink

            </ConfirmButton>
        )
    }
}

ContactUnlinkButton.propTypes = {
    contactId: PropTypes.number.isRequired,
};
