import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';
import Fa from "alpaca.js/dist/util/fa";
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';


export class StartExaminationButton extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            loading: false,
            message: null,
            modal_opened: false
        }
    }

    toggleModal() {
        if(this.state.loading) {
            return;
        }

        this.setState({ modal_opened: !this.state.modal_opened });
    }

    renderModal() {
        return (
            <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal} backdrop={this.state.loading ? "static" : true}>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">Warning</h3>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.toggleModal} disabled={this.state.loading}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <hr/>
                    <p>{this.state.message}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.loading} className="btn-wide">Cancel</Button>
                    <Button color="primary" onClick={this.sendRequest.bind(this, false)} disabled={this.state.loading}
                            className="btn-wide">
                        {this.state.loading ? (
                            <span><Fa icon="spinner" spin/> loading...</span>
                        ) : (
                            "Continue"
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    sendRequest(check_service_group) {
        this.setState({loading: true});
        let component = this;

        axios.post(`/api/claims/${this.props.claim_id}/start_examination/`, {check_service_group: check_service_group}).then((response) => {
            document.location = response.data.url;
        }).catch(function (error) {
            if(error.response.data.message) {
                component.setState({message: error.response.data.message, loading: false}, component.toggleModal);
            }
        });
    }

    render() {
        return (
            <div className="d-inline-block">
                <button onClick={this.sendRequest.bind(this, true)} className={classNames("btn", this.props.button_class)} disabled={this.state.loading}>
                    {this.state.loading ? (
                        <span><Fa icon="spinner" spin/> loading...</span>
                    ) : (
                        "Start Examination"
                    )}
                </button>
                {this.renderModal()}
            </div>
        )
    }
}

StartExaminationButton.propTypes = {
    claim_id: PropTypes.number.isRequired,
    button_class: PropTypes.string,
};

StartExaminationButton.defaultProps = {
    button_class: "btn-primary",
};
