import React from 'react';
import { Row, Col, Button, Card, CardBody, Modal, ModalFooter, ModalHeader, ModalBody, Alert } from 'reactstrap';
import { getIn, deleteIn } from 'immutable-setter';
import Fa from "alpaca.js/dist/util/fa";
import axios from "axios/index";


export class TicketServiceGroupForm extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            saving: false,
            deleting: false,
            downloading_claims: false,
            available_claims: [],
            claims: [],
            original_claims: [],
            modal_opened: false,
            mode: "edit"
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({downloading_claims: true});

        let requests = [];

        requests.push(axios.get('/api/claims/', {params: {ticket: this.props.ticket, available_for_service_group: true}}));

        if(this.props.service_group) {
            requests.push(axios.get('/api/claims/', {params: {ticket: this.props.ticket, service_group: this.props.service_group}}));
        }

        let component = this;

        axios.all(requests).then(axios.spread(function(available_claims, claims) {
            component.setState({
                available_claims: available_claims.data.results,
                downloading_claims: false,
                claims: claims ? claims.data.results : [],
                original_claims: claims ? claims.data.results : []
            });
        }));
    }

    onAddClaim(index) {
        this.setState({
            claims: this.state.claims.concat([this.state.available_claims[index]]),
            available_claims: deleteIn(this.state.available_claims, [index])
        });
    }

    onRemoveClaim(index) {
        this.setState({
            claims: deleteIn(this.state.claims, [index]),
            available_claims: this.state.available_claims.concat([this.state.claims[index]])
        });
    }

    toggleModal() {
        if(this.state.deleting || this.state.saving) {
            return;
        }

        this.setState({ modal_opened: !this.state.modal_opened });
    }

    saveGroup(confirmed) {
        if(!confirmed && this.props.service_group && this.props.examination) {
            this.setState({mode: "edit"});
            this.toggleModal();
            return;
        }

        this.setState({
            saving: true
        });

        let params = {
            ticket: this.props.ticket,
            claims: this.state.claims.map((claim) => claim.id),
        };

        const next_url = this.props.next_url;

        let success_function = function(response) {
            document.location = next_url;
        };

        if(this.props.service_group) {
            axios.put('/api/service-groups/' + this.props.service_group + '/', params).then(success_function)
        } else {
            axios.post('/api/service-groups/', params).then(success_function);
        }
    }

    deleteGroup(confirmed) {
        if(!confirmed && this.props.service_group) {
            this.setState({mode: "delete"});
            this.toggleModal();
            return;
        }

        this.setState({
            deleting: true
        });

        const next_url = this.props.next_url;

        axios.post('/api/service-groups/' + this.props.service_group + '/delete/').then(function() {
            document.location = next_url;
        });
    }

    renderClaimList(claims, icon, onAction) {
        if(this.state.downloading_claims) {
            return (
                <span className="text-muted"><Fa icon="spinner" spin/> loading...</span>
            );
        }

        return claims.length > 0 ? (
            <div className="selection-list">
                {claims.map((claim, index) =>
                    <div className="selection-row" key={index}>
                        <h5 className="d-inline-block mb-1 mt-1 mr-3" style={{verticalAlign: "middle"}}>
                            <a href={claim.url} target="_blank">Claim {claim.name}</a>
                        </h5>
                        <div className="claim-item d-inline-flex mt-0" style={{minWidth: 200}}>
                            {claim.item.description}
                        </div>
                        <a className="selection-add-remove" onClick={onAction.bind(this, index)}><Fa icon={icon} size={1} /></a>
                    </div>
                )}
            </div>
        ) : (
            <span className="text-muted">No Claims</span>
        )
    }

    render() {
        return (
            <div>
                {this.state.claims.length > 0 &&
                    <Card className="mb-3">
                        <CardBody>
                            <Row className="justify-content-center">
                                <Col md={8}>
                                    {this.renderClaimList(this.state.claims, "minus-circle", this.onRemoveClaim)}

                                    <div className="text-center">
                                        <Button color="primary" className="btn-wide" disabled={this.state.claims.length <= 1 || this.state.saving || this.state.deleting} onClick={this.saveGroup.bind(this, false)}>
                                            {this.state.saving ? (
                                                <span><Fa icon="spinner" spin/> saving...</span>
                                            ) : (
                                                "Save Group"
                                            )}
                                        </Button>
                                        &#160;&#160;
                                        {this.props.service_group &&
                                            <Button color="danger" outline className="btn-wide" disabled={this.state.deleting || this.state.saving} onClick={this.deleteGroup.bind(this, false)}>
                                                {this.state.deleting ? (
                                                    <span><Fa icon="spinner" spin/> deleting...</span>
                                                ) : (
                                                    "Delete Group"
                                                )}
                                            </Button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                }

                <Card>
                    <CardBody>
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <h4>Pending Claims on Ticket Not in Group</h4>
                                {this.renderClaimList(this.state.available_claims, "plus", this.onAddClaim)}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal} backdrop={(this.state.deleting || this.state.saving) ? "static" : true} size="lg">
                    <ModalHeader toggle={this.toggleModal} tag="h3">
                        {this.state.mode === 'edit' ? "Confirm Service Group Edit" : "Confirm Service Group Delete"}
                    </ModalHeader>
                    <ModalBody>
                        <Alert color="warning">
                            <Row>
                                <Col xs="1">
                                    <Fa icon="exclamation-triangle" size="1" />
                                </Col>
                                {this.state.mode === 'edit' ? (
                                    <Col>
                                        Service group edits made after examination has begun will result in the cancellation of the examination.
                                        <div className="font-weight-normal">Affected claims must be resubmitted for examination.</div>
                                    </Col>
                                ) : (
                                    <Col>
                                        Deleting a service group after examination has begun will result in the deletion of the examination.
                                        <div className="font-weight-normal">Affected claims must be resubmitted for examination.</div>
                                    </Col>
                                )}
                            </Row>
                        </Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.saving || this.state.deleting} className="btn-wide">
                            Cancel
                        </Button>

                        {this.state.mode === 'edit' ? (
                            <Button color="primary" onClick={this.saveGroup.bind(this, true)} disabled={this.state.saving}>
                                {this.state.saving ? (
                                    <span><Fa icon="spinner" spin/> saving...</span>
                                ) : (
                                    "Confirm Change"
                                )}
                            </Button>
                        ) : (
                            <Button color="primary" onClick={this.deleteGroup.bind(this, true)} disabled={this.state.deleting}>
                                {this.state.deleting ? (
                                    <span><Fa icon="spinner" spin/> deleting...</span>
                                ) : (
                                    "Confirm Delete"
                                )}
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
