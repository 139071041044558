import React from 'react';
import axios from 'axios';
import {Button, Col, Modal, ModalBody, ModalFooter, Row} from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import FieldInput from "../forms/field_input";
import linkState from "../link_state";
import {getIn} from "immutable-setter";
import {currencyLinkState} from "../util";
import CheckBoxInput from "../forms/checkbox_input";


export class RTGAuthButtonServiceGroup extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.sendAuth = this.sendAuth.bind(this);
        this.getDefaultValues = this.getDefaultValues.bind(this);

        this.getDefaultAuthActions = this.getDefaultAuthActions.bind(this);
        this.getDefaultAuthAmounts = this.getDefaultAuthAmounts.bind(this);
        this.remapClaimIds = this.remapClaimIds.bind(this);


        this.state = {
            modal_opened: false,
            sending: false,
            loading: false,
            errors: {},
            new_notes: props.examination.examination_claims.reduce((notes, claim) => {
                notes[claim.id] = "";
                return notes;
            }, {}),
            new_auth_actions: props.examination.examination_claims.reduce((actions, claim) => {
                actions[claim.id] = "";

                return actions;
            }, {}),
            new_auth_amount: props.examination.examination_claims.reduce((amounts, claim) => {
                amounts[claim.id] = 0.0;
                return amounts;
            }, {}),
            data: {
                notes: [],
            }
        };
    }

    getDefaultAuthActions() {
        let auth_actions = {};
        for (let index in this.props.examination.examination_claims) {
            let examination_claim = this.props.examination.examination_claims[index];
            let selected_option = this.props.selections[examination_claim.id];

            if (selected_option === undefined) {
                auth_actions[examination_claim.id] = "";
            } else if (selected_option === "replace_parts") {
                auth_actions[examination_claim.id] = "Part Order";
            } else {
                let option = examination_claim.options[selected_option];
                auth_actions[examination_claim.id] = option.option_type_name;
            }
        }

        return auth_actions;
    }

    getDefaultAuthAmounts() {
        let auth_amounts = {};
        for (let index in this.props.examination.examination_claims) {
            let examination_claim = this.props.examination.examination_claims[index];
            let selected_option = this.props.selections[examination_claim.id];

            auth_amounts[examination_claim.id] = 0.0;

            if (selected_option === undefined) {

            } else if (selected_option === "replace_parts") {

            } else {
                let option = examination_claim.options[selected_option];

                if (option.amount !== null) {
                    auth_amounts[examination_claim.id] = parseFloat(option.amount);
                }
            }
        }
        return auth_amounts;
    }

    getDefaultValues() {
        let totals = [];
        let actions = new Array();
        let action = "";
        if (this.props.option_type == "quote_request") {
            actions.push("Quote Request");
        } else {
            for (let index in this.props.examination.examination_claims) {
                let examination_claim = this.props.examination.examination_claims[index];
                let selected_option = this.props.selections[examination_claim.id];
                if (selected_option === undefined) {
                    actions.push("");
                    totals.push(0.0);
                    continue;
                }
                if (selected_option == "replace_parts") {
                    actions.push("Part Order");
                    totals.push(0.00);
                    continue;
                }
                let option = examination_claim.options[selected_option];
                if (option.amount !== null) {
                    totals.push(parseFloat(option.amount));
                    action = option.option_type_name;
                    if (action === 'Repair') {
                        action = action + ": ";
                        for (let i in examination_claim.selected_services) {
                            action = action + examination_claim.selected_services[i].selected_service.name;
                            actions.push(action);
                            action = "";
                        }
                    } else {
                        actions.push(action);
                    }
                } else {
                    actions.push(action);
                }
            }
        }
        return {
            authorization_amounts: totals,
            authorization_actions: actions,
            notes: [],
        };
    }

    onLinkStateChange() {
        const {onChange} = this.props;

        if (onChange) {
            onChange(this.state.data);
        }
    }

    getError(paths) {
        if (this.state.errors !== undefined) {
            return getIn(this.state.errors, paths);
        }

        return null;
    }

    toggleModal() {
        if (this.state.sending) {
            return;
        }
        this.setState({
            modal_opened: !this.state.modal_opened,
            data: this.getDefaultValues(),
            new_auth_actions: this.getDefaultAuthActions(),
            new_auth_amount: this.getDefaultAuthAmounts(),
        });
    }

    remapClaimIds() {
        let data = {};

        data.authorization_action_map = {};
        for (const [ex_claim_id, action] of Object.entries(this.state.new_auth_actions)) {
            let claim = this.props.examination.examination_claims.find((ex_claim) => ex_claim.id == ex_claim_id);
            data.authorization_action_map[claim.claim.id.toString()] = action;
        }

        data.authorization_amount_map = {};
        for (const [ex_claim_id, amount] of Object.entries(this.state.new_auth_amount)) {
            let claim = this.props.examination.examination_claims.find((ex_claim) => ex_claim.id == ex_claim_id);
            data.authorization_amount_map[claim.claim.id.toString()] = amount;
        }

        data.notes_map = {};
        for (const [ex_claim_id, note] of Object.entries(this.state.new_notes)) {
            let claim = this.props.examination.examination_claims.find((ex_claim) => ex_claim.id == ex_claim_id);
            data.notes_map[claim.claim.id.toString()] = note;
        }

        return data;
    }

    sendAuth() {
        let component = this;
        this.setState({sending: true});
        let new_data = this.remapClaimIds();
        axios.post(`/api/examination/${this.props.examination.id}/send_rtg_communication/`, new_data).then((response) => {
            this.setState({modal_opened: false, finalizing: false});
            document.location.reload();
        }).catch(function (error) {
            if (error.response) {
                let errors = JSON.parse(error.response.request.response);
                component.setState({errors: errors});
            } else if (error.request) {
                alert("Unable to communicate with server");
            }
        }).finally(function () {
            component.setState({sending: false});
        });
    }

    renderModal() {
        let data = this.state.data;
        let heading;
        let heading_description;
        if (this.props.option_type == 'quote_request') {
            heading = "Quote Request";
            heading_description = "Email a quote request to Rooms to Go";
        } else {
            heading = "Send Authorization";
            heading_description = "Send authorization information to Rooms To Go";
        }
        console.log(this.state);

        return (
            <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal}
                   backdrop={this.state.sending ? "static" : true}
                   size="xl">
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">{heading}</h3>
                        <p>{heading_description}</p>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.toggleModal}
                            disabled={this.state.sending}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    {this.props.examination.examination_claims.map((claim, index) =>
                    <React.Fragment key={claim.id}>
                    <Row>
                        <Col>
                            <h6>Claim {claim.claim.name}</h6>
                            {claim.claim.item !== null &&
                                <div className="claim-item">{claim.claim.item.description}</div>
                            }
                        </Col>
                        <Col>
                            <FieldInput
                                label="Authorization Action"
                                onChange={linkState(this, 'new_auth_actions', [claim.id])}
                                value={this.state.new_auth_actions[claim.id]}
                                error={this.getError(['authorization_action'])}
                                readOnly={true}
                            />
                        </Col>
                        <Col>
                            <FieldInput
                                label="Authorization Amount"
                                onChange={currencyLinkState(this, 'new_auth_amount', [claim.id])}
                                value={this.state.new_auth_amount[claim.id]}
                                error={this.getError(['authorization_amount'])}
                                type="number"
                            />
                        </Col>
                        <Col>
                            <FieldInput
                                label="Notes"
                                onChange={linkState(this, 'new_notes', [claim.id])}
                                value={this.state.new_notes[claim.id]}
                                error={this.getError(['notes'])}
                                type="textarea"
                                maxLength="512"
                            />
                        </Col>
                    </Row>
                    </React.Fragment>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.sending}
                            className="btn-wide">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.sendAuth} disabled={this.state.sending} className="btn-wide">
                        {this.state.sending ? (
                            <span><Fa icon="spinner" spin/> Sending </span>
                        ) : (
                            "Send Data to RTG"
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        let title;
        if (this.props.option_type == "quote_request") {
            title = "Email Quote Request";
        } else {
            title = "Send Data to Rooms to Go";
        }

        return (
            <span>
                <Button onClick={this.toggleModal}
                        disabled={this.props.disabled}
                        color={this.props.color}
                        size={this.props.size}
                        className={this.props.className}
                >{title}</Button>

                {this.renderModal()}
            </span>
        )
    }
}

// RTGAuthButton.propTypes = {
//     resolution_id: PropTypes.number.isRequired,
// };
