import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import { InteractionsPanel } from './interactions_panel';
import Paginator from '../paginator';


export class InteractionsOverviewPanel extends InteractionsPanel {
    constructor(props) {
        super(props);
    }

    loadMessages() {
        this.setState({loading: true});

        let params = {page: this.state.current_page, contact: this.props.contact, page_size: 5};

        if(this.state.current_tab !== 'all') {
            params.message_type =  this.state.current_tab;
        }

        axios.get('/api/messages/', {params: params}).then((response) => {
            this.setState({
                messages: response.data.results,
                current_page: response.data.current_page,
                total_pages: response.data.total_pages,
                loading: false
            });
        });
    }

    renderHeader() {
        return (
            <h3>Interactions</h3>
        )
    }

    renderReply() {
        return null;
    }

    getShowTicketColumn() {
        return true;
    }

    renderFooter() {
        return (
            <div className="table-list-footer">
                <Row>
                    <Col sm={4} xs={12}>
                        <a href={'/messages/?contact=' + this.props.contact} className="btn btn-xs btn-outline-primary" target="_blank">Show All</a>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Paginator activePage={this.state.current_page} numPages={this.state.total_pages} onSelect={this.changePage} />
                    </Col>
                    <Col sm={4} xs={12} />
                </Row>
            </div>
        );
    }
}

InteractionsOverviewPanel.propTypes = {
    contact: PropTypes.number,
};
