import React from 'react';

import {FinalizeClaimsButton} from "./finalize_claims_button";


export class CheckIssuedButton extends React.Component {
    render() {
        return (
            <FinalizeClaimsButton {...this.props} title="Check Issued"/>
        )
    }
}
