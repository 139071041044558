import React from 'react';
import Fa from "alpaca.js/dist/util/fa";
import { Row, Col, Button } from 'reactstrap';
import ClaimDamageDetails from './claim_damage_details';
import {deleteIn, setIn} from 'immutable-setter';


export class ClaimDamageDetailsFormset extends React.Component {
    constructor(props) {
        super(props);
        this.handleAddAnother = this.handleAddAnother.bind(this);

        let damages = this.props.damages;
        if(damages.length === 0) {
            damages = [this.getEmptyDamage()];
        }

        this.state = {
            damages: damages
        };
    }

    getEmptyDamage() {
        return {
            code_reported: null,
            code_actual_different: false,
            detail_reported: {
                cause: '',
                cause_type_id: null,
                damage_date: null,
                damage_date_unknown: false,
                locations: [],
            },
            detail_actual_different: false
        };
    }

    handleAddAnother(e) {
        e.preventDefault();
        this.setState({damages: this.state.damages.concat([this.getEmptyDamage()])});
    }

    handleRemove(index) {
        this.setState({damages: deleteIn(this.state.damages, [index])});
    }

    handleDamageChange(index, damage) {
        this.setState(
            {'damages': setIn(this.state.damages, [index], damage)}
        );
    }

    getErrors(index) {
        if(this.props.errors !== undefined && this.props.errors.length > index) {
            return this.props.errors[index];
        }

        return null;
    }

    render() {
        return (
            <div>
                {this.state.damages.map((result, index) =>
                    <div key={`damage_${this.state.damages.length}_${index}`}>
                        {index > 0 &&
                            <hr className="mb-5 mt-5" />
                        }

                        <Row>
                            <Col sm="6"><h4>Damage Listing {index + 1}</h4></Col>

                            {index > 0 &&
                                <Col sm="6" className="text-right">
                                    <Button onClick={this.handleRemove.bind(this, index)} color="primary" outline>
                                        <Fa icon="minus-circle" /> Remove
                                    </Button>
                                </Col>
                            }
                        </Row>

                        <ClaimDamageDetails
                            damage={result}
                            onChange={this.handleDamageChange.bind(this, index)}
                            damage_code_tree={this.props.damage_code_tree}
                            errors={this.getErrors(index)}
                            cause_types={this.props.cause_types}
                            damage_location_types={this.props.damage_location_types} />
                    </div>
                )}

                <a href="#" className="font-weight-bold" onClick={this.handleAddAnother}>
                    <span className="icon-circle mr-3">
                        <Fa icon="plus" size={1}/>
                    </span>
                    Add Another Damage Listing for Item
                </a>

                <input type="hidden" value={JSON.stringify(this.state.damages)} name="claim_details_json"/>
            </div>
        );
    }
}
