import {parseNumber} from "./examination/examination_resolution/common";
import linkState from "./link_state";
import moment from "moment";

export function get_url_file_name(url) {
    return url.substr(url.lastIndexOf('/') + 1);
}

export function get_first_letter(string){
    if(string === null) {
        return '';
    }

    let letters_only = string.replace(/[\W\d_]/g, '');
    if(letters_only.length > 0) {
        return letters_only.charAt(0);
    }
    return '';
}

export function capitalize(string) {
    if(string === undefined) {
        return "";
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isNone(value) {
    return _.isNull(value) || _.isUndefined(value) || _.isNaN(value);
}

export function yesno(value) {
    if(isNone(value)) {
        return "Unknown";
    } else if(value) {
        return "Yes";
    } else {
        return "No";
    }
}

export function currencyValue(value) {
    if(isNone(value)) {
        return "";
    }

    value = parseFloat(value);

    if(parseInt(value) < value) {
        value = value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    } else {
        value = value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }

    return value;
}

export function currency(value) {
    if(isNone(value)) {
        return "";
    }

    return "$" + currencyValue(value);
}

export function textOrDash(value) {
    if(isNone(value)) {
        return <span className="text-muted">&mdash;</span>
    }

    return value;
}


export function lineBreaks(value) {
    if(isNone(value)) {
        return "";
    }

    return value.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
    });
}

export function lineBreaksHtml(value) {
    if(isNone(value)) {
        return "";
    }

    return value.split('\n').map((item, key) => {
        return <span key={key} dangerouslySetInnerHTML={{__html: item + "<br/>"}} />
    });
}

export function currencyLinkState(component, key, path) {
    function roundIfNeeded(value) {
        let newValue = parseNumber(value);

        if(newValue.toFixed(2).length < newValue.toString().length) {
            return newValue.toFixed(2);
        }

        return value;
    }
    return linkState(component, key, path, x => roundIfNeeded(x))
}


export function formatTime(timeStr) {
    return moment(timeStr).format('MM/DD/YYYY h:mm A');
}
