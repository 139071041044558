import React, { Component } from 'react';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import Fa from "alpaca.js/dist/util/fa";


export default class AttachmentViewer extends Component {
    constructor(props) {
        super(props);
        this.goToNext = this.goToNext.bind(this);
        this.goToPrev = this.goToPrev.bind(this);

        this.state = {
            isOpen: false,
            attachments: _.groupBy(this.props.attachments, ((attachment) => attachment.attachment_type)),
        };
    }

    openImage(attachment, index) {
        this.setState({ isOpen: true, open_section: attachment.attachment_type, open_index: index});
    }

    getSrc(index) {
        return this.state.attachments[this.state.open_section][index].attachment;
    }

    getMainSrc() {
        return this.getSrc(this.state.open_index);
    }

    getPrevSrc() {
        return this.getSrc(this.getPrevIndex());
    }

    getNextSrc() {
        return this.getSrc(this.getNextIndex());
    }

    getNextIndex() {
        let count = this.state.attachments[this.state.open_section].length;
        return (this.state.open_index + count - 1) % count;
    }

    getPrevIndex() {
        let count = this.state.attachments[this.state.open_section].length;
        return (this.state.open_index + 1) % count;
    }

    goToNext() {
        this.setState({open_index: this.getNextIndex()});
    }

    goToPrev() {
        this.setState({open_index: this.getPrevIndex()});
    }

    render() {
        return (
            <div>
                {_.map(this.state.attachments, (attachments, attachment_type) =>
                    <div className="d-inline-block mr-3" key={attachment_type}>
                        <h6>{attachment_type === 'receipt' ? "Receipts" : "Photos"}</h6>

                        {_.map(attachments, (attachment, index) =>
                            attachment.thumbnail !== null ? (
                                <a className="thumbnail" onClick={this.openImage.bind(this, attachment, index)} style={{cursor: "pointer"}} key={attachment.id}>
                                    <img src={attachment.thumbnail} alt="Claim file attachment"/>
                                </a>
                            ) : (
                                <a className="thumbnail" target="_blank" href={attachment.attachment} key={attachment.id}>
                                    <i className="fas fa-file" aria-hidden="true"/>
                                </a>
                            )
                        )}
                    </div>
                )}

                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.getMainSrc()}
                        nextSrc={this.getNextSrc()}
                        prevSrc={this.getPrevSrc()}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={this.goToNext}
                        onMoveNextRequest={this.goToPrev}
                        reactModalStyle={{overlay: {zIndex: 1110}}}
                    />
                )}
            </div>
        );
    }
}
