import React from 'react';
import { Row, Col, Button, FormGroup, Input, UncontrolledTooltip } from 'reactstrap';
import axios from 'axios';
import FileInput from '../forms/file_input';
import Fa from "alpaca.js/dist/util/fa";


export class ReplyBox extends React.Component{
    constructor(props) {
        super(props);
        this.changeFiles = this.changeFiles.bind(this);
        this.addMessage = this.addMessage.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
        this.changeEmailAddr = this.changeEmailAddr.bind(this);
        this.changeCritical = this.changeCritical.bind(this);
        this.addNote = this.addNote.bind(this);
        this.addReply = this.addReply.bind(this);
        this.addSMS = this.addSMS.bind(this);
        this.addMessage = this.addMessage.bind(this);
        this.onMarkAttachment = this.onMarkAttachment.bind(this);
        this.state = {
            files: [],
            message: "",
            critical: false,
            sending_message: false,
            loading: false,
            success_message: null,
            email_address: this.props.last_message && this.props.last_message.from_object? this.props.last_message.from_object.email: "",
        };
    }

    changeFiles(files) {
        this.setState({files: files});
    }

    changeMessage(event) {
        this.setState({message: event.target.value});
    }

    changeEmailAddr(event) {
        this.setState({email_address: event.target.value});
    }

    changeCritical(event) {
        this.setState({critical: event.target.checked});
    }

    cancelNoteOrMessage() {
        if (this.props.changeTab){
            this.props.changeTab("all");
        }
    }

    addMessage(message_type) {
        if (this.state.success_message) {
            this.setState({success_message: null});
        }

        if(this.state.message.trim().length === 0){
            this.setState({error_message: "No message text entered to send"});
            return;
        }

        this.setState({
            sending_message: true,
        });

        let formData = new FormData();
        formData.append('content',this.state.message);
        formData.append('message_type', message_type);
        formData.append('critical', this.state.critical);
        formData.append('channel', this.props.channel);

        if (this.props.reply_email) {
            formData.append('reply_email', this.props.reply_email);
        }

        if (this.state.email_address && this.state.email_address.trim() !== ""){
          formData.append('email_address', this.state.email_address);
        }

        for(let index in this.state.files) {
            formData.append("files", this.state.files[index]);
        }

        axios.post('/api/messages/', formData).then((response) => {
            if (response.status === 201) {
                this.setState({
                    message: "",
                    critical: false,
                    files: [],
                    sending_message: false,
                    success_message: "Message sent"
                });

                this.props.reload();

            } else {
                this.setState({
                    error_message: "Failed to send message",
                    sending_message: false,
                });
            }
        }).catch((err) => {
            this.setState({
                sending_message: false,
            });
        });
    }

    addReply() {
        this.addMessage("email");
    }

    addNote() {
        this.addMessage("note");
    }

    addSMS() {
        this.addMessage("sms");
    }

    onMarkAttachment(message, attachment, mark_as) {
        if (this.state.success_message){
            this.setState({success_message: null});
        }

        axios.patch(
            `/api/messages/${message.id}/attachments/{attachment.id}/`,
            {attachment_type: mark_as}
        ).then((response) => {
            this.setState({success_message: "Attachment sent"});
        });
    }

    render_send_button() {
        let empty_message = this.state.message.trim().length === 0;

        if(this.state.sending_message) {
            return (
                <Button color="primary" className="reply-box-send-btn btn-wide" disabled={true}><Fa icon="spinner" spin/> sending...</Button>
            );
        } else if(this.props.current_tab === 'note') {
            return (
                <Button color="primary" className="reply-box-send-btn btn-wide" onClick={this.addNote}
                        disabled={empty_message}>Add Note</Button>
            );
        } else if (this.props.current_tab === 'sms') {
            return (
                <Button color="primary" className="reply-box-send-btn btn-wide" onClick={this.addSMS}
                        disabled={empty_message}>Send SMS</Button>
            );
        } else if(this.props.can_add_email) {
            return (
                <Button color="primary" className="reply-box-send-btn btn-wide" onClick={this.addReply} disabled={empty_message}>Send Email</Button>
            );
        } else {
            return (
                <div className="d-inline-block">
                    <UncontrolledTooltip target="no_email_send_message_tooltip">
                        No email address on file
                    </UncontrolledTooltip>

                    <div className="d-inline-block" id="no_email_send_message_tooltip"
                         data-toggle="tooltip" aria-disabled="true" aria-hidden="true">
                        <a href="#" className="reply-box-send-btn btn btn-primary disabled btn-wide" disabled>Send Email</a>
                    </div>
                </div>
            );
        }
    }

    render(){
        let replyTitle;
        if (this.props.current_tab === 'note') {
            replyTitle = (<h4>Add Internal Note</h4>);
        } else if (this.props.current_tab === 'email') {
            replyTitle = (<h4>Send Email</h4>);
        } else if (this.props.current_tab === 'sms') {
            replyTitle = (<h4>Send SMS</h4>);
        }

        return (
            <div className="reply-box">
                {replyTitle}

                <FormGroup>
                    {this.props.can_specify_email_addr && this.props.current_tab === 'email' &&
                        <div>
                            <label htmlFor="email_attr">To:</label>
                            <Input type="text" name="email_address" value={this.state.email_address}
                                   onChange={this.changeEmailAddr}/>
                            <br/>
                        </div>
                    }
                    <Input type="textarea" name="text" value={this.state.message} onChange={this.changeMessage}
                           rows={4}/>
                </FormGroup>

                <Row>
                    <Col sm="5">
                        {this.props.current_tab !== 'sms' &&
                            <FileInput onChange={this.changeFiles} files={this.state.files}
                                       fileSizeTotalLimit={1024 * 1024 * 18}>
                                <i className="fa fa-paperclip" aria-hidden="true"/> Add Attachment
                            </FileInput>
                        }
                    </Col>

                    <Col sm="3" className="critical-checkbox text-right">
                        <div className="custom-control custom-checkbox checkbox-danger d-inline-block mr-3">
                            <input type="checkbox" className="custom-control-input"
                                   checked={this.state.critical} onChange={this.changeCritical}
                                   id="id_critical"/>
                            <label className="custom-control-label" htmlFor="id_critical">Critical</label>
                        </div>
                    </Col>

                    <Col sm="4" className="text-right">
                        {this.props.changeTab &&
                            <Button color="primary" className="cancel-btn" outline
                                    onClick={this.cancelNoteOrMessage.bind(this)}
                                    disabled={this.state.sending_message}>
                                Cancel
                            </Button>
                        }
                        &#160;
                        {this.render_send_button()}
                    </Col>
                </Row>
            </div>
        );
    }
}
