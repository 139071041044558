import React from 'react';
import PropTypes from 'prop-types';


export default class Select2 extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const {onSelect} = this.props;

        if (onSelect) {
            onSelect(event);
        }
    }

    initSelect2(s) {
        s.off("change");

        let options = {
            theme: 'bootstrap',
            ajax: this.props.ajax,
        };

        if (this.props.templateResult) {
            options.templateResult = this.props.templateResult;
        }

        if (this.props.templateSelection) {
            options.templateSelection = this.props.templateSelection;
        }

        if (this.props.minimumResultsForSearch) {
            options.minimumResultsForSearch = this.props.minimumResultsForSearch;
        }

        if (this.props.minimumInputLength) {
            options.minimumInputLength = this.props.minimumInputLength;
        }

        s.select2(options);
        s.on("change", this.handleChange);
    }

    componentDidMount() {
        let s = $(this.refs.select);
        this.initSelect2(s);
    }

    componentDidUpdate(prevProps, prevState) {
        let s = $(this.refs.select);

        if (prevProps.ajax !== this.props.ajax || prevProps.value !== this.props.value) {
            s.select2('destroy');

            if (this.props.data.length === 0 && !this.props.value) {
                s.empty();
            }

            this.initSelect2(s);
        }
    }

    renderOption(opt) {
        let id = null;
        let name = null;

        if (opt.constructor === Array) {
            id = opt[0];
            name = opt[1];
        } else {
            id = opt.id;
            name = opt.name;
        }

        return <option key={id} value={id} >{name}</option>;
    }

    render() {
        let {
            data, value, emptyLabel, ajax, placeholder, templateResult, templateSelection, minimumResultsForSearch,
            minimumInputLength, ...rest
        } = this.props;

        return (
            <select
                ref="select"
                value={value !== null ? value : ''}
                data-react-select2
                data-placeholder={this.props.placeholder}
                {...rest}
            >
                {this.props.emptyLabel !== null &&
                <option key='__empty__' value="">{this.props.emptyLabel}</option>
                }
                {this.props.data.map((option) =>
                    this.renderOption(option)
                )}

            </select>
        );
    }
}

Select2.propTypes = {
    data: PropTypes.array,
    onSelect: PropTypes.func,
    emptyLabel: PropTypes.string,
    ajax: PropTypes.object,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    templateResult: PropTypes.func,
    minimumResultsForSearch: PropTypes.number,
    minimumInputLength: PropTypes.number,
    id: PropTypes.string
};

Select2.defaultProps = {
    data: [],
    value: undefined,
    emptyLabel: null,
    ajax: undefined,
    disabled: false,
    placeholder: null,
    name: undefined,
    templateResult: null,
    templateSelection: null,
    minimumResultsForSearch: null,
    minimumInputLength: null,
    id: ""
};
