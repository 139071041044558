import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input} from 'reactstrap';
import classNames from 'classnames';


export default class FieldInput extends React.Component {

    renderInput() {
        let {label, error, ...rest} = this.props;
        if (rest.type === "number" && rest.step === undefined) {
            rest.step = "any";
        }
        return (
            <Input className={classNames({'is-invalid': error})} {...rest} />
        )
    }

    render() {
        return (
            <FormGroup className={classNames({'is-invalid': this.props.error})}>
                {this.props.label &&
                    <Label>{this.props.label}</Label>
                }
                {this.renderInput()}
                {this.props.error &&
                    <span className="invalid-feedback">{this.props.error}</span>
                }
            </FormGroup>
        );
    }
}

FieldInput.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
};

FieldInput.defaultProps = {
    label: '',
    type: 'text',
};
