import React, {Fragment} from 'react';
import Fa from "alpaca.js/dist/util/fa";
import { Modal, ModalBody } from 'reactstrap';


export default class QuoteDetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.toggleDetails = this.toggleDetails.bind(this);

        this.state = {
            detailsOpen: false
        };
    }

    toggleDetails(e) {
        e.preventDefault();

        this.setState({
            detailsOpen: !this.state.detailsOpen
        });
    }

    render() {
        return (
            <div>
                {this.props.quote_status === 'pending' ? (
                  <div>
                      <small><Fa icon="info-circle"/> Quote Requested</small>
                      <br/>
                      <small><a href={`/tickets/${this.props.ticket_id}/retailers/${this.props.retailer_id}/`}>Messaging</a></small>
                  </div>
                ) : (
                    <Fragment>
                        <small><a href="#" onClick={this.toggleDetails}>Details</a></small>

                        <Modal placement="bottom" isOpen={this.state.detailsOpen} toggle={this.toggleDetails}>
                            <div className="modal-header pb-0">
                                <div className="modal-title">
                                    <h3 className="modal-title">Quote Details</h3>
                                </div>
                                <button type="button" className="close" aria-label="Close" onClick={this.toggleDetails}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <ModalBody>
                                {this.props.children}
                            </ModalBody>
                        </Modal>
                    </Fragment>
                )}
            </div>
        )
    }
}
