import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Fa from "alpaca.js/dist/util/fa";
import { currency } from '../../util';


export class ExaminationOption extends React.Component {

    selectOption(examination_claim_id, option_name, event) {
        this.props.selectOption(examination_claim_id, option_name, event);
    }

    renderCheckbox() {
        let selected_option = this.props.selected_option;
        let option_name = this.props.option_name;
        let examination_claim = this.props.examination_claim;
        let alternate_flow = _.get(examination_claim, 'claim.plan.retailer.alternate_flow');

        let option = examination_claim.options[option_name];
        let checkbox_name = `option_checkbox_${examination_claim.id}_${option_name}`;

        let amount_display = <small className="text-muted">unknown</small>;
        if (alternate_flow === 'rtg'){
            amount_display = <small className="text-muted">{examination_claim.options[option_name].option_type_name}</small>;
        }
        if(!this.isAvailable()) {
            amount_display = <strong>N/A</strong>;
        } else if(option.amount) {
            // if (option_name === 'repair' && this.props.is_second_repair) {
            //     amount_display = <strong>$0</strong>;
            // } else {
                amount_display = <strong>{currency(option.amount)}</strong>;
            // }
        }

        return (
            <div className="custom-control custom-checkbox">
                {!(this.isDisabled() || this.isSelected()) &&
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        onChange={this.selectOption.bind(this, examination_claim.id, option_name)}
                        checked={selected_option === option_name}
                        id={checkbox_name}/>
                }

                <label className="custom-control-label" htmlFor={checkbox_name}>
                    {amount_display}
                </label>
            </div>
        )
    }

    isSelected() {
        return this.props.examination_claim.selected === this.props.option_name;
    }

    isDisabled() {
        return (this.props.examination_claim.selected !== null && !this.isSelected()) || !this.isAvailable();
    }

    isAvailable() {
        let option = this.props.examination_claim.options[this.props.option_name];
        return option.available;
    }

    renderExtra() {
        return null;
    }

    render() {
        let option_name = this.props.option_name;
        let examination_claim = this.props.examination_claim;

        // for now we don't have recommendations, so everything was the "recommended" option.
        // we may change this once we have recommendations
        let recommended = this.isSelected(); //examination_claim.recommendation === option_name;

        return (
            <div className={classNames("examination-option", {"highlighted": recommended}, {"selected": this.isSelected()}, {"disabled": this.isDisabled()})}>
                {this.renderCheckbox()}

                {this.renderExtra()}
                {this.props.children}

                {this.isSelected() ? (
                    <span>
                        <Fa icon="check" size={1}/>&#160;
                        <small className="d-inline-block">
                            <strong>Chosen {!recommended && "(Override)"}</strong>
                        </small>
                    </span>
                ) : (
                    recommended && (
                        <small><strong className="d-inline-block">Recommended</strong></small>
                    )
                )}
            </div>
        )
    }
}

ExaminationOption.propTypes = {
    option_name: PropTypes.string.isRequired,
    examination_claim: PropTypes.object.isRequired,
    selected_option: PropTypes.string,
    is_second_repair: PropTypes.bool.isRequired,
    selectOption: PropTypes.func.isRequired,
    selected_vendor: PropTypes.object
};
