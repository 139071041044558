import React from 'react';
import Fa from "alpaca.js/dist/util/fa";
import {ReplyBox} from "./replybox";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";


export class ReplyBoxPopup extends React.Component{
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle className="btn-link btn-xs" title="Reply" color={null}>
                    <Fa icon="reply"/>
                </DropdownToggle>

                <DropdownMenu right className="reply-box-dropdown">
                    <h4 className="pt-3 pl-4 pb-2">Send Email</h4>
                    <ReplyBox {...this.props} />
                </DropdownMenu>
            </Dropdown>
        );
    }
}
