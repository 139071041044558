import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";


export class RestartExaminationButton extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.doRestart = this.doRestart.bind(this);

        this.state = {
            modal_opened: false,
            restarting: false,
        }
    }

    toggleModal(e) {
        if (this.state.restarting) {
            return;
        }

        if (e) {
            e.preventDefault();
        }

        this.setState({modal_opened: !this.state.modal_opened});
    }

    doRestart() {
        this.setState({restarting: true});

        axios.post(`/api/examination/${this.props.examination}/restart/`).then((response) => {
            document.location = `/examination/${this.props.examination}/`;
        });
    }

    renderModal() {
        let dialog_content;
        if (this.props.can_restart) {
            dialog_content = (
                <div>
                    <ModalBody>
                        <hr/>
                        <p>Are you sure you want to restart this examination?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.restarting}
                                className="btn-wide">No, Cancel</Button>
                        <Button color="primary" onClick={this.doRestart} disabled={this.state.restarting}
                                className="btn-wide">
                            {this.state.restarting ? (
                                <span><Fa icon="spinner" spin/> Restarting</span>
                            ) : (
                                "Yes, Restart"
                            )}
                        </Button>
                    </ModalFooter>
                </div>);
        } else {
            dialog_content = (
                <div>
                    <ModalBody>
                        <hr/>
                        <p>Current Resolution status does not allow a restart of examination. Please take appropriate
                            action
                            for
                            resolution to restart the examination.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.restarting}
                                className="btn-wide">Close</Button>
                    </ModalFooter>
                </div>);
        }
        return (
            <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal}
                   backdrop={this.state.restarting ? "static" : true}>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">Confirm Restart</h3>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.toggleModal}
                            disabled={this.state.restarting}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                {dialog_content}
            </Modal>
        );
    }

    render() {

        return (
            <span>
                <Button color="danger" outline onClick={this.toggleModal} className="mt-2">Restart Examination</Button>

                {this.renderModal()}
            </span>
        )
    }
}

RestartExaminationButton.propTypes = {
    examination: PropTypes.number.isRequired,
};
