import React from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import classNames from "classnames";
import MultiAttachmentInput from "../forms/multi_attachment_input";


export class ClaimDocumentation extends React.Component {
    constructor(props) {
        super(props);
        this.handlePhotoAttachmentChange = this.handlePhotoAttachmentChange.bind(this);
        this.handleReceiptAttachmentChange = this.handleReceiptAttachmentChange.bind(this);
        this.handleTicketAttachmentSelect = this.handleTicketAttachmentSelect.bind(this);

        var ticket_attachments_selected = [];
        var receipt_attachments = [];
        var photo_attachments = [];

        for(let x in this.props.attachments) {
            let attachment = this.props.attachments[x];

            let found = false;
            for(let i in this.props.ticket_attachments) {
                if(this.props.ticket_attachments[i].id === attachment.id) {
                    found = true;
                    break;
                }
            }

            if(found) {
                ticket_attachments_selected.push(attachment.id);
            } else if(attachment.attachment_type === 'receipt') {
                receipt_attachments.push(attachment);
            } else if(attachment.attachment_type === 'image') {
                photo_attachments.push(attachment);
            }
        }

        this.state = {
            receipt_attachments: receipt_attachments,
            photo_attachments: photo_attachments,
            ticket_attachments_selected: ticket_attachments_selected,
        };
    }

    renderImageCheckbox(attachment, index, prefix) {
        let checked = this.state.ticket_attachments_selected.indexOf(attachment.id) >= 0;

        return (
            <div className={classNames("custom-control", "custom-checkbox", "image-checkbox")} key={index}>
                <img src={attachment.thumbnail} alt={attachment.filename} title={attachment.filename} />
                <input type="checkbox" name="attachments" id={prefix + index} className={classNames("custom-control-input")} value={attachment.id} checked={checked} onChange={this.handleTicketAttachmentSelect}/>
                <label className="custom-control-label" htmlFor={prefix + index}>&#160;</label>
            </div>
        )
    }

    handleTicketAttachmentSelect(event) {
        let value = parseInt(event.target.value);

        if(event.target.checked) {
            this.setState({
                ticket_attachments_selected: this.state.ticket_attachments_selected.concat([value])
            });
        } else {
            this.setState({
                ticket_attachments_selected: this.state.ticket_attachments_selected.filter(x => x !== value)
            });
        }
    }

    handlePhotoAttachmentChange(attachments) {
        this.setState({photo_attachments: attachments});
    }

    handleReceiptAttachmentChange(attachments) {
        this.setState({receipt_attachments: attachments});
    }

    render() {
        var ticket_receipts = this.props.ticket_attachments.filter(x => x.attachment_type === 'receipt');
        var ticket_photos = this.props.ticket_attachments.filter(x => x.attachment_type === 'image');

        var all_attachments = this.state.ticket_attachments_selected;
        all_attachments = all_attachments.concat(this.state.photo_attachments.map(x => x.id));
        all_attachments = all_attachments.concat(this.state.receipt_attachments.map(x => x.id));

        return (
            <Row>
                <Col md={6}>
                    <Card color="light" style={{minHeight: 300}}>
                        <CardBody>
                            <h4>Select from Ticket</h4>
                            <h6>Receipts</h6>
                            {ticket_receipts.length > 0 ? (
                                ticket_receipts.map((receipt, index) =>
                                    this.renderImageCheckbox(receipt, index, "receipt")
                                )
                            ) : (
                                <div className="mb-2"><small><i>No receipts found</i></small></div>
                            )}
                            <h6>Photos</h6>
                            {ticket_photos.length > 0 ? (
                                ticket_photos.map((photo, index) =>
                                    this.renderImageCheckbox(photo, index, "photo")
                                )
                            ) : (
                                <small><i>No photos found</i></small>
                            )}

                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card color="light" style={{minHeight: 300}}>
                        <CardBody>
                            <h4>Upload</h4>
                            <FormGroup>
                                <Label>Receipts</Label>
                                <MultiAttachmentInput
                                    placeholder="Browse to select receipts"
                                    attachment_type="receipt"
                                    onChange={this.handleReceiptAttachmentChange}
                                    attachments={this.state.receipt_attachments}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Photos</Label>
                                <MultiAttachmentInput
                                    placeholder="Browse to select photos"
                                    attachment_type="image"
                                    onChange={this.handlePhotoAttachmentChange}
                                    attachments={this.state.photo_attachments} />
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
                <input type="hidden" value={JSON.stringify(all_attachments)} name="claim_details_json"/>
            </Row>
        );
    }
}
