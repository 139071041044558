import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import classNames from 'classnames';
import { FormGroup } from 'reactstrap';


export default class CheckBoxInput extends React.Component {
    constructor(props) {
        super(props);

        const id = uniqueId("checkbox-");
        this.state = {id: id};
    }

    render() {
        let { error, light, pullRight, pullLeft, label, bold, children, ...rest } = this.props;

        let control_wrapper_class = classNames(
            "custom-control", "custom-checkbox", {"custom-control-light": light}, {"pull-right": pullRight}, {"pull-left": pullLeft}
        );
        let input_class = classNames("custom-control-input", {'is-invalid': error});
        let label_class = classNames("custom-control-label", {"font-weight-bold": bold});

        return (
            <FormGroup className={classNames({'is-invalid': error})}>
                <div className={control_wrapper_class}>
                    <input type="checkbox" name="select_checkboxes" id={this.state.id} {...rest} className={input_class} />
                    <label className={label_class} htmlFor={this.state.id}>
                        {children !== undefined ? children : label}
                    </label>
                </div>

                {error && <span className="invalid-feedback">{error}</span>}
            </FormGroup>
        );
    }
}

CheckBoxInput.propTypes = {
    label: PropTypes.string,
    light: PropTypes.bool,
    bold: PropTypes.bool
};

CheckBoxInput.defaultProps = {
    label: '',
    light: false,
    bold: false,
};
