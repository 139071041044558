import React, {Fragment} from 'react';
import {Alert, Button, Card, CardBody, Row, Col} from 'reactstrap';
import CheckBoxInput from '../forms/checkbox_input';
import FieldInput from '../forms/field_input';
import SelectInput from '../forms/select_input';
import linkState from '../link_state';
import {setIn, getIn} from 'immutable-setter';
import axios from 'axios';
import Fa from "alpaca.js/dist/util/fa";
import {StartExaminationButton} from "../examination/start_examination_button";

export class ClaimDecision extends React.Component {
    constructor(props) {
        super(props);
        this.loadDecision = this.loadDecision.bind(this);
        this.doConfirm = this.doConfirm.bind(this);
        this.doRetry = this.doRetry.bind(this);
        this.doConfirmTryAlternate = this.doConfirmTryAlternate.bind(this);
        this.load_timeout = null;

        this.state = {
            decision: {},
            decision_ready: false,
            confirming: false,
            loading: true,
        };
    }

    componentDidMount() {
        this.loadDecision();
    }

    componentWillUnmount() {
        clearTimeout(this.load_timeout);
    }

    retry_decision_with_delay() {
        // when decision isn't ready, try again after a delay
        this.setState({
            loading: false,
            decision_ready: false
        });

        this.load_timeout = setTimeout(() => {
            this.loadDecision();
        }, 1500);
    }

    canConfirm() {
        if (this.state.confirming) {
            return false;
        }  // disable button after it's clicked
        if (!this.state.decision.override && this.state.decision.recommendation !== 'unknown') {
            return true;  // allow to confirm when not overriding and decision engine rec. is allow or deny
        } else {
            // if overriding, require decision AND reason to confirm
            return this.state.decision.override_decision !== '' && _.trim(this.state.decision.override_reason) !== '';
        }

    }

    loadDecision() {

        axios.get('/api/claims/' + this.props.claim_id + '/decision/')
            .then((response) => {
                if (response.data.response_status === '') {
                    // decision exists, but doesn't have a response from the decision engine
                    this.retry_decision_with_delay();
                } else {
                    let decision = response.data;

                    this.setState({
                        decision: decision,
                        loading: false,
                        decision_ready: true
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    this.retry_decision_with_delay();
                }
            });
    }

    doRetry(e) {
        // Clear the current decision, tell the server to resubmit to decision engine, then load new decision
        e.preventDefault();
        this.setState({
            decision: {},
            decision_ready: false
        });
        axios.post('/api/claims/' + this.props.claim_id + '/retry-decision/')
            .then((response) => {
                this.loadDecision();
            });

    }

    doConfirm(e) {
        e.preventDefault();

        if (!this.canConfirm()) {
            return;
        }

        this.setState({confirming: true});

        let params = {
            decision: this.state.decision
        };

        axios.patch(`/api/claims/${this.props.claim_id}/decision/`, params)
            .then((response) => {
                this.setState({
                    confirming: false,
                    decision: response.data
                });
            })
            .catch((response) => {
                this.setState({
                    confirming: false,
                });
            });
    }

    doConfirmTryAlternate(e) {
        e.preventDefault();
        if (this.state.confirming) {
            return false;
        }
        this.setState({
            confirming: true,
        });

        let params = {
            decision: JSON.parse(JSON.stringify(this.state.decision))
        };
        params.decision.alternate_plan_decision_confirmed = true;

        axios.patch(`/api/claims/${this.props.claim_id}/decision/`, params)
            .then((response) => {
                let decision = response.data;
                if (!decision.alternate_plan_decision_confirmed) {
                    alert("Unable to create alternate plan.");
                }
                this.setState({
                    confirming: false,
                    decision: decision
                });
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
                if (error.response.status === 404) {
                    this.retry_decision_with_delay();
                }
                if (error.response.status == 400) {
                    let message = "An Error Occurred\n";
                    let errors = error.response.data.errors;
                    try {
                        for (let [k,v] of Object.entries(errors)) {
                            message += `\n${k}: ${v}`;
                        }
                    }
                    catch(err) {console.log(err)}
                    finally {
                        alert(message);
                    }
                }
                this.setState({
                    confirming: false,
                });
            });
    }


    getError(paths) {
        if (this.props.errors !== undefined) {
            return getIn(this.props.errors, paths);
        }

        return null;
    }

    getRecommendationText(recommendation) {
        if (recommendation === 'approve') {
            return 'Approve Coverage';
        } else if (recommendation === 'deny') {
            return 'Deny Coverage';
        } else {
            return 'Unable to Provide Recommendation';
        }
    }

    getBannerColor(recommendation) {
        if (recommendation === 'approve') {
            return 'success';
        } else if (recommendation === 'deny') {
            return 'danger';
        } else {
            return 'info';
        }
    }

    renderHeaderText(accepted) {
        let headerText = 'Decision';
        if (!accepted) {
            headerText += ' Required';
        }

        return (
            <h4>{headerText}</h4>
        );
    }

    getOverrideChoices(recommendation) {
        let approve = ['approve', 'Approve'];
        let deny = ['deny', 'Deny'];

        if (recommendation === 'approve') {
            return [deny];
        }

        if (recommendation === 'deny') {
            return [approve]
        }

        return [approve, deny];
    }

    renderBackButtons(decision) {
        let recommendation = decision.override ? decision.override_decision : decision.recommendation;

        return (
            <div className="text-center mt-4">
                <a href={`/tickets/${decision.ticket}/`} className="btn btn-outline-primary">Back to
                    Ticket</a>&#160;&#160;

                {(decision.examination !== null && decision.examination !== undefined) ? (
                    this.props.can_change_examination && (
                        <a href={`/examination/${decision.examination}/`} className="btn btn-primary">View
                            Examination</a>
                    )
                ) : (
                    (this.props.can_start_examination && recommendation === 'approve') && (
                        <StartExaminationButton claim_id={this.props.claim_id}/>
                    )
                )}
            </div>
        );
    }

    renderBanner(decision) {
        if (!this.state.decision_ready) {
            return (
                <Alert color="info" className="alert-large">Waiting for decision recommendation</Alert>
            );
        }

        // Saved decision with an override
        if (decision.accepted && decision.override) {

            return (
                <div>
                    <Alert color="info" className="alert-large">
                        <span
                            className="dark">Recommendation:</span> {this.getRecommendationText(decision.recommendation)}
                    </Alert>
                    <Alert color={this.getBannerColor(decision.override_decision)} className="alert-large">
                        <span className="dark">Override:</span> {this.getRecommendationText(decision.override_decision)}
                    </Alert>

                    {this.renderReasons()}

                    <p><strong>Override Reason:</strong> {decision.override_reason}</p>

                    {this.renderAlternate(decision)}
                    {this.renderBackButtons(decision)}
                </div>
            );
        }

        if (decision.response_status === 'failed') {
            return (
                <Alert color="warning" className="alert-large">
                    <Fa icon='exclamation-triangle'/> Error Retrieving Recommendation
                    <Button color="warning" outline={true} className="ml-2" onClick={this.doRetry}>Retry</Button>
                </Alert>
            );
        }

        // No override, but decision may or may not be saved, only difference is "Recommendation:" prefix text
        let prefix = '';
        if (!decision.accepted) {
            prefix = <span className="dark">Recommendation:</span>;
        }

        return (
            <div>
                <Alert color={this.getBannerColor(decision.recommendation)} className="alert-large">
                    {prefix} {this.getRecommendationText(decision.recommendation)}
                </Alert>

                {decision.accepted && this.renderReasons()}
                {this.renderAlternate(decision)}
                {decision.accepted && this.renderBackButtons(decision)}
            </div>
        );

    }

    renderReasons() {
        let claim_reasons = this.state.decision.claim_recommendation_reasons;
        let plan_reasons = this.state.decision.plan_recommendation_reasons;

        if (!_.isEmpty(claim_reasons) || !_.isEmpty(plan_reasons)) {
            return (
                <div>
                    <strong>Recommendation Reason:</strong>
                    {plan_reasons.map((reason, i) => <div className="plan_reason" key={i}>{reason}</div>)}
                    {claim_reasons.map((reason, i) => <div className="claim_reason" key={i}>{reason}</div>)}
                </div>
            );
        }
    }

    renderAlternateOptions(decision) {
        console.log(decision);
        if (decision.alternate_plan_decision_confirmed) {
            if (decision.alternate_plan_tried) {
                return (
                    <Fragment>
                        <Alert color="success" className="alert-large">
                            <span className="dark">Alternate Decision Plan Chosen</span>
                        </Alert>
                        <h3><a href={decision.alternate_ticket_url}>Ticket# {decision.ticket}</a></h3>
                    </Fragment>
                )
            } else {
                return (
                    <Alert color="info" className="alert-large">
                            <span
                                className="dark">Alternate Decision Plan Not Chosen</span>
                    </Alert>
                )
            }
        } else {
            if (decision.can_try_alternate_plan) {
                return (
                    <Fragment>
                        <Alert color="success" className="alert-large">
                            <span className="dark">Alternate Decision Plan Available!</span>
                        </Alert>
                        <Row>
                            <Col sm={5}>
                            </Col>
                            <Col sm={2}>
                                <CheckBoxInput
                                    label="Try Alternate Plan"
                                    onChange={linkState(this, 'decision', ['try_alternate'])}
                                    checked={decision.try_alternate}
                                    value={true}
                                    pullRight={true}
                                />
                            </Col>
                            <Col sm={5}></Col>
                        </Row>
                        <Row>
                            <Col sm={5}></Col>
                            <Col sm={2}>
                                <Button color="primary" onClick={this.doConfirmTryAlternate} style={{minWidth: 135}}
                                        disabled={this.state.confirming}
                                >
                                    Confirm{this.state.confirming ? (
                                    <Fa style={{marginLeft: "6px"}} icon="spinner" spin/>) : null}
                                </Button>
                            </Col>
                            <Col sm={5}></Col>
                        </Row>
                    </Fragment>
                )
            } else {
                return null
            }
        }
    }

    renderAlternate(decision) {
        let plan_product = this.props.plan_product;
        if (decision.can_try_alternate_plan || decision.alternate_plan_decision_confirmed) {
            return (<Row>
                <Col sm={12}>
                    &nbsp;
                </Col>
                <Col sm="12">
                    <div>
                        {this.renderAlternateOptions(decision)}
                    </div>
                </Col>
            </Row>)
        } else {
            return null;
        }
    }

    renderOverride(decision) {
        // can't' override a decision once it's been accepted
        if (decision.accepted) {
            return null;
        }

        return (
            <div>
                <Row>
                    <Col sm="8">
                        {this.renderReasons()}
                    </Col>
                    <Col sm="4">
                        <CheckBoxInput
                            label="Override"
                            onChange={linkState(this, 'decision', ['override'])}
                            checked={decision.override}
                            pullRight={true}
                        />
                    </Col>
                </Row>
                {this.renderOverrideDetails(decision)}
            </div>
        )
    }

    renderOverrideDetails(decision) {
        // only need to show details if override is selected
        if (!decision.override) {
            return null;
        }

        return (
            <form onSubmit={this.doConfirm}>
                <Row className="mt-3">
                    <Col sm={{size: 8, offset: 2}}>
                        <SelectInput
                            label="Decision"
                            onChange={linkState(this, 'decision', ['override_decision'])}
                            value={decision.override_decision}
                            error={this.getError(['override_decision'])}
                            choices={this.getOverrideChoices(decision.recommendation)}/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={{size: 8, offset: 2}}>
                        <FieldInput
                            label="Reason"
                            onChange={linkState(this, 'decision', ['override_reason'])}
                            value={decision.override_reason}
                            error={this.getError(['override_reason'])}/>
                    </Col>
                </Row>
            </form>
        );
    }

    renderConfirm(decision) {
        if (decision.accepted) {
            return null;
        }

        let can_confirm = this.canConfirm();

        return (
            <Row className="mt-3">
                <Col sm={5} className="pt-1">
                    <CheckBoxInput
                        label="Send Eligibility Determination Notice for this claim"
                        onChange={linkState(this, 'decision', ['send_email'])}
                        checked={decision.send_email}
                    />
                </Col>
                <Col sm={2} className='text-center'>
                    <Button color="primary" onClick={this.doConfirm} style={{minWidth: 135}} disabled={!can_confirm}>
                        Confirm{this.state.confirming ? (<Fa style={{marginLeft: "6px"}} icon="spinner" spin/>) : null}
                    </Button>
                </Col>
            </Row>
        )
    }

    render() {
        if (this.state.loading) return null;

        if (!this.state.decision_ready) {
            return (
                <Card className="mb-4">
                    <CardBody>
                        {this.renderHeaderText(false)}
                        <Alert color="info" className="alert-large">Waiting for decision</Alert>
                    </CardBody>
                </Card>
            )
        }

        let decision = this.state.decision;

        return (
            <Card className="mb-4">
                <CardBody>
                    {this.renderHeaderText(decision.accepted)}
                    {this.renderBanner(decision)}
                    {/*{this.state.decision.can_confirm_decision &&*/}
                    <div>
                        {this.renderOverride(decision)}
                        {this.renderConfirm(decision)}
                    </div>
                    {/*}*/}
                </CardBody>
            </Card>
        );
    }
}
