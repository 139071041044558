import React, {Component} from 'react';
import {Scope, ArrayField, Text} from 'informed';
import BulkUploadErrorList from "alpaca.js/dist/bulk_upload/bulkUploadErrorList";
import {BootstrapText} from "alpaca.js/dist/autoform/bootstrap";
import {Button} from "reactstrap";
import Fa from "alpaca.js/dist/util/fa";
import {currency} from "alpaca.js/dist/util/util";

const COLUMN_MAPPINGS = {
    examination_resolution_id: "Auth Number",
    payment_number: "Payment Number",
    payment_date: "Payment Date",
    payment_type: "Payment Type",
};

export default class InvoiceUploadErrors extends Component {
    render() {
        return  (
            <div className="container">
                <BulkUploadErrorList
                    url="/api/invoices/bulk/"
                    columns={COLUMN_MAPPINGS}
                    onUpload={() => {document.location = '/invoices/'}}
                    readOnlyColumns={["authorized_amount", "customer_name"]}
                    columnFormatters={{authorized_amount: currency}} />
            </div>
        )
    }
}
