import React from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import DuplicateContact from "./duplicate_contact";
import Fa from "alpaca.js/dist/util/fa";


export default class DuplicateContactMatch extends React.Component {
    constructor(props) {
        super(props);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleMergeSelected = this.handleMergeSelected.bind(this);
        this.handleMerge = this.handleMerge.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.onMarkPrimary = this.onMarkPrimary.bind(this);

        this.state = {
            selected_contacts: [],
            primary_contact: this.props.contact_match.contacts[0].id,
            dismissed: false,
            merged_contact: null,
            loading: false,
            merging_selected: false,
            merging: false
        };
    }

    handleDismiss(e) {
        this.setState({loading: true});
        axios.post('/api/contacts-duplicates/' + this.props.contact_match.id + '/ignore/').then((response) => {
            this.setState({
                dismissed: true,
                loading: false,
            });
        });
    }

    handleMergeSelected(e) {
        if(this.state.selected_contacts.length <= 1){
            return;
        } else if(this.state.selected_contacts.indexOf(this.state.primary_contact) < 0) {
            alert("Please choose one of the selected records as the primary version.");
            return;
        }

        this.setState({merging_selected: true});

        this.doMerge(this.state.primary_contact, this.state.selected_contacts);
    }

    handleMerge(e) {
        this.setState({merging: true});

        this.doMerge(this.state.primary_contact, this.props.contact_match.contacts.map(x => x.id));
    }

    doMerge(primary_contact, contacts) {
        this.setState({loading: true});

        let params = {
            contacts: contacts,
            primary_contact: primary_contact,
        };

        axios.post('/api/contacts-duplicates/' + this.props.contact_match.id + '/merge/', params).then((response) => {
            this.setState({
                merged_contact: response.data,
                loading: false,
                merging_selected: false,
                merging: false
            });
        });
    }

    onSelected(e, contact_id) {
        if(e.target.checked) {
            this.setState({selected_contacts: this.state.selected_contacts.concat(contact_id)});
        } else {
            this.setState({selected_contacts: this.state.selected_contacts.filter(item => item !== contact_id)});
        }
    }

    onMarkPrimary(e, contact_id) {
        if(e.target.checked) {
            this.setState({primary_contact: contact_id});
        }
    }

    render() {
        let contact_match = this.props.contact_match;

        if(this.state.dismissed) {
            return null;
        }

        return (

            <Card className="mb-3">
                <CardBody className="pb-0">
                    <Row>
                        <Col sm="2"><h4>{this.props.index}</h4></Col>
                        {this.state.merged_contact === null ? (
                            <Col className="text-right" sm="10">
                                <Button outline color="primary" onClick={this.handleDismiss} className="mb-3" disabled={this.state.loading}>Dismiss</Button>&#160;
                                <Button outline color="primary" disabled={this.state.selected_contacts.length <= 1 || this.state.loading} onClick={this.handleMergeSelected} className="mb-3">
                                    {this.state.merging_selected ? (
                                        <span><Fa icon="spinner" spin /> Merging...</span>
                                    ) : (
                                        <span><Fa icon="check" /> Merge Selected</span>
                                    )}
                                </Button>&#160;
                                <Button color="primary" onClick={this.handleMerge} className="mb-3" disabled={this.state.loading || this.state.selected_contacts.length > 0}>
                                    {this.state.merging ? (
                                        <span><Fa icon="spinner" spin /> Merging...</span>
                                    ) : (
                                        <span><Fa icon="check" /> Merge</span>
                                    )}
                                </Button>
                            </Col>
                        ) : (
                            <Col className="text-right" sm="10">
                                <strong className="text-success" style={{verticalAlign: 5}}><Fa icon="check"/> Merged</strong>&#160;&#160;&#160;
                                <Button outline color="primary" href={this.state.merged_contact.url} target="_blank" className="mb-3">View Contact</Button>&#160;
                            </Col>
                        )}
                    </Row>

                    <Row>
                        {contact_match.contacts.map((contact) =>
                            <Col key={contact.id} xs={12} md={6}>
                                <DuplicateContact
                                    contact={contact}
                                    contact_match={contact_match}
                                    primary={this.state.primary_contact === contact.id}
                                    selected={this.state.selected_contacts.indexOf(contact.id) >= 0}
                                    disabled={this.state.merged_contact && this.state.selected_contacts.length > 0 && this.state.selected_contacts.indexOf(contact.id) < 0}
                                    merged={this.state.merged_contact != null}
                                    onSelected={this.onSelected}
                                    onMarkPrimary={this.onMarkPrimary}
                                />
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        );
    }
}
