import React from 'react';
import { Row, Col } from 'reactstrap';
import { capitalize } from "../util";
import classNames from 'classnames';
import { get_primary_contact_item, get_shipping_address } from './util';


export default class DuplicateContact extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let contact = this.props.contact;
        let phone = get_primary_contact_item(contact.phones);
        let email = get_primary_contact_item(contact.emails);
        let address = get_shipping_address(contact.addresses);

        return (
            <div className={classNames("contact-match", {"contact-match-disabled": this.props.disabled})}>
                <Row>
                    <Col xs={4} lg={3} xl={2}>
                        <div className="custom-control custom-checkbox">
                            <input
                                id={`id_select_${contact.id}`}
                                type="checkbox"
                                className="custom-control-input"
                                checked={this.props.selected}
                                onChange={(e) => { this.props.onSelected(e, contact.id); }}
                                disabled={this.props.merged} />
                            <label className="custom-control-label" htmlFor={`id_select_${contact.id}`}>&#160;</label>
                        </div>
                    </Col>
                    <Col className="text-right">
                        <div className="custom-control custom-radio d-inline-block">
                            <input
                                id={`id_mark_primary_${contact.id}`}
                                type="radio"
                                name={"mark_primary_" + this.props.contact_match.id}
                                value={contact.id}
                                className="custom-control-input"
                                checked={this.props.primary} onChange={(e) => { this.props.onMarkPrimary(e, contact.id); }}
                                disabled={this.props.merged} />
                            <label
                                className="custom-control-label"
                                htmlFor={`id_mark_primary_${contact.id}`}>
                                <i>Mark version as primary</i>
                            </label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} lg={3} xl={2}><strong>Name</strong></Col>
                    <Col><a href={`/contacts/${contact.id}/`} target="_blank">{contact.name}</a></Col>
                </Row>
                <Row>
                    <Col xs={4} lg={3} xl={2}><strong>Phone</strong></Col>
                    <Col>{phone !== null ? phone.phone : ''}</Col>
                    <Col sm={2} className="text-right text-muted">{phone !== null ? capitalize(phone.phone_type) : ''}</Col>
                </Row>
                <Row>
                    <Col xs={4} lg={3} xl={2}><strong>Email</strong></Col>
                    <Col>
                        {email !== null && (
                            <a href={"mailto:" + email.email}>{email.email}</a>
                        )}
                    </Col>
                    <Col sm={2} className="text-right text-muted">{email !== null ? capitalize(email.email_type) : ''}</Col>
                </Row>
                <Row>
                    <Col xs={4} lg={3} xl={2}><strong>Address</strong></Col>
                    <Col>{address !== null ? address.full_address : ''}</Col>
                    <Col sm={2} className="text-right text-muted">{address !== null ? capitalize(address.address_type) : ''}</Col>
                </Row>
            </div>
        );
    }
}
