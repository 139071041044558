import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Fa from "alpaca.js/dist/util/fa";

import CollapsibleCard from "../collapsible_card";
import {ManualResolutionButton} from "./manual_resolution_button";
import {Button} from "reactstrap";


export const ExaminationOtherOptionsPanel = (props) => {
    return (
        <CollapsibleCard header="Additional Examination Options" expanded={props.expanded}>
            {props.examination == null ? (
                <div><Fa icon="spinner" spin/> Loading additional options</div>
            ): (
                <Fragment>
                    {props.examination.service_group === null &&
                        <ManualResolutionButton
                            examination={props.examination}
                            manual_reasons={props.manual_reasons} />
                    }

                    {props.can_request_inspection &&
                        <Button
                            size="xs"
                            color="primary"
                            outline
                            onClick={() => {
                                document.location = `/examination/${props.examination.id}/select-vendor/inspection/`
                            }}>
                            Request Inspection
                        </Button>
                    }
                </Fragment>
            )}
        </CollapsibleCard>
    );
};

ExaminationOtherOptionsPanel.propTypes = {
    examination: PropTypes.object,
    expanded: PropTypes.bool,
    manual_reasons: PropTypes.array
};

ExaminationOtherOptionsPanel.defaultProps = {
    expanded: true,
};
