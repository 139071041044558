import React, {Fragment} from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import moment from 'moment';
import {capitalize, get_first_letter} from './util';
import classNames from 'classnames';
import { observer } from "mobx-react";
import { reaction } from 'mobx';

import { observablePageStore } from './store';


export const CallWidget = observer(class CallWidget extends React.Component {
    constructor(props) {
        super(props);
        this.handleWebsocketMessage = this.handleWebsocketMessage.bind(this);
        this.handleWebsocketOnOpen = this.handleWebsocketOnOpen.bind(this);
        this.handleWebsocketOnClose = this.handleWebsocketOnClose.bind(this);
        this.startWebsocket = this.startWebsocket.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);

        this.default_data = {
            status: 'waiting',
            from: null,
            to: null,
            interactions: [],
            message: null,
        };

        this.state = {
            data: this.default_data,
            opened: false,
            loading: true,
        };
    }

    componentDidMount() {
        this.retries = 0;

        if(observablePageStore.callWidgetOpen) {
            this.startWebsocket();
        }

        reaction(
            () => observablePageStore.callWidgetOpen,
            callWidgetOpen => {
                if(callWidgetOpen) {
                    this.retries = 0;
                    this.startWebsocket();
                } else {
                    this.retries = 500;
                    this.socket.close();
                }
            }
        );
    }

    startWebsocket() {
        let protocol = "ws://";
        if (location.protocol === "https:") {
            protocol = "wss://";
        }

        this.socket = new WebSocket(protocol + window.location.host + "/ws/call-status/");
        this.socket.onmessage = this.handleWebsocketMessage;
        this.socket.onopen = this.handleWebsocketOnOpen;
        this.socket.onclose = this.handleWebsocketOnClose;
    }

    handleWebsocketMessage(e) {
        let data = JSON.parse(e.data);
        this.setState({data: data});
    }

    handleWebsocketOnOpen(e) {
        this.setState({loading: false});
        this.retries = 0;
    }

    handleWebsocketOnClose(e) {
        this.setState({loading: true, data: this.default_data});

        if (this.retries < 10) {
            setTimeout(this.startWebsocket, 1000 * this.retries);
            this.retries++;
        }
    }

    getStatusClass() {
        return 'call-widget-status-' + this.state.data.status;
    }

    toggleOpen() {
        this.setState({ opened: !this.state.opened });
    }

    renderHeader() {
        let data = this.state.data;

        return (
            <div className="call-widget-header">
                {data.status !== 'waiting' &&
                    <a className="call-widget-close" onClick={this.toggleOpen}/>
                }
                <div className="call-widget-header-main">
                    {this.renderInnerHeader()}
                    {data.status !== 'waiting' &&
                        <div className="call-widget-header-bottom">
                            {data.to && data.to.name &&
                                <h6>{data.to.name}</h6>
                            }
                            {data.to && data.to.number &&
                                <h5 className="pull-right">{data.to.number}</h5>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderInnerHeader() {
        let data = this.state.data;

        let contact_link = null;
        let TagName = 'div';
        if(data.from && data.from.url){
            contact_link = data.from.url;
            TagName = 'a'
        }
        return (
            <TagName className="call-widget-header-inner" href={contact_link}>
                {this.state.loading ? (
                    <i className="call-widget-icon icon-loading"/>
                ) : (
                    data.status === 'waiting' ? (
                        <i className="call-widget-icon icon-bars"/>
                    ) : (
                        <i className="call-widget-icon icon-phone"/>
                    )
                )}
                {data.status === 'waiting' &&
                    <span className="small">Status</span>
                }
                {data.status === 'unregistered' &&
                    <span className="small">Number Not Registered</span>
                }
                {data.status === 'multiple' &&
                    <span className="small">Multiple Matches</span>
                }
                {this.state.loading ? (
                    <h5>Connecting...</h5>
                ) : (
                    data.status === 'waiting' && (
                        <h5>Waiting in Queue</h5>
                    )
                )}
                {data.status === 'known' &&
                    <h5>{data.from.name}</h5>
                }
                {data.status === 'unknown' &&
                    <h5>Unknown Caller</h5>
                }
                {data.from && data.from.number &&
                    <h5>{data.from.number}</h5>
                }
                {(data.status === 'unknown' || data.status === 'unregistered' || data.status === 'multiple') &&
                    <a className="call-widget-header-link" href={"/contacts/" + (data.from && data.from.number ? '?phone=' + data.from.number : '')}>
                        View List
                    </a>
                }
                {(data.status === 'unknown' || data.status === 'unregistered') &&
                    <a className="btn btn-primary call-widget-btn" href={"/contacts/new/" + (data.from && data.from.number ? '?phone=' + data.from.number : '')}>New</a>
                }
            </TagName>
        );
    }

    renderBody() {
        let data = this.state.data;

        if(data.status === 'waiting') {
            return null;
        }

        return (
            <Collapse isOpen={this.state.opened}>
                <div className="call-widget-body">
                    {data.message ? (
                        <p>{data.message}</p>
                    ) : (
                        data.interactions.length > 0 ? (
                            data.interactions.map((interaction) =>
                                <div className="call-widget-section" key={interaction.id}>
                                    <Row>
                                        <Col>
                                            <strong>{moment(interaction.date_sent).format('M/D/YY')}</strong>&#160;&#160;
                                            <span className="light">{moment(interaction.date_sent).format('ha zz')}</span><br/>
                                            {interaction.extra_data !== null && interaction.extra_data.ticket &&
                                                <span>
                                                    <strong>Ticket</strong> <a href={"/tickets/" + interaction.extra_data.ticket + "/"}>#{interaction.extra_data.ticket}</a>
                                                </span>
                                            }

                                        </Col>
                                        <Col>
                                            {interaction.from_object ? (
                                                <Fragment>
                                                    {interaction.from_object.avatar_image ? (
                                                        <img src={interaction.from_object.avatar_image} className="avatar" alt={`Profile image for ${interaction.from_object.name}`} />
                                                    ) : (
                                                        <span className="avatar avatar-blank">{get_first_letter(interaction.from_object.name)}</span>
                                                    )}
                                                    &#160;&#160;
                                                    <strong>{interaction.from_object.name}</strong><br/>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <span className="avatar avatar-blank">U</span>
                                                    &#160;&#160;
                                                    <strong>[Unknown Name]</strong><br/>
                                                </Fragment>
                                            )}

                                            <strong>Interaction ID</strong> <a href={'/messages/' + interaction.id + '/'}>#{interaction.id}</a>
                                        </Col>
                                        <Col>
                                            <div className="icon-wrapper">
                                                <i className={"icon icon-" + interaction.message_type}/>
                                                <strong>{capitalize(interaction.message_type)}</strong>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        ) : (
                            <p className="text-muted">No interactions found.</p>
                        )
                    )}
                </div>
            </Collapse>
        );
    }

    render() {
        if(!observablePageStore.callWidgetOpen) {
            return null;
        }

        return (
            <div id="call-widget" className={classNames(this.getStatusClass(), {'call-widget-open': this.state.opened})}>
                {this.renderHeader()}
                {this.renderBody()}
            </div>
        );
    }
});
