import React from 'react';
import classNames from 'classnames';
import axios from 'axios';
import Fa from "alpaca.js/dist/util/fa";


export class ExceptionGuide extends React.Component {
    constructor(props) {
        super(props);
        this.loadExceptions = this.loadExceptions.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);

        this.state = {
            opened: false,
            opening: false,
            exceptions: {}
        };
    }

    toggleOpen() {
        let component = this;

        if(!this.state.opened) {
            this.setState({opening: true});

            setTimeout(function() {
                component.setState({opened: true});
            }, 1);
        } else {
            this.setState({opened: false});

            setTimeout(function() {
                component.setState({opening: false});
            }, 100);
        }
    }

    componentDidMount() {
        this.loadExceptions();
    }

    loadExceptions() {
        this.setState({loading: true});

        const params = this.props;

        axios.get('/api/exceptions/', {params: params}).then((response) => {
            this.setState({
                exceptions: _.groupBy(response.data.results, "exception_type"),
                loading: false
            });
        });

    }

    renderExceptions() {
        if (_.isEmpty(this.state.exceptions)) return null;

        return _.map(this.state.exceptions, (exceptionItems, title) =>
            (<div className="exception-div" key={title}>
                <div className="exception-div-title">
                    <h5>{title}</h5>
                </div>
                <div className="exception-div-content">
                    {_.map(exceptionItems, (exception) =>
                        <div key={exception.id}>
                            <h6>{exception.title}</h6>
                            <div dangerouslySetInnerHTML={{__html: exception.description}}/>
                        </div>
                    )}
                </div>
            </div>)
        )
    }

    render() {
        if (this.state.loading || _.isEmpty(this.state.exceptions)) return null;

        return (
            <div>
                {this.state.opening &&
                    <div className={classNames("modal-backdrop", "fade", {"show": this.state.opened})} onClick={this.toggleOpen}/>
                }

                <div id="exception_guide" className={classNames({'open': this.state.opened})}>
                    <button className="btn exception-toggle-btn" onClick={this.toggleOpen}>
                        <span className="exception-arrow"/>
                        <span className="exception-name d-inline-block">View Exception Guide</span>
                        <span className="exception-info d-inline-block">
                            <Fa icon="info-circle" />
                        </span>
                    </button>

                    <div className="exception-content">
                        <button type="button" className="close" onClick={this.toggleOpen}>
                            <span aria-hidden="true">&times;</span>
                        </button>

                        <h1 className="mb-5">Exception Guide</h1>

                        {this.renderExceptions()}
                    </div>
                </div>
            </div>
        );
    }
}
