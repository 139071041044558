import React from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import linkState from '../link_state';
import { deleteIn, setIn } from 'immutable-setter';
import Fa from "alpaca.js/dist/util/fa";
import Select2 from '../forms/select2';
import axios from "axios/index";
import { get_first_letter, capitalize } from '../util';
import { get_primary_contact_item, get_shipping_address } from './util';


export class ContactSelector extends React.Component {
    constructor(props) {
        super(props);

         this.state = {
             contacts: this.props.contacts,
             selected_contact: null,
             loading: false,
        };
    }

    handleAddContact() {
        this.setState({loading: true});

        axios.get('/api/contacts/' + this.state.selected_contact + '/').then((response) => {
            let contact = {
                primary: this.state.contacts.length === 0,
                contact: response.data,
            };

            this.setState({contacts: this.state.contacts.concat([contact]), loading: false, selected_contact: null});
        });
    }

    handleRemoveContact(index) {
        this.setState(
            {contacts: deleteIn(this.state.contacts, [index])},
            function() {
                if(this.state.contacts.length > 0) {
                    var any_primary = false;
                    for (let x in this.state.contacts) {
                        if (this.state.contacts[x].primary) {
                            any_primary = true;
                            break;
                        }
                    }

                    if(!any_primary) {
                        this.setState({contacts: setIn(this.state.contacts, [0, 'primary'], true)});
                    }
                }
            }
        );
    }

    handleSetPrimary(index) {
        let contacts = this.state.contacts.map(function(contact, i) {
            return {
                primary: i === index,
                contact: contact.contact
            };
        });
        this.setState({contacts: contacts});
    }

    getContactsJson() {
        let contacts_json = [];

        for(let index in this.state.contacts) {
            contacts_json.push({
                'primary': this.state.contacts[index].primary,
                'contact_id': this.state.contacts[index].contact.id
            });
        }

        return JSON.stringify(contacts_json);
    }

    renderContact(index, contact) {
        let phone = get_primary_contact_item(contact.contact.phones);
        let email = get_primary_contact_item(contact.contact.emails);
        let address = get_shipping_address(contact.contact.addresses);
        let contact_name = contact.contact.name || '[Unknown Name]';

        return (
            <tr className={index % 2 === 0 ? "even" : "odd"} key={`contact_${index}`}>
                <td>
                    <div className="custom-control custom-radio mb-0" style={{verticalAlign: "middle"}}>
                        <input type="radio" id={"primary_contact_" + contact.contact.id} checked={contact.primary} className="custom-control-input" value={contact.contact.id} onChange={this.handleSetPrimary.bind(this, index)}/>
                        <label className="custom-control-label" htmlFor={"primary_contact_" + contact.contact.id}>&#160;</label>
                    </div>
                </td>
                <td className="name">
                    <span className="avatar avatar-blank ">
                        {get_first_letter(contact_name)}
                    </span>
                    &nbsp;&nbsp;
                    <strong><a href={`/contacts/${contact.contact.id}/`} target="_blank">{contact_name}</a></strong>
                </td>

                <td className="phone">
                    {phone && <div>
                        {phone.phone}
                        &nbsp;&nbsp;
                        {phone.primary &&
                            <i className="fab fa-product-hunt text-success" aria-hidden="true" style={{fontSize: 20}} />
                        }
                        <br/>
                        {phone.type &&
                            <i className="text-muted">{capitalize(phone.type)}</i>
                        }
                        &nbsp;&nbsp;
                        {phone.preferred &&
                            <span className="badge badge-warning">Preferred</span>
                        }
                    </div>}
                </td>

                <td className="email">
                    {email && <div>
                        {email.email}
                        &nbsp;&nbsp;
                        {email.primary &&
                            <i className="fab fa-product-hunt text-success" aria-hidden="true" style={{fontSize: 20}} />
                        }
                        <br/>
                        {email.type &&
                            <i className="text-muted">{capitalize(email.type)}</i>
                        }
                        &nbsp;&nbsp;
                        {email.preferred &&
                            <span className="badge badge-warning">Preferred</span>
                        }
                    </div>}

                </td>

                <td className="address">
                    {address && <div>
                        {address.full_address}
                        &nbsp;&nbsp;
                        <br/>
                        {address.type &&
                            <i className="text-muted">{capitalize(address.type)}</i>
                        }
                    </div>}
                </td>
                <td>
                    <Button size="xs" color="primary" outline onClick={this.handleRemoveContact.bind(this, index)}>
                        Remove
                    </Button>
                </td>
            </tr>
        );
    }

    render() {
        let formatContactResult = function(contact) {
            if(contact.name === undefined) {
                return contact.text;
            }

            let phone = get_primary_contact_item(contact.phones);
            let email = get_primary_contact_item(contact.emails);
            let address = get_shipping_address(contact.addresses);

            let $element = $(`<div class="contact-selection-result"><span class="name">${contact.name !== null ? contact.name : "[Unknown Name]"}</span></div>`);

            if(phone != null) {
                $element.append(`<br/><span><i class="fas fa-phone"></i> ${phone.phone}</span>`);
            }

            if(email != null) {
                $element.append(`<br/><span><i class="fas fa-envelope"></i> ${email.email}</span>`);
            }

            if(address != null) {
                $element.append(`<br/><span><i class="fas fa-home"></i> ${address.full_address}</span>`);
            }

            return $element;
        };

        let contact_ajax = {
            url: '/api/active-contacts/',
            dataType: 'json',
            delay: 750,
            processResults: function (data) {
                return {
                    results: data.results.map(function(contact) { return setIn(contact, ["text"], contact.name); }),
                    pagination: {
                        more: data.next !== null
                    }
                };
            },
            data: function (params) {
                return {
                    search: params.term,
                    page: params.page || 1
                };
            }
        };

        return (
            <div>
                <Card className="pt-3 mb-3">
                    <CardBody>
                        <Row>
                            <Col sm={{size: 6, offset: 3}}>
                                <h4><Fa icon="search" /> Find a Customer</h4>

                                <Select2
                                    onSelect={linkState(this, 'selected_contact')}
                                    value={this.state.selected_contact}
                                    placeholder="Search by name, phone or email"
                                    ajax={contact_ajax}
                                    templateResult={formatContactResult}
                                    minimumInputLength={4}
                                />

                                <div className="text-right mt-2">
                                    {this.state.loading ? (
                                        <Button outline color="primary" disabled={true}>
                                            <Fa icon="spinner" spin /> adding...
                                        </Button>
                                    ) : (
                                        <Button outline color="primary" disabled={!this.state.selected_contact} onClick={this.handleAddContact.bind(this)}>
                                            <Fa icon="plus" /> Add to {this.props.model_name}
                                        </Button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                {this.state.contacts.length > 0 &&
                    <div className="table-list mb-3">
                        <div className="table-list-header">
                            <h4>Contact(s) on {this.props.model_name}</h4>
                        </div>

                        <table className="table table-striped table-responsive-lg">
                            <thead>
                            <tr>
                                <th>Primary</th>
                                <th className="name">Name</th>
                                <th className="phone">Phone Number</th>
                                <th className="email">Email</th>
                                <th className="address">Address</th>
                                <th/>
                            </tr>
                            </thead>

                            <tbody>
                                {this.state.contacts.map((contact, index) =>
                                    this.renderContact(index, contact)
                                )}
                            </tbody>
                        </table>
                    </div>
                }

                <input type="hidden" name="contacts_json" value={this.getContactsJson()}/>
            </div>
        );
    }
}

ContactSelector.defaultProps = {
    contacts: [],
    model_name: "Plan"
};
