import React from 'react';
import ClaimDamageDetailsConfirm from './claim_damage_details_confirm';
import { ClaimDamageDetailsFormset } from './claim_damage_details_formset';


export class ClaimDamageDetailsFormsetConfirm extends ClaimDamageDetailsFormset {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.state.damages.map((result, index) =>
                    <ClaimDamageDetailsConfirm
                        key={index}
                        damage={result}
                        onChange={this.handleDamageChange.bind(this, index)}
                        damage_code_tree={this.props.damage_code_tree}
                        errors={this.getErrors(index)}
                        cause_types={this.props.cause_types}
                        damage_location_types={this.props.damage_location_types}
                        showActual={true} />
                )}

                <input type="hidden" value={JSON.stringify(this.state.damages)} name="claim_details_json"/>
            </div>
        );
    }
}
