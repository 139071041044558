import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import axios from "axios/index";


export class ClaimCancelButton extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.doCancel = this.doCancel.bind(this);

        this.state = {
            modal_opened: false,
            loading: false
        };
    }

    toggleModal() {
        if(this.state.loading) {
            return;
        }

        this.setState({ modal_opened: !this.state.modal_opened });
    }

    doCancel() {
        this.setState({loading: true});

        axios.post(`/api/claims/${this.props.claim}/close/`).then((response) => {
            this.setState({
                modal_opened: false,
                loading: false,
            }, function(){
                document.location.reload();
            });
        });
    }

    render() {
        return (
            <div className="d-inline-block">
                <Button color="danger" outline onClick={this.toggleModal}><Fa icon="ban" /> Close Claim</Button>

                <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal} backdrop={this.state.loading ? "static" : true}>
                    <ModalHeader toggle={this.toggleModal}>Confirm Close</ModalHeader>
                    <ModalBody>
                        <Alert color="danger"><Fa icon="exclamation-triangle" /> Closing a claim cannot be undone.</Alert>

                        {this.props.pending_examination &&
                            <Alert color="danger"><Fa icon="exclamation-triangle" /> This claim has a pending examination. Closing the claim will close the examination.</Alert>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.toggleModal} disabled={this.state.loading} style={{minWidth: 135}}>
                            Cancel
                        </Button>{' '}
                        {this.state.loading ? (
                            <Button color="primary" disabled style={{minWidth: 150}}>
                                <Fa icon="spinner" spin /> Closing...
                            </Button>
                        ) : (
                            <Button color="primary" onClick={this.doCancel} style={{minWidth: 150}}>Close Claim</Button>
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

ClaimCancelButton.propTypes = {
    claim: PropTypes.number.isRequired,
    pending_examination: PropTypes.bool
};

ClaimCancelButton.defaultProps = {
    pending_examination: false
};
