import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import Plyr from 'plyr';
import Fa from "alpaca.js/dist/util/fa";
import { get_url_file_name, get_first_letter, lineBreaksHtml } from '../util';
import {MoveToTicketButton} from "./move_to_ticket_button";
import {ReplyBox} from "./replybox";
import {ReplyBoxPopup} from "./reply_popup";


export const MESSAGE_TYPES = {
    note: {
        name: "Note",
        icon: "icon-note",
    },
    email: {
        name: "Email",
        icon: "icon-email",
    },
    phone: {
        name: "Call",
        icon: "icon-call",
    },
    sms: {
        name: "SMS",
        icon: "icon-sms",
    },
    system: {
        name: "System Message",
        icon: "icon-system",
    }
};


export default class InteractionRow extends React.Component {
    constructor(props) {
        super(props);
        this.handleMarkAttachment = this.handleMarkAttachment.bind(this);
    }

    componentDidMount() {
        if(this.refs.audio !== undefined) {
            new Plyr(this.refs.audio, {
                controls: ['play', 'progress', 'current-time', 'duration'],
                volume: 10,
                storage: {
                    enabled: false
                }
            });
        }
    }

    handleMarkAttachment(event, attachment, mark_as) {
        const { onMarkAttachment, message } = this.props;

        event.preventDefault();


        if (attachment.attachment_type !== null) {
            return;
        }

        if (onMarkAttachment) {
            onMarkAttachment(message, attachment, mark_as);
        }
    }

    render() {
        let message = this.props.message;
        return (
            <tr key={message.id}>
                {this.props.showTicketColumn &&
                    <td>
                        <strong>
                            <a href={"/tickets/" + message.extra_data.ticket + "/"} className="text-bold">{message.extra_data.ticket}</a>
                        </strong>
                    </td>
                }
                <td className="type">
                    <i className={classNames("icon", MESSAGE_TYPES[message.message_type].icon)} /><br/>
                    <strong>{MESSAGE_TYPES[message.message_type].name}</strong>
                </td>
                <td className="sender">
                    {message.from_object &&
                        <div>
                            {message.from_object.avatar_image ? (
                                <img className="avatar" src={message.from_object.avatar_image} alt={`Profile image for ${message.from_object.name}`} />
                            ) : (
                                <span className="avatar avatar-blank">{get_first_letter(message.from_object.name)}</span>
                            )}
                            <div className="from">
                                <span className="from name">{message.from_object.name}</span>
                                <span className="from email">{message.from_email}</span>
                            </div>
                        </div>
                    }
                </td>
                <td className="id"><a href={"/messages/" + message.id + "/"}>{message.id}</a></td>
                <td className="details" style={{overflow: "visible", overflowWrap: "break-word", wordWrap: "break-word"}}>
                    {message.message_type === 'phone' && message.attachments.length > 0 ? (
                        <audio src={message.attachments[0].attachment} ref="audio" />
                    ): (
                        <div>
                            <p>{lineBreaksHtml(message.content)}</p>
                            <table className="table-sm no-borders">
                                <tbody>
                                    {message.attachments.map((attachment) =>
                                        <tr key={attachment.id}>
                                            <td style={{maxWidth: 310, wordBreak: "break-all"}}>
                                                <a href={attachment.attachment} target="_blank" className="attachment-link">
                                                    {get_url_file_name(attachment.attachment)}
                                                </a>
                                            </td>
                                            <td>
                                                {attachment.attachment_type !== null ? (
                                                    <i>{attachment.attachment_type}</i>
                                                ) : (
                                                    <span>
                                                        <strong>Mark as: </strong>
                                                        <a href="#" onClick={(e) => this.handleMarkAttachment(e, attachment, "receipt")}>Receipt</a>
                                                        &#160;|&#160;
                                                        <a href="#" onClick={(e) => this.handleMarkAttachment(e, attachment, "image")}>Image</a>
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {message.critical &&
                        <span className="label label-danger">
                            <Fa icon="exclamation-triangle" /> Critical
                        </span>
                    }
                </td>
                <td className="date">
                    {moment(message.date_sent).format('MM/DD/YYYY h:mm A zz')}
                </td>
                <td className="options button-column">
                    {message.can_move_to_ticket &&
                        <div className="dropdown">
                            <button className="btn btn-link btn-xs" type="button" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false" title="Show more options">
                                <Fa icon="ellipsis-h" />
                            </button>

                            <div className="dropdown-menu dropdown-menu-right">
                                <MoveToTicketButton message={message.id} onComplete={this.props.reload}/>
                            </div>
                        </div>
                    }

                    {(this.props.can_email_reply && message.can_email_reply) &&
                        <ReplyBoxPopup
                            channel={message.channel_id}
                            reply_email={message.id}
                            reload={this.props.reload}
                            asDropdown={true}
                            can_add_email={message.can_email_reply}
                        />
                    }
                </td>
            </tr>
        );
    }
}

InteractionRow.propTypes = {
    message: PropTypes.object,
    onMarkAttachment: PropTypes.func,
    showTicketColumn: PropTypes.bool,
    reload: PropTypes.func.isRequired,
};

InteractionRow.defaultProps = {
    showTicketColumn: false,
};
