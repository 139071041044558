import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";


export class ConfirmButton extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            modalOpened: false
        };
    }

    toggleModal() {
        if(this.props.loading) {
            return;
        }

        this.setState({ modalOpened: !this.state.modalOpened });
    }

    render() {
        let {
            children, loading, confirmTitle, confirmMessage, cancelButtonText, loadingText, confirmButtonText,
            onConfirm, ...rest
        } = this.props;

        return (
            <div>
                <Button {...rest} onClick={this.toggleModal}>{children}</Button>

                <Modal isOpen={this.state.modalOpened} toggle={this.toggleModal} backdrop={loading ? "static" : true}>
                    <div className="modal-header pb-0">
                        <div className="modal-title">
                            <h3 className="modal-title">{confirmTitle}</h3>
                        </div>
                        <button type="button" className="close" aria-label="Close" onClick={this.toggleModal} disabled={loading}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <hr className="mt-0"/>
                        <p>{confirmMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={this.toggleModal} disabled={loading} className="btn-wide">
                            {cancelButtonText}
                        </Button>
                        <Button color="primary" onClick={onConfirm} disabled={loading} className="btn-wide">
                            {loading ? <span><Fa icon="spinner" spin/> {loadingText}</span> : confirmButtonText }
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ConfirmButton.propTypes = {
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    confirmMessage: PropTypes.string,
    confirmTitle: PropTypes.string,
    onConfirm: PropTypes.func.isRequired
};

ConfirmButton.defaultProps = {
    loading: false,
    loadingText: 'loading...',
    confirmButtonText: "Yes",
    cancelButtonText: "Cancel",
    confirmMessage: "Do you want to continue?",
    confirmTitle: "Confirm",
};
